@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital@1&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
@import url("https://use.fontawesome.com/releases/v5.0.6/css/all.css");

:root {
  --blue: #339dd8;
  /* --darkBlue: #1F3977; */
  --darkBlue: #293043;

  --yellow: #fcc10c;
  --pink: #f15cb5;
  --green: #00f3b9;
  --background: #dbdbdd;
  /* --color:#EFF6FF; */
  --color: #e4e9f4;
  /* color:#e7e5e5 */
}

body {
  /*
	margin: 0;
	padding: 0;
	position: relative !important; */
  overflow-y: auto !important;
  overflow-x: hidden !important;
  height: 100% !important;
  width: 100% !important;
  background: white !important;
  font-family: "Poppins", sans-serif !important;
}

.list p {
  float: left;
}

@media screen and (min-width: 0px) and (max-width: 800px) {
  body {
    width: 100%;
  }

  html {
    width: 100%;
  }

  .header {
    width: 100%;
    display: inline;
  }

  .header li {
    float: none;
    display: block;
    text-align: left;
    border: 1px solid red;
  }

  .header-right {
    float: none;
  }
}

.switch_account:hover {
  background: rgb(241, 239, 239);
  border-radius: 10px;
}

/* MAP */

#map {
  height: 500px;
  margin: 10px auto;
  width: 800px;
}

/* .admin_dashboard h1
{
	margin-left: 80px;
	color:white;
	font-size: 50px !important;
	font-weight: bold;

} */

.login-wrap {
  margin: 50px;
  height: 500px;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.login-left {
  width: 60%;
  height: auto;

  background: url(./images/home-banner.jpg) no-repeat;
  border-radius: 10px 0px 0px 10px;
}

.login-right {
  width: 50%;
  height: 100%;

  background-color: #a4d3e2;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
  border-radius: 0px 10px 10px 0px;
}

::placeholder {
  color: #9e9e9e !important;
  margin: 5px 0px 0px 5px !important;
  padding-left: 2px !important;
  font-size: 9px !important;
}

.hr {
  height: 2px;
  margin: 60px 0 50px 0;
  background: rgba(255, 255, 255, 0.2);
}

.foot-lnk {
  text-align: center;
}

.forgot_pass {
  width: 100%;
  height: 60%;
  position: absolute;
  padding: 90px 70px 10px 70px;
  background: rgba(40, 57, 101, 0.9);
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.forgot_wrap {
  width: 60%;
  margin-left: 400px;
  max-width: 525px;
  min-height: 670px;
  position: relative;
}

.main-footer {
  position: absolute;
}

.alert {
  display: none;
}

.banner-settings {
  width: 50%;
  margin-left: 3px;
  margin-bottom: 20px;
  padding: 2px;
  height: auto;

  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.logo-settings {
  width: 50%;
  margin-left: 5px;
  margin-bottom: 20px;
  padding: 2px;
  height: auto;

  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.user-profile {
  width: 100%;
  margin-left: 440px;
  margin-bottom: 20px;
  max-width: 758px;
  min-height: 400px;
  position: relative;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

/* regular */
.regular_jobs::-webkit-scrollbar {
  width: 10px;
}

.regular_jobs::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.regular_jobs::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background-color: grey;
}

/* remote */
.remote_jobs::-webkit-scrollbar {
  width: 10px;
}

.remote_jobs::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.remote_jobs::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background-color: grey;
}

/* parttime */
.partTime_jobs::-webkit-scrollbar {
  width: 10px;
}

.partTime_jobs::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.partTime_jobs::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background-color: grey;
}

/* freelancing */
.freelancing::-webkit-scrollbar {
  width: 10px;
}

.freelancing::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.freelancing::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background-color: grey;
}

/* voluntree */
.volunTeerJobs::-webkit-scrollbar {
  width: 10px;
}

.volunTeerJobs::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.volunTeerJobs::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background-color: grey;
}

/* full time */

.fullTimeJobs::-webkit-scrollbar {
  width: 10px;
}

.fullTimeJobs::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.fullTimeJobs::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background-color: grey;
}

.main-sidebar {
  /* top:60px !important; */
  bottom: 0 !important;
  width: 250px !important;
  border: none !important;
  /* position: relative !important; */
  height: 100vh !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  background-color: #293043;
  /* padding-right: 1rem; */
  padding-bottom: 2rem;
}

.main-sidebar .mainmenu {
  padding: 0 20px;
}

.main-sidebar::-webkit-scrollbar {
  width: 10px;
}

.main-sidebar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.main-sidebar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background-color: grey;
}

@media screen and (min-width: 0px) and (max-width: 400px) {
  .pushmenu {
    display: block;
  }

  .side_menuu {
    display: block !important;
  }

  /* show it on small screens */
  /* .navbar{width:100%;display:inline} */
}

@media screen and (min-width: 401px) and (max-width: 3000px) {
  .pushmenu {
    display: none;
  }

  /* hide it elsewhere */
}

.nav-link {
  color: #6c7a99 !important;
  margin-bottom: 6px;
  font-weight: 500;
  margin-left: 30px;
  font-size: 11px;
}

.nav-link:hover {
  color: white !important;
}

.nav-item {
  /* / border:1px solid white; / */

  color: #6c7a99;
}

.nav-item:hover {
  color: white !important;
  /* background-color: rgb(128, 128, 128, 0.4) !important; */
}

.nav-notification:hover {
  background-color: none !important;
}

.inactive:hover {
  background-color: transparent !important;
  color: white !important;
}

.header-item i {
  color: white !important;
  margin-left: 50px !important;

  margin-top: 0px !important;
}

.list-item :hover {
  color: white !important;
  background-color: transparent !important;
  border: none !important;
}

body::-webkit-scrollbar {
  width: 5px;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

body::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background-color: grey;
}

.forgotpass {
  color: white;
  text-decoration: underline;
  margin-top: 3px;
}

.select_image {
  margin-left: 200px;
}

.save_banner {
  margin-left: 180px;
}

.hide_div {
  top: 0px;
  left: 0px;
  border-top: 10px;
  width: 100%;
  height: 40px;
}

.AddAlert {
  position: absolute;
  top: 10px;
  left: 250px;
  border-top: 10px;
  width: 40%;
  height: 40px;
}

.EditAlert {
  position: absolute;
  top: 10px;
  left: 250px;
  border-top: 10px;
  width: 40%;
  height: 40px;
}

.DeleteAlert {
  position: absolute;
  top: 10px;
  left: 250px;
  border-top: 10px;
  width: 40%;
  height: 40px;
}

/* toggle */
.switch {
  position: relative;
  display: inline-block;
  width: 55px;
  height: 25px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: green;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: red;
}

input:focus + .slider {
  box-shadow: 0 0 1px red;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* .nav-item p:hover, .nav-item i:hover
  {
	  color:black !important;
  } */
.menu_item:hover {
  color: white !important;
}

.deactive {
  position: absolute;
  top: 0px;
  left: 0px;
  border-top: 10px;
  width: 100%;
  height: 40px;
  display: none;
}

.back_button {
  width: "100px";
  height: "100px";
}

.form-control {
  /* width: 290px !important; */
  background: transparent;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px solid white !important;
  border-radius: 0px !important;
  color: white !important;
}

.form-control:focus {
  /* width: 290px !important; */
  background: transparent;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px solid white !important;
  color: white !important;
}

.college_login {
  color: white;
  background-color: #1f3977;
  padding: 3px;
  animation: zoom-in-zoom-out 2s ease infinite;
  border-radius: 0px 10px 10px 0px;
  text-align: center;
  border: none !important;
  font-family: "Source Sans Pro", sans-serif;
}

.login_button {
  /* background-color: #1F3977  !important; */
  border: none !important;
  background: #1f3977 !important;
  cursor: pointer !important;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(0.8, 0.8);
  }

  100% {
    transform: scale(1, 1);
  }
}

input:focus::-webkit-input-placeholder {
  font-size: 9px !important;
  position: relative;
  /* top: -15px;  */
  /* border-bottom: 1px solid grey; */
  transition: 0.2s ease-out;
  /* border: none; */
  color: black;
}

input:focus {
  outline: none !important;
  /* border: 2px solid  var(--blue); */
  /* border-color: #719ECE; */
}

.faqAnswer:focus {
  outline: none !important;
}

.content-header {
  /* padding:0px 0px 0px 0px; */
  /* background-color: white; */
  width: 100%;
  margin-right: 10px;
  /* box-shadow:0 12px 15px 0 rgba(0,0,0,.24),0 17px 50px 0 rgba(0,0,0,.19); */
}

.content-wrapper {
  padding: 10px 20px 10px 20px;
  background: #f2f2f2 !important;
  font-family: Poppins;
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0px;
  margin-left: 250px !important;
  overflow: auto;
  min-height: 574px !important;
}

/*
  .content-wrapper::-webkit-scrollbar {
    width: 10px;
}
.content-wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;

}

.content-wrapper::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
	background-color: grey;
}  */

.first_part:hover,
.central_part:hover,
.right_part:hover {
  transform: scale(1);
  /* zoom: 50%; */
  border: 1px solid rgb(194, 189, 189);
}

.call_icon,
.mail_icon,
.chat_icon,
.calender_icon,
.phone_icon {
  color: black !important;
}

.call_icon:hover {
  color: #00f3b9 !important;
  cursor: pointer;
}

.mail_icon:hover {
  color: red !important;
  cursor: pointer;
}

.chat_icon:hover {
  color: #339dd8 !important;
  cursor: pointer;
}

.calender_icon:hover {
  color: green !important;
  cursor: pointer;
}

.phone_icon:hover {
  color: #fcc10c !important;
  cursor: pointer;
}

.contact_button {
  width: 70px;
  font-size: 10px;
  background-color: #1f3977 !important;
  border: none;
}

.contact_button:hover {
  background-color: #339dd8 !important;
}

.contact_icons {
  display: none;
}

.expand_map:hover {
  text-decoration: underline;
  color: white;
  /* transform: scale(1.1); */
}

.notification_icon {
  color: white !important;
  background-color: green !important;
  padding: 5px;
  height: 30px;
  border-radius: 10px 0px 0px 10px;
  margin-left: 10px;
}

.notification_icon:hover {
  color: white !important;
  background-color: green !important;
  padding: 5px;
  height: 30px;
  border-radius: 10px 0px 0px 10px;
  margin-left: 10px;
  text-decoration: none;
}

.content_center {
  /* background-color: rgb(230, 225, 225); */

  margin: 6px;
}

.services {
  background-color: white;
  border-left: 1px solid #d4d5db;
  padding: 15px;
}

/* .search-container {
	float: right;

  } */

/* .search-container input[type=text] {
	padding-left: 3px;

	font-size: 14px;
	border: none;
	width: 300px;
  } */

/* .search-container button {
	float: right;
	padding: 4px 10px;

	margin-right: 16px;
	background: #ddd;

	border: none;
	cursor: pointer;
  } */

/* .search-container button:hover {
	background: #ccc;
  } */

/* @media screen and (max-width: 600px) { */
/* .search-container {
	  float: none;
	} */
/* .search-container input[type=text], .search-container button {
	  float: none;
	  display: block;
	  text-align: left;
	  width: 100%;
	  margin: 0;
	  padding: 14px;
	} */
/* .search-container input[type=text] {
	  border: 1px solid #ccc;
	} */
/* } */

.student_btn {
  color: #fff;
  font-size: 15px;
  font-weight: 300;
  padding: 10px 20px;
  margin: 10px 10px 10px 23px;

  border: none;
  cursor: pointer;

  transition: all 0.3s linear;

  background: #e44c7a;
}

.create_btn {
  color: #fff;
  font-size: 15px;
  font-weight: 300;
  padding: 10px;
  /* margin: 10px 10px 0px 0px; */

  border: none;
  cursor: pointer;

  transition: all 0.3s linear;

  background: #339dd8;
}

.student_btn:hover {
  background: #eb5d88;
}

.student_persona {
  border: 1px solid white;
  margin: 10px 20px;
  height: auto !important;
  border-radius: 5px;
  background-color: white;
  color: black;
}

.student_groups {
  background-color: rgb(202, 199, 199);
  margin: 10px 10px 8px 10px;
  height: auto !important;
  padding: 10px 10px 0px 10px;
}

/* .student_groups a
{
	color: black !important;
}
.nav-link :active
{
	color: black !important;
} */
.student_profile {
  background-color: white;
  color: black;
  margin: 10px 20px;
}

.international_std {
  background-color: white;
  padding: 0px;
}

.total_count {
  /* border-left: 6px solid #339dd8; */
  background-color: rgb(230, 222, 222);
  color: grey;
  margin: 10px 15px 10px 15px;
  padding: 5px;
}

.faq {
  /* border-left: 6px solid #339dd8; */
  background-color: #effeff;
  color: black;
  border: 1px solid #eaecef;
  border-radius: 4px;
  margin: 10px 1px 5px 1px;
  padding: 5px 5px 0px 10px !important;
}

.left_chatbox {
  /* border: 1px solid white; */
  border-radius: 20px 0px 0px 20px;
  width: 40%;
  font-size: 15px;
  background-color: rgb(230, 222, 222);
  padding: 5px;
  margin: 10px 0px 10px 10px;
}

.right_chatbox {
  border-left: 1px solid rgb(219, 216, 216);
  background-color: rgb(230, 222, 222);
  padding: 5px 5px 5px 0px;
  width: 60%;
  height: auto;
  margin: 10px 10px 10px 0px;
  border-radius: 0px 20px 20px 0px;
}

.left_sidebar {
  background-color: white;
  border-radius: 3px;
  margin: 8px;
  /* box-shadow:0 10px 3px 2px rgba(0,0,0,.24); */
}

.left_sidebar p {
  color: black;
}

/* chat search */
.chat_search {
  /* float: center; */
  margin-left: 40px;
  margin-top: 10px;
}

.chat_search input[type="text"] {
  padding-left: 35px;
  /* margin-bottom: 3px; */
  font-size: 14px;
  border: none;
  border-radius: 5px 0px 0px 5px;
  width: 300px;
  background: rgb(180, 179, 179);
}

.chat_search button {
  float: right;
  padding: 4px 10px;
  border-radius: 0px 5px 5px 0px;
  margin-right: 16px;
  background: rgb(180, 179, 179);

  border: none;
  cursor: pointer;
}

.chat_search button:hover {
  background: #ccc;
}

.chat_search ::placeholder {
  color: #c1c1c1 !important;
}

.inbox_chat_header {
  /* background-color:rgb(230, 222, 222); */
  color: black;
  /* padding: 2px 2px 0px 2px; */
  padding-bottom: 0 !important;
  /* font-size: 15px; */
}

.inbox_chat_footer {
  background-color: rgb(230, 222, 222);
  color: black;
  /* top: 20px; */
  border-radius: 0px 0px 20px 0px;
  position: relative;
}

.inbox_chat_footer::placeholder {
  margin-left: 2px;
  font-size: 10px !important;
}

.inbox_chat {
  background-color: rgb(114, 112, 112);
  height: auto;
  color: black;
  overflow-y: auto;
  padding: 2px;
}

/* Read More */
/* *{
	margin:0;
	padding:0;
	border:0;
	font-size: 16px;
	line-height: 1.4;
	font-family: sans-serif;
  }
  .body{
	padding:20px;
	margin:0 auto;
	max-width:80%;
	background:#fff;
	display:block;
	height:100vh;
  } */

h2,
details {
  display: inline;
}

details {
  position: relative;
}

details summary {
  display: block;
  cursor: pointer;
  color: white;
  background-color: #339dd8;
  /* margin-left:800px !important; */

  font-size: 15px;
  padding: 4px;
  border-radius: 3px;
}

details summary:focus {
  outline: none;
}

details[open] {
  display: block;
  padding-bottom: 25px;
  padding-top: 10px;
  animation: open 0.2s linear;
}

details[open] summary {
  position: absolute;
  bottom: 0;
  left: 0;
}

details #open {
  padding-left: 5px;
  text-align: middle;
}

details #open:after {
  display: inline-block;
  position: relative;
  top: -3px;
  padding-left: 8px;
  content: "\00bb";
  transform: rotate(90deg);
}

details[open] #open {
  display: none;
}

details #close {
  display: none;
}

details[open] #close {
  display: block;
}

::-webkit-details-marker {
  display: none;
}

@keyframes open {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Text tabs */
article.tabs {
  position: relative;
  display: block;
  width: 100%;
  height: 800px;
  margin: 2px;
}

article.tabs section {
  position: absolute;
  display: block;
  top: 1.8em;
  left: 0;
  right: 0;
  height: auto;
  padding: 10px 20px;
  /* border: 1px solid blue; */
  background-color: #ddd;
  border-radius: 0px;

  z-index: 0;
  color: black;
}

article.tabs section .tab-content {
  display: none;
}

article.tabs section:last-child {
  z-index: 1;
  color: #333;

  background-color: #fff;
}

article.tabs section:last-child .tab-content {
  display: block;
}

article.tabs section h2 {
  position: absolute;
  font-size: 15px;
  font-weight: normal;

  width: 135px;
  height: 1.8em;
  top: -1.8em;
  left: 0px;
  padding: 0;
  margin: 0;
  border: 1px solid rgb(202, 201, 201);
  border-bottom: none;
  color: rgb(129, 127, 127);
  background-color: #ddd;
  border-radius: 5px 5px 0 0;
}

/* persona */
article.tabs section:last-child h2 {
  color: #333;

  background-color: #fff;
}

article.tabs section:nth-child(1) h2 {
  left: 132px;
}

article.tabs section:nth-child(2) h2 {
  left: 254px;
}

article.tabs section h2 a {
  display: block;
  width: 100%;
  line-height: 1.8em;
  text-align: center;

  text-decoration: none;
  color: inherit;
  outline: 0 none;
}

article.tabs section:target,
article.tabs section:target h2 {
  color: #333;
  background-color: #fff;

  z-index: 2;
}

article.tabs section:target .tab-content {
  display: block;
}

article.tabs section:target ~ section:last-child h2 {
  color: #999;
  background-color: #ddd;
}

article.tabs section:target ~ section:last-child .tab-content {
  display: none;
}

/* Modal */
.modal-window {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.25);
  width: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  visibility: hidden;
  opacity: 0.5;
  pointer-events: none;
  transition: all 0.3s;
}

.modal-window:target {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

.modal-window > div {
  width: 500px;
  position: absolute;
  top: 50%;
  left: 790px;
  transform: translate(-50%, -50%);
  padding: 15px;
  background: white;
  border: 1px solid #1f3977;
  border-top: none;
}

.modal-window header {
  font-weight: bold;
}

.modal-window h1 {
  font-size: 150%;
  margin: 0 0 15px;
}

/* #close {
    float:right;
    display:inline-block;
    padding:2px 5px;
    background:#ccc;
} */
.modal-close {
  color: white;
  background-color: #1f3977;
  line-height: 30px;
  font-size: 80%;
  position: absolute;
  right: 0;
  left: 0;
  text-align: right;
  padding-right: 8px;
  top: 0;
  width: 100%;
  text-decoration: none;
}

.popup a:hover {
  color: grey !important;
}

a:hover {
  text-decoration: none !important;
}

/* a {
	color: inherit;
  }
   */
/* .container {
	display: grid;

	width: 100% !important;
	margin: 0;

  }
   */
.modal-window > div {
  /* border-radius: 1rem; */
}

.modal-window div:not(:last-of-type) {
  margin-bottom: 15px;
}

small {
  color: lightgray;
}

.btn {
  background-color: #339dd8;
  color: white;
  /* padding: 1em 1.5em; */
  border-radius: 5px;
  /* text-decoration: none; */
}

.btn i {
  /* padding-right: 0.3em; */
}

/* custome tabs */

/* .tab-container{
	margin: 10px 30px 10px 30px ;
	background-color: white;
	padding: 5px 0px;

} */
.tab-menu {
}

.tab-menu ul {
  margin: 0;
  padding: 0;
}

.tab-menu ul li {
  list-style-type: none;
  display: inline-block;
}

.tab-menu ul li a {
  text-decoration: none;
  color: rgb(109, 108, 108);
  background-color: darkgrey;
  margin-right: 1px;
  padding: 7px 25px;
  /* border-right: 2px solid rgb(202, 199, 199); */
  /* border-radius: 4px; */
}

.tab-menu ul li a.active-a {
  background-color: white;
  color: #339dd8;
  /* border-bottom: 2px solid #339dd8 ; */
}

.tab-menu ul li a.active-a:hover {
  background-color: white;
  color: #339dd8;
  border-top: 2px solid #339dd8;
}

.tab {
  display: none;
}

.tab h2 {
  color: rgba(0, 0, 0, 0.7);
}

.tab p {
  color: rgba(0, 0, 0, 0.6);
  text-align: justify;
}

.tab-active {
  display: block;
}

/* hexagonal image */
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700");

/*
*, *::before, *::after {
  box-sizing: border-box;
} */

.honeycomb {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  /* justify-content: center; */
  /* align-items: center; */
  max-width: 1200px;
  margin: 0 auto !important;
  padding: 0 !important;
  transform: translateY(34.375px);
}

.honeycomb-cell {
  flex: 0 1 250px;
  max-width: 250px;
  height: 137.5px;
  margin: 65.4761904762px 12.5px 25px;
  position: relative;
  padding: 0.5em;

  text-align: center;
  z-index: 1;
}

.honeycomb-cell__title {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  word-break: break-word;
  text-transform: uppercase;
  color: #fff;
  font-weight: 700;
  font-size: 1.75em;
  transition: opacity 350ms;
}

.honeycomb-cell__title > small {
  font-weight: 300;
  margin-top: 0.25em;
}

.honeycomb-cell__image {
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  background-color: grey;
}

.honeycomb-cell::before,
.honeycomb-cell::after {
  content: "";
}

.honeycomb-cell::before,
.honeycomb-cell::after,
.honeycomb-cell__image {
  top: -50%;
  left: 0;
  width: 100%;
  height: 200%;
  display: block;
  position: absolute;
  -webkit-clip-path: polygon(
    50% 0%,
    100% 25%,
    100% 75%,
    50% 100%,
    0% 75%,
    0% 25%
  );
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  z-index: -1;
}

.honeycomb-cell::before {
  background: #fff;
  transform: scale(1.055);
}

.honeycomb-cell::after {
  /* background: #3ea0eb; */
  opacity: 0.5;
  transition: opacity 350ms;
}

.honeycomb-cell:hover .honeycomb-cell__title {
  opacity: 0;
}

.honeycomb-cell:hover::before {
  background: grey;
}

.honeycomb-cell:hover::after {
  opacity: 0;
}

.honeycomb__placeholder {
  display: none;
  opacity: 0;
  width: 250px;
  margin: 0 12.5px;
}

@media (max-width: 550px) {
  .honeycomb-cell {
    margin: 81.25px 25px;
  }
}

@media (min-width: 550px) and (max-width: 825px) {
  .honeycomb-cell:nth-child(3n) {
    margin-right: calc(50% - 125px);
    margin-left: calc(50% - 125px);
  }

  .honeycomb__placeholder:nth-child(3n + 5) {
    display: block;
  }
}

@media (min-width: 825px) and (max-width: 1100px) {
  .honeycomb-cell:nth-child(5n + 4) {
    margin-left: calc(50% - 275px);
  }

  .honeycomb-cell:nth-child(5n + 5) {
    margin-right: calc(50% - 275px);
  }

  .honeycomb__placeholder:nth-child(5n),
  .honeycomb__placeholder:nth-child(5n + 3) {
    display: block;
  }
}

@media (min-width: 1100px) {
  .honeycomb-cell:nth-child(7n + 5) {
    margin-left: calc(50% - 400px);
  }

  .honeycomb-cell:nth-child(7n + 7),
  .honeycomb-cell:nth-child(7n + 5):nth-last-child(2) {
    margin-right: calc(50% - 400px);
  }

  .honeycomb__placeholder:nth-child(7n + 7),
  .honeycomb__placeholder:nth-child(7n + 9),
  .honeycomb__placeholder:nth-child(7n + 11) {
    display: block;
  }
}

/* Alphabet Navigation list */
/*
  Our design has a min-width of 310px to work
*/
@import url(https://fonts.googleapis.com/css?family=Roboto:300, 500, 400);
/* *, *:before, *:after {
  box-sizing: border-box;
} */

.AlphabetNav {
  width: 98% !important;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-flow: wrap row;
  background-color: transparent !important;
  border-radius: 5px;
  color: white !important;
  margin-bottom: none !important;
  margin-left: 8px;
}

.AlphabetNav a {
  display: flex;
  justify-content: center;
  /* padding: 0.5em; */
  border-radius: 8px;
  font-size: 1.2em;
  line-height: 1;
  font-weight: 500;
  text-decoration: none;
  color: darkgray;
}

.AlphabetNav a:hover {
  background: #ddd;
  color: #444;
}

.AlphabetNav .active {
  /* background: #f46353; */
  /* color: white; */

  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-flow: wrap row;
  background-color: transparent !important;
  border-radius: 5px;
  color: white !important;
  margin-bottom: none !important;
  /* margin-left:8px; */

  position: relative;
}

/*
  Contact List
*/
.ContactList {
  padding: 10em 2rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.Contact {
  display: flex;
  align-items: center;
  width: 90%;
}

.ContactList .Contact {
  margin-bottom: 1.5rem;
}

.Contact-avatar {
  margin: 0 1em 0 0;
  width: 30%;
  min-width: 60px;
  max-width: 100px;
  border-radius: 50%;
  background: #a7c031;
}

.Contact-name {
  margin: 0;
  font-size: 1.3em;
  font-weight: 500;
  color: #339dd8;
}

.department-name {
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  color: darkgray;
}

@media (min-width: 400px) {
  .AlphabetNav {
    top: 2vh;
    right: 0;
    height: 90vh;
    width: 9em;
  }

  .AlphabetNav a {
    width: calc(100% * 1 / 3 - 0.3em);
  }

  .ContactList {
    padding-top: 2rem;
  }
}

@media (min-width: 400px) and (min-height: 600px) {
  .AlphabetNav {
    width: 6em;
  }

  .AlphabetNav a {
    width: calc(100% * 1 / 2 - 0.5em);
    margin: 0.25em;
  }
}

@media (min-width: 800px) {
  .AlphabetNav {
    top: 0;
    display: flex;
    align-items: flex-start;
    flex-flow: nowrap row;
    justify-content: space-around;
    width: 100%;
    height: auto;
    background: ghostwhite;
  }

  .AlphabetNav a {
    margin: 0.5em 0;
    padding: 0.5em 0;
  }

  .ContactList {
    padding-top: 2px;
    /* background-color: white; */
    margin: 3px 0px;
    /* text-align: center; */
  }

  .Contact {
    width: calc(100% * 1 / 3 - 2rem);
    /* border: 1px solid grey; */
    padding: 10px;
    /* background-color: grey; */
    border-left: 6px solid #339dd8;
    background-color: rgb(230, 222, 222);
    color: #339dd8 !important;
    margin: 10px 0px 10px 0px;
    padding: 5px;
  }

  .Contact:hover {
    width: calc(100% * 1 / 3 - 2rem);
    /* border: 1px solid grey; */
    padding: 10px;
    transform: scale(1.1);
  }
}

/* new staff modal */
/* h1 {
	text-align: center;
	font-family: "Trebuchet MS", Tahoma, Arial, sans-serif;
	color: #333;
	text-shadow: 0 1px 0 #fff;
	margin: 50px 0;
  } */

#wrapper {
  width: 100px;
  margin: 0 820px;
  /* background: #fff; */
  padding-top: 8px;
  margin-bottom: none;
  /* border: 10px solid #aaa; */
  border-radius: 15px;
  background-clip: padding-box;
  text-align: center;
  display: flex;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  /* transition: opacity 200ms; */
  visibility: hidden;
  opacity: 0;
}

.overlay.light {
  background: rgba(255, 255, 255, 0.5);
}

.overlay .cancel {
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: default;
}

.overlay:target {
  visibility: visible;
  opacity: 1;
}

.popup {
  margin: 75px 25px;
  padding: 20px;
  background: #fff;
  /* border: 1px solid #666; */
  /* width: 500px; */
  border-radius: 10px;
  /* box-shadow: 0 0 50px rgba(0, 0, 0, 0.5); */
  position: relative;
  /* border: 1px solid black; */
}

.light .popup {
  border-color: #aaa;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
}

.popup h2 {
  margin-top: 0;
  color: #666;
  font-family: "Trebuchet MS", Tahoma, Arial, sans-serif;
}

.popup .close {
  position: absolute;
  width: 18px;
  height: 18px;
  top: 1px;
  right: 5px;
  opacity: 0.8;
  transition: all 200ms;
  font-size: 24px;
  font-weight: bold;
  text-decoration: none;

  color: #666;
}

.popup .close:hover {
  /* opacity: 1; */
}

.popup .content {
  max-height: 400px;
  overflow: auto;
}

.popup p {
  margin: 0 0 1em;
}

.popup p:last-child {
  margin: 0;
}

/* Button animation */
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300, 400, 600);

.buttonContainer {
  /* margin-top:100px; */
  text-align: center;
  display: inline-block;
  font: normal normal 300 1.3em "Open Sans";
  text-decoration: none;
  color: white;
  /* background-color: var(--blue); */
  border: none;
  margin: 5px;
  background-size: 200% 100%;
  background-image: linear-gradient(to right, transparent 50%, #1f3977 50%);
  transition: background-position 0.3s cubic-bezier(0.19, 1, 0.22, 1) 0.1s,
    color 0.5s ease 0s, background-color 0.5s ease;
}

.nav-pills > li > a {
  border-radius: 0px !important;
  background-color: transparent !important;
}

/* h1{
	color:rgba(69, 69, 69, 1);
	line-height:2em;
} */

/* .buttonContainer:hover{
	color:rgba(255, 255, 255, 1) !important;
	background-color:var(--blue) !important;
	background-position: -100% 100%;
	border:1px solid white;


} */
.buttonContainer a:hover {
  text-decoration: none !important;
  color: white !important;
}

.services p {
  color: black;
}

/* student view */
/** Inspired by: http://graphicburger.com/flat-design-ui-components/ **/
/** Line-chart and donut-chart made by @kseso https://codepen.io/Kseso/pen/phiyL **/

/************************************ FONTS ************************************/
@import url(https://fonts.googleapis.com/css?family=Ubuntu:400, 700);
@import url(https://weloveiconfonts.com/api/?family=entypo|fontawesome|zocial);

/* entypo */
[class*="entypo-"]:before {
  font-family: "entypo", sans-serif;
}

/* fontawesome */
[class*="fontawesome-"]:before {
  font-family: "FontAwesome", sans-serif;
}

/* zocial */
[class*="zocial-"]:before {
  font-family: "zocial", sans-serif;
}

@font-face {
  font-family: "icomoon";
  src: url("https://jlalovi-cv.herokuapp.com/font/icomoon.eot");
  src: url("https://jlalovi-cv.herokuapp.com/font/icomoon.eot?#iefix")
      format("embedded-opentype"),
    url("https://jlalovi-cv.herokuapp.com/font/icomoon.ttf") format("truetype"),
    url("https://jlalovi-cv.herokuapp.com/font/icomoon.woff") format("woff"),
    url("https://jlalovi-cv.herokuapp.com/font/icomoon.svg#icomoon")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  font-family: "icomoon";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-cloudy:before {
  content: "\e60f";
}

.icon-sun:before {
  content: "\e610";
}

.icon-cloudy2:before {
  content: "\e611";
}

/************************************* END FONTS *************************************/

/* * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
} */

/* .input-container {
	position: relative;
} */
input[type="text"] {
  /* width: 260px; */
  /* height: 35px; */
  /* margin-left: 20px;
		margin-bottom: 20px;
		padding-left: 45px; */
  /* background: #f4f4f4; */
  color: black;
  /* border: solid 1px #1f253d; */
  /* border-radius: 30px;		 */
}

input[type="text"]::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #fff;
}

input[type="text"]:-moz-input-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #fff;
}

input[type="text"]::-moz-input-placeholder {
  /* Mozilla Firefox 19+ */
  color: #fff;
}

input[type="text"]:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #fff;
}

/* input[type=text]:focus {
	   		outline: none;
	   		border: 1px solid #11a8ab;
	   	} */
.input-icon {
  font-size: 22px;
  position: absolute;
  left: 31px;
  top: 10px;
}

.input-icon.password-icon {
  left: 35px;
}

.table-cards .row .col-md-4 {
  margin-bottom: 12px !important;
}

.horizontal-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.horizontal-list li {
  float: left;
}

.clear {
  clear: both;
}

.icon {
  font-size: 25px;
}

.titular {
  display: block;
  line-height: 60px;
  margin: 0;
  text-align: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.button {
  display: block;
  width: 175px;
  line-height: 50px;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  margin: 0 auto;
  border-radius: 5px;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
}

.button:hover {
  text-decoration: none;
}

/* .arrow-btn-container {
	position: relative;
} */
.arrow-btn {
  position: absolute;
  display: block;
  width: 60px;
  height: 60px;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
}

.arrow-btn:hover {
  text-decoration: none;
}

.arrow-btn.left {
  border-top-left-radius: 5px;
}

.arrow-btn.right {
  border-top-right-radius: 5px;
  right: 0;
  top: 0;
}

.arrow-btn .icon {
  display: block;
  font-size: 18px;
  border: 2px solid #fff;
  border-radius: 100%;
  line-height: 17px;
  width: 21px;
  margin: 20px auto;
  text-align: center;
}

.arrow-btn.left .icon {
  padding-right: 2px;
}

.profile-picture {
  border-radius: 100%;
  overflow: hidden;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.big-profile-picture {
  margin: 0 auto;
  /* border: 5px solid #50597b; */
  width: 150px;
  height: 150px;
}

.small-profile-picture {
  border: 2px solid #50597b;
  width: 40px;
  height: 40px;
}

/** MAIN CONTAINER **/

/* .main-container {
	font-family: 'Ubuntu', sans-serif;
	width: 950px;
	height: 1725px;
	margin: 6em auto;
}	 */
/*********************************************** HEADER ***********************************************/

.profile-menu {
  /*
			height: 50px;
			padding-right: 20px;
			margin-top:"none"; */
}

.profile-menu p {
  font-size: 17px;
  display: inline-block;
  line-height: 50px;
  margin: 0;
  padding-right: 10px;
}

.profile-menu a {
  padding-left: 5px;
}

.profile-menu a:hover {
  text-decoration: none;
}

.small-profile-picture {
  display: inline-block;
  vertical-align: middle;
}

/** CONTAINERS **/
/* .container {
		float: left;
		width: 300px;
	} */
.block {
  margin-bottom: 25px;
  background: #394264;
  border-radius: 5px;
}

/******************************************** LEFT CONTAINER *****************************************/
.inbox {
  animation: zoom-in-zoom-out 1s ease infinite;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.2, 1.2);
  }

  100% {
    transform: scale(1, 1);
  }
}

.inbox:hover {
  width: 36px !important;

  line-height: 22px !important;
  background-color: #e64c65 !important;
  text-align: center !important;
  border-radius: 15px !important;
  position: relative !important;
  top: 4px !important;
  float: right !important;
  right: 1px;
}

/* .donut-chart-block {
				height: 434px;
			} */
/* .donut-chart-block .titular {
					padding: 10px 0;
				} */
/* .donut-chart {
					height: 270px;
				} */
/******************************************
				DONUT-CHART by @kseso https://codepen.io/Kseso/pen/phiyL
				******************************************/
/* .donut-chart {
					  position: relative;
						width: 200px;
					  height: 200px;
						margin: 0 auto 2rem;
						border-radius: 100%
					 } */
/* p.center-date {
					  background: #394264;
					  position: absolute;
					  text-align: center;
						font-size: 28px;
					  top:0;left:0;bottom:0;right:0;
					  width: 130px;
					  height: 130px;
					  margin: auto;
					  border-radius: 50%;
					  line-height: 35px;
					  padding: 15% 0 0;
					} */
/* .center-date span.scnd-font-color {
					 line-height: 0;
					} */
/* .recorte {
					    border-radius: 50%;
					    clip: rect(0px, 200px, 200px, 100px);
					    height: 100%;
					    position: absolute;
					    width: 100%;
					  } */
/* .quesito {
					    border-radius: 50%;
					    clip: rect(0px, 100px, 200px, 0px);
					    height: 100%;
					    position: absolute;
					    width: 100%;
					    font-family: monospace;
					    font-size: 1.5rem;
					  }
					#porcion1 {
					    -webkit-transform: rotate(0deg);
					    transform: rotate(0deg);
					  }

					#porcion1 .quesito {
					    background-color: #E64C65;
					    -webkit-transform: rotate(76deg);
					    transform: rotate(76deg);
					  }
					#porcion2 {
					    -webkit-transform: rotate(76deg);
					    transform: rotate(76deg);
					  }
					#porcion2 .quesito {
					    background-color: #11A8AB;
					    -webkit-transform: rotate(140deg);
					    transform: rotate(140deg);
					  }
					#porcion3 {
					    -webkit-transform: rotate(215deg);
					    transform: rotate(215deg);
					  }
					#porcion3 .quesito {
					    background-color: #4FC4F6;
					    -webkit-transform: rotate(113deg);
					    transform: rotate(113deg);
					  }
					#porcionFin {
					    -webkit-transform: rotate(-32deg);
					    transform:rotate(-32deg);
					  }
					#porcionFin .quesito {
					    background-color: #FCB150;
					    -webkit-transform: rotate(32deg);
					    transform: rotate(32deg);
					  } */
/******************************************
				END DONUT-CHART by @kseso https://codepen.io/Kseso/pen/phiyL
				******************************************/
.os-percentages {
  padding-top: 36px;
}

.os-percentages li {
  width: 75px;
  border-left: 1px solid #394264;
  text-align: center;
  background: #50597b;
}

.os {
  margin: 0;
  padding: 10px 0 5px;
  font-size: 15px;
}

.os.ios {
  border-top: 4px solid #e64c65;
}

.os.mac {
  border-top: 4px solid #11a8ab;
}

.os.linux {
  border-top: 4px solid #fcb150;
}

.os.win {
  border-top: 4px solid #4fc4f6;
}

.os-percentage {
  margin: 0;
  padding: 0 0 15px 10px;
  font-size: 25px;
}

.line-chart-block {
  height: 400px;
}

.line-chart {
  height: 200px;
  background: #11a8ab;
}

/******************************************
				LINE-CHART by @kseso https://codepen.io/Kseso/pen/phiyL
				******************************************/
.grafico {
  padding: 2rem 1rem 1rem;
  width: 100%;
  height: 100%;
  position: relative;
  color: #fff;
  font-size: 80%;
}

.grafico span {
  display: block;
  position: absolute;
  bottom: 3rem;
  left: 2rem;
  height: 0;
  border-top: 2px solid;
  -webkit-transform-origin: left center;
  transform-origin: left center;
}

.grafico span > span {
  left: 100%;
  bottom: 0;
}

[data-valor="25"] {
  width: 75px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

[data-valor="8"] {
  width: 24px;
  -webkit-transform: rotate(65deg);
  transform: rotate(65deg);
}

[data-valor="13"] {
  width: 39px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

[data-valor="5"] {
  width: 15px;
  -webkit-transform: rotate(50deg);
  transform: rotate(50deg);
}

[data-valor="23"] {
  width: 69px;
  -webkit-transform: rotate(-70deg);
  transform: rotate(-70deg);
}

[data-valor="12"] {
  width: 36px;
  -webkit-transform: rotate(75deg);
  transform: rotate(75deg);
}

[data-valor="15"] {
  width: 45px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

[data-valor]:before {
  content: "";
  position: absolute;
  display: block;
  right: -4px;
  bottom: -3px;
  padding: 4px;
  background: #fff;
  border-radius: 50%;
}

[data-valor="23"]:after {
  content: "+" attr(data-valor) "%";
  position: absolute;
  right: -2.7rem;
  top: -1.7rem;
  padding: 0.3rem 0.5rem;
  background: #50597b;
  border-radius: 0.5rem;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

[class^="eje-"] {
  position: absolute;
  left: 0;
  bottom: 0rem;
  width: 100%;
  padding: 1rem 1rem 0 2rem;
  height: 80%;
}

.eje-x {
  height: 2.5rem;
}

.eje-y li {
  height: 25%;
  border-top: 1px solid #777;
}

[data-ejeY]:before {
  content: attr(data-ejeY);
  display: inline-block;
  width: 2rem;
  text-align: right;
  line-height: 0;
  position: relative;
  left: -2.5rem;
  top: -0.5rem;
}

.eje-x li {
  width: 33%;
  float: left;
  text-align: center;
}

/******************************************
				END LINE-CHART by @kseso https://codepen.io/Kseso/pen/phiyL
				******************************************/
.time-lenght {
  padding-top: 22px;
  padding-left: 38px;
}

.time-lenght-btn {
  display: block;
  width: 70px;
  line-height: 32px;
  background: #50597b;
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
  margin-right: 5px;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
}

.time-lenght-btn:hover {
  text-decoration: none;
  background: #e64c65;
}

.month-data {
  padding-top: 28px;
}

.month-data p {
  display: inline-block;
  margin: 0;
  padding: 0 25px 15px;
  font-size: 16px;
}

.month-data p:last-child {
  padding: 0 25px;
  float: right;
  font-size: 15px;
}

.increment {
  color: #e64c65;
}

.media {
  height: 216px;
}

#media-display {
  position: relative;
  height: 180px;
  background: #787878
    url("https://www.fancinema.com.ar/wp-content/uploads/catwoman1.jpg") center
    top;
}

#media-display .play {
  position: absolute;
  top: 75px;
  right: 32px;
  border: 2px solid #fff;
  border-radius: 100%;
  padding: 2px 5px 2px 9px;
}

#media-display .play:hover {
  border: 2px solid #e64c65;
}

.media-control-bar {
  padding: 6px 0 0 15px;
}

.media-btn,
.time-passed {
  display: inline-block;
  margin: 0;
}

.media-btn {
  font-size: 19px;
}

.media-btn:hover,
.media-btn:hover span {
  text-decoration: none;
  color: #e64c65;
}

.play {
  margin-right: 100px;
}

.volume {
  margin-left: 30px;
}

.resize {
  margin-left: 12px;
}

.left-container .social {
  height: 110px;
}

.left-container .social li {
  width: 75px;
  height: 110px;
}

.left-container .social li .icon {
  text-align: center;
  font-size: 20px;
  margin: 0;
  line-height: 75px;
}

.left-container .social li .number {
  text-align: center;
  margin: 0;
  line-height: 34px;
}

.left-container .social .facebook {
  background: #3468af;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.left-container .social .facebook .number {
  background: #1a4e95;
  border-bottom-left-radius: 5px;
}

.left-container .social .twitter {
  background: #4fc4f6;
}

.left-container .social .twitter .icon {
  font-size: 18px;
}

.left-container .social .twitter .number {
  background: #35aadc;
}

.left-container .social .googleplus {
  background: #e64c65;
}

.left-container .social .googleplus .number {
  background: #cc324b;
}

.left-container .social .mailbox {
  background: #50597b;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.left-container .social .mailbox .number {
  background: #363f61;
  border-bottom-right-radius: 5px;
}

/************************************************** MIDDLE CONTAINER **********************************/
.middle-container {
  margin: 0 25px;
}

.profile {
  height: 410px;
}

.add-button .icon {
  float: right;
  line-height: 18px;
  width: 23px;
  border: 2px solid;
  border-radius: 100%;
  font-size: 18px;
  text-align: center;
  margin: 10px;
}

.add-button .icon:hover {
  color: #e64c65;
  border-color: #e64c65;
}

.user-name {
  margin: 25px 0 16px;
  text-align: center;
}

.profile-description {
  width: 210px;
  margin: 0 auto;
  text-align: center;
}

.profile-options {
  padding-top: 23px;
}

.profile-options li {
  border-left: 1px solid #1f253d;
}

.profile-options p {
  margin: 0;
}

.profile-options a {
  display: block;
  width: 99px;
  line-height: 60px;
  text-align: center;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
}

.profile-options a:hover {
  text-decoration: none;
  background: #50597b;
}

.profile-options a:hover.comments .icon {
  color: #fcb150;
}

.profile-options a:hover.views .icon {
  color: #11a8ab;
}

.profile-options a:hover.likes .icon {
  color: #e64c65;
}

.profile-options .icon {
  padding-right: 10px;
}

.profile-options .comments {
  border-top: 4px solid #fcb150;
}

.profile-options .views {
  border-top: 4px solid #11a8ab;
}

.profile-options .likes {
  border-top: 4px solid #e64c65;
}

.weather {
  height: 555px;
}

.weather .titular {
  background: #cc324b;
}

.weather .titular .icon {
  padding-right: 15px;
  font-size: 26px;
}

.weather .current-day {
  height: 135px;
  background: #e64c65;
}

.weather .current-day p {
  margin: 0;
  padding-left: 50px;
}

.current-day-date {
  font-size: 16px;
  padding-top: 16px;
}

.current-day-temperature {
  font-size: 70px;
}

.current-day-temperature .icon-cloudy {
  padding-left: 20px;
}

.weather .next-days {
}

.weather .next-days p {
  margin: 0;
  display: inline-block;
  font-size: 16px;
}

.weather .next-days a {
  display: block;
  line-height: 58px;
  border-bottom: 1px solid #1f253d;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
}

.weather .next-days a:hover {
  background: #50597b;
}

.weather .next-days a:hover .day {
  color: #e64c65;
}

.weather .next-days-date {
  padding-left: 20px;
}

.weather .next-days-temperature {
  float: right;
  padding-right: 20px;
}

.weather .next-days-temperature .icon {
  padding-left: 10px;
}

.tweets {
  height: 375px;
}

.tweets .titular {
  background: #35aadc;
}

.tweets .titular .icon {
  font-size: 18px;
  padding-right: 20px;
}

.tweet.first {
  height: 150px;
  border-bottom: 1px solid #1f253d;
}

.tweet p:first-child {
  margin: 0;
  padding: 30px 30px 0;
}

.tweet p:last-child {
  margin: 0;
  padding: 15px 30px 0;
}

.tweet-link {
  color: #4fc4f6;
}

.middle-container .social {
  height: 205px;
  background: #1f253d;
}

.middle-container .social li {
  margin-bottom: 12px;
}

.middle-container .social a {
  line-height: 60px;
}

.middle-container .social a:hover {
  text-decoration: none;
}

.middle-container .social .titular {
  border-radius: 5px;
}

.middle-container .social .facebook {
  background: #3468af;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
}

.middle-container .social a:hover .facebook {
  background: #1a4e95;
}

.middle-container .social a:hover .icon.facebook {
  background: #3468af;
}

.middle-container .social .twitter {
  background: #4fc4f6;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
}

.middle-container .social a:hover .twitter {
  background: #35aadc;
}

.middle-container .social a:hover .icon.twitter {
  background: #4fc4f6;
}

.middle-container .social .googleplus {
  background: #e64c65;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
}

.middle-container .social a:hover .googleplus {
  background: #cc324b;
}

.middle-container .social a:hover .icon.googleplus {
  background: #e64c65;
}

.middle-container .social .icon {
  float: left;
  width: 60px;
  height: 60px;
  text-align: center;
  font-size: 20px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

.middle-container .social .icon.facebook {
  background: #1a4e95;
}

.middle-container .social .icon.twitter {
  background: #35aadc;
}

.middle-container .social .icon.googleplus {
  background: #cc324b;
}

/* Profile */
/* @import url(https://fonts.googleapis.com/css?family=Open+sans); */
@import url(https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css);
@import url(https://fonts.googleapis.com/css?family=Playfair+Display);

.snip1515 {
  font-family: "Open Sans", Arial, sans-serif;
  position: relative;
  margin-top: 10px;
  width: 98%;
  margin-left: 10px;
  /* margin-right: 100px !important; */

  color: #000000;
  text-align: center;
  /* line-height: 1.4em; */
  font-size: 14px;
  box-shadow: none !important;
}

.snip1515 * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.snip1515 .profile-image {
  /* display: inline-block; */
  width: 10%;
  z-index: 1;
  margin-top: 0px !important;
  position: relative;
  padding: 5px 0px 5px 0px;
  border: 2px solid #e8b563;
}

.snip1515 .profile-image img {
  width: 40%;

  vertical-align: left;
}

.snip1515 figcaption {
  width: 100%;
  background-color: #f2f2f2;
  color: #555;
  padding: 10px 20px 20px;
  /* margin-top: -200px; */
  display: inline-block;
}

.snip1515 h3,
.snip1515 h4,
.snip1515 p {
  /* margin: 0 0 5px; */
}

.snip1515 p {
  margin: 0 0 5px;
}

.snip1515 h3 {
  font-weight: 600;
  font-size: 1.3em;
  font-family: "Playfair Display", Arial, sans-serif;
}

.snip1515 h4 {
  color: #8c8c8c;
  font-weight: 400;
  letter-spacing: 2px;
}

.snip1515 p {
  font-size: 0.9em;
  letter-spacing: 1px;
  opacity: 0.9;
  margin-left: 0px !important;
}

.snip1515 .icons {
  text-align: center;
  width: 100%;
}

.snip1515 i {
  padding: 10px 2px;
  display: inline-block;
  font-size: 18px;
  font-weight: normal;
  color: #e8b563;
  opacity: 0.75;
}

.snip1515 i:hover {
  opacity: 1;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}

/* card animation */
@import url("https://fonts.googleapis.com/css?family=Cardo:400i|Rubik:400,700&display=swap");
/* :root {
  --d: 700ms;
  --e: cubic-bezier(0.19, 1, 0.22, 1);
  --font-sans: "Rubik", sans-serif;
  --font-serif: "Cardo", serif;
}

* {
  box-sizing: border-box;
} */

.page-content {
  display: grid;
  grid-gap: 1rem;
  padding: 1rem;
  max-width: 1024px;
  margin: 0 auto;
  font-family: var(--font-sans);
}

@media (min-width: 600px) {
  .page-content {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 800px) {
  .page-content {
    grid-template-columns: repeat(4, 1fr);
  }
}

.card {
  position: relative;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  /* padding: 1rem; */
  height: auto;
  width: 100%;
  text-align: center;
  color: whitesmoke;
  background-color: whitesmoke;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1),
    0 4px 4px rgba(0, 0, 0, 0.1), 0 8px 8px rgba(0, 0, 0, 0.1),
    0 16px 16px rgba(0, 0, 0, 0.1);
}

@media (min-width: 600px) {
  .card {
    height: 200px;
  }
}

.card:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 110%;
  background-size: cover;
  background-position: 0 0;
  transition: transform calc(var(--d) * 1.5) var(--e);
  pointer-events: none;
}

.card:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 200%;
  pointer-events: none;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.009) 11.7%,
    rgba(0, 0, 0, 0.034) 22.1%,
    rgba(0, 0, 0, 0.072) 31.2%,
    rgba(0, 0, 0, 0.123) 39.4%,
    rgba(0, 0, 0, 0.182) 46.6%,
    rgba(0, 0, 0, 0.249) 53.1%,
    rgba(0, 0, 0, 0.32) 58.9%,
    rgba(0, 0, 0, 0.394) 64.3%,
    rgba(0, 0, 0, 0.468) 69.3%,
    rgba(0, 0, 0, 0.54) 74.1%,
    rgba(0, 0, 0, 0.607) 78.8%,
    rgba(0, 0, 0, 0.668) 83.6%,
    rgba(0, 0, 0, 0.721) 88.7%,
    rgba(0, 0, 0, 0.762) 94.1%,
    rgba(0, 0, 0, 0.79) 100%
  );
  transform: translateY(-50%);
  transition: transform calc(var(--d) * 2) var(--e);
}

.card:nth-child(1):before {
  background-image: url(https://images.unsplash.com/photo-1517021897933-0e0319cfbc28?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ);
}

/* .card:nth-child(2):before {
	background-image: url(https://images.unsplash.com/photo-1533903345306-15d1c30952de?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ);
} */

.card:nth-child(3):before {
  background-image: url(https://images.unsplash.com/photo-1545243424-0ce743321e11?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ);
}

.card:nth-child(4):before {
  background-image: url(https://images.unsplash.com/photo-1531306728370-e2ebd9d7bb99?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ);
}

/* .content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 1rem;
  transition: transform var(--d) var(--e);
  z-index: 1;
} */
/* .content > * + * {
  margin-top: 1rem;
} */

.title {
  font-size: 1.3rem;
  font-weight: bold;
  line-height: 1.2;
}

.copy {
  font-family: var(--font-serif);
  font-size: 1.125rem;
  font-style: italic;
  line-height: 1.35;
}

.btn {
  cursor: pointer;
  margin-top: 1.5rem;
  padding: 0.75rem 1.5rem;
  font-size: 0.65rem;
  font-weight: bold;
  letter-spacing: 0.025rem;
  text-transform: uppercase;
  color: white;
  background-color: black;
  border: none;
}

.btn:hover {
  background-color: #0d0d0d;
  color: white;
}

.btn:focus {
  outline: 1px dashed yellow;
  outline-offset: 3px;
}

@media (hover: hover) and (min-width: 600px) {
  .card:after {
    transform: translateY(0);
  }

  .content {
    transform: translateY(calc(100% - 4.5rem));
  }

  .content > *:not(.title) {
    opacity: 0;
    transform: translateY(1rem);
    transition: transform var(--d) var(--e), opacity var(--d) var(--e);
  }

  .card {
    align-items: center;
  }

  .card:hover:before,
  .card:focus-within:before {
    /* transform: translateY(-4%); */
  }

  .card:hover:after,
  .card:focus-within:after {
    /* transform: translateY(-50%); */
  }

  .card:hover .content,
  .card:focus-within .content {
    transform: translateY(0);
  }

  .card:hover .content > *:not(.title),
  .card:focus-within .content > *:not(.title) {
    opacity: 1;
    transform: translateY(0);
    transition-delay: calc(var(--d) / 8);
  }

  .card:focus-within:before,
  .card:focus-within:after,
  .card:focus-within .content,
  .card:focus-within .content > *:not(.title) {
    transition-duration: 0s;
  }
}

/* emoji picker*/
@import url("https://use.fontawesome.com/releases/v5.0.6/css/all.css");

/* * {
  list-style: none;
  border: none;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
} */
#emoji-picker {
  display: flex;
  flex-flow: column nowrap;
  margin: 1em auto;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  /* max-width: 320px; */
  display: none;
  width: 100% !important;
}

#emoji-picker input[type="search"] {
  font-size: 14px;
  height: 30px;
  width: 98%;
  border: 1px solid #ccc;
  border-radius: 15px;
  padding: 0 12px;
  margin: 8px;
  outline: none;
}

#emoji-picker #container {
  max-height: 194px;
  overflow-y: scroll;
  padding: 0 8px;
}

#emoji-picker #container p {
  color: #90949c;
  font-size: 10px;
  text-transform: uppercase;
}

#emoji-picker #container ol {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin-top: 4px;
}

#emoji-picker #container ol li {
  cursor: default;
  width: 10%;
  position: relative;
  overflow: hidden;
}

#emoji-picker #container ol li:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 100%;
}

#emoji-picker #container ol li:hover {
  background: #e6e6e6;
  border-radius: 4px;
}

#emoji-picker #container ol li span {
  font-size: 24px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -42%);
}

#emoji-picker #categories {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
  color: black;
}

#emoji-picker #categories li {
  cursor: default;
  width: 10%;
  position: relative;
}

#emoji-picker #categories li:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 85%;
}

#emoji-picker #categories li.active {
  background: #cce2ff;
  border-radius: 4px;
}

#emoji-picker #categories li span {
  font-size: 16px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding-top: 4px;
}

/* graph */
@-webkit-keyframes animate-width {
  0% {
    width: 0;
  }

  100% {
    visibility: visible;
  }
}

@-moz-keyframes animate-width {
  0% {
    width: 0;
  }

  100% {
    visibility: visible;
  }
}

@keyframes animate-width {
  0% {
    width: 0;
  }

  100% {
    visibility: visible;
  }
}

@-webkit-keyframes animate-height {
  0% {
    height: 0;
  }

  100% {
    visibility: visible;
  }
}

@-moz-keyframes animate-height {
  0% {
    height: 0;
  }

  100% {
    visibility: visible;
  }
}

@keyframes animate-height {
  0% {
    height: 0;
  }

  100% {
    visibility: visible;
  }
}

#bar-chart {
  height: auto;
  width: 70%;
  position: relative;
  margin: 50px auto 0;
}

#bar-chart * {
  box-sizing: border-box;
}

#bar-chart .graph {
  height: 283px;
  position: relative;
}

#bar-chart .bars {
  height: 253px;
  padding: 0 2%;
  position: absolute;
  width: 100%;
  z-index: 10;
}

#bar-chart .bar-group {
  display: block;
  float: left;
  height: 100%;
  position: relative;
  width: 12%;
  margin-right: 10%;
}

#bar-chart .bar-group:last-child {
  margin-right: 0;
}

#bar-chart .bar-group .bar {
  visibility: hidden;
  height: 0;
  -webkit-animation: animate-height;
  -moz-animation: animate-height;
  animation: animate-height;
  animation-timing-function: cubic-bezier(0.35, 0.95, 0.67, 0.99);
  -webkit-animation-timing-function: cubic-bezier(0.35, 0.95, 0.67, 0.99);
  -moz-animation-timing-function: cubic-bezier(0.35, 0.95, 0.67, 0.99);
  animation-duration: 0.4s;
  -webkit-animation-duration: 0.4s;
  -moz-animation-duration: 0.4s;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  box-shadow: 1px 0 2px rgba(0, 0, 0, 0.15);
  border: 1px solid #2d2d2d;
  border-radius: 3px 3px 0 0;
  bottom: 0;
  cursor: pointer;
  height: 0;
  position: absolute;
  text-align: center;
  width: 25%;
}

#bar-chart .bar-group .bar:nth-child(2) {
  left: 35%;
}

#bar-chart .bar-group .bar:nth-child(3) {
  left: 70%;
}

#bar-chart .bar-group .bar span {
  display: none;
}

#bar-chart .bar-group .bar-1 {
  animation-delay: 0.3s;
  -webkit-animation-delay: 0.3s;
}

#bar-chart .bar-group .bar-2 {
  animation-delay: 0.4s;
  -webkit-animation-delay: 0.4s;
}

#bar-chart .bar-group .bar-3 {
  animation-delay: 0.5s;
  -webkit-animation-delay: 0.5s;
}

#bar-chart .bar-group .bar-4 {
  animation-delay: 0.6s;
  -webkit-animation-delay: 0.6s;
}

#bar-chart .bar-group .bar-5 {
  animation-delay: 0.7s;
  -webkit-animation-delay: 0.7s;
}

#bar-chart .bar-group .bar-6 {
  animation-delay: 0.8s;
  -webkit-animation-delay: 0.8s;
}

#bar-chart .bar-group .bar-7 {
  animation-delay: 0.9s;
  -webkit-animation-delay: 0.9s;
}

#bar-chart .bar-group .bar-8 {
  animation-delay: 1s;
  -webkit-animation-delay: 1s;
}

#bar-chart .bar-group .bar-9 {
  animation-delay: 1.1s;
  -webkit-animation-delay: 1.1s;
}

#bar-chart .bar-group .bar-10 {
  animation-delay: 1.2s;
  -webkit-animation-delay: 1.2s;
}

#bar-chart .bar-group .bar-11 {
  animation-delay: 1.3s;
  -webkit-animation-delay: 1.3s;
}

#bar-chart .bar-group .bar-12 {
  animation-delay: 1.4s;
  -webkit-animation-delay: 1.4s;
}

#bar-chart .bar-group .bar-13 {
  animation-delay: 1.5s;
  -webkit-animation-delay: 1.5s;
}

#bar-chart .bar-group .bar-14 {
  animation-delay: 1.6s;
  -webkit-animation-delay: 1.6s;
}

#bar-chart .bar-group .bar-15 {
  animation-delay: 1.7s;
  -webkit-animation-delay: 1.7s;
}

#bar-chart ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

#bar-chart .x-axis {
  bottom: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}

#bar-chart .x-axis li {
  float: left;
  margin-right: 10.5%;
  font-size: 11px;
  width: 11.5%;
}

#bar-chart .x-axis li:last-child {
  margin-right: 0;
}

#bar-chart .y-axis {
  position: absolute;
  text-align: right;
  width: 100%;
}

#bar-chart .y-axis li {
  border-top: 1px solid #4e5464;
  display: block;
  height: 63.25px;
  width: 100%;
}

#bar-chart .y-axis li span {
  display: block;
  font-size: 11px;
  margin: -10px 0 0 -60px;
  padding: 0 10px;
  width: 40px;
}

#bar-chart .stat-1 {
  background-image: -webkit-linear-gradient(
    left,
    #ff4500 0%,
    #ff4500 47%,
    #cf3a02 50%,
    #cf3a02 100%
  );
  background-image: linear-gradient(
    to right,
    #ff4500 0%,
    #ff4500 47%,
    #cf3a02 50%,
    #cf3a02 100%
  );
}

#bar-chart .stat-2 {
  background-image: -webkit-linear-gradient(
    left,
    #b8f123 0%,
    #b8f123 47%,
    #79a602 50%,
    #79a602 100%
  );
  background-image: linear-gradient(
    to right,
    #b8f123 0%,
    #b8f123 47%,
    #79a602 50%,
    #79a602 100%
  );
}

#bar-chart .stat-3 {
  background-image: -webkit-linear-gradient(
    left,
    #00c5ff 0%,
    #00c5ff 47%,
    #0383a9 50%,
    #0383a9 100%
  );
  background-image: linear-gradient(
    to right,
    #00c5ff 0%,
    #00c5ff 47%,
    #0383a9 50%,
    #0383a9 100%
  );
}

.list_element:hover {
  color: grey !important;
}

.central_tab_menu:hover {
  transform: scale(0.9);
}

/* donut chart */

/* line chart */
.pie-chart {
  background: radial-gradient(
      circle closest-side,
      #f2f2f2 0,
      #f2f2f2 46.02%,
      transparent 46.02%,
      transparent 59%,
      #f2f2f2 0
    ),
    conic-gradient(
      from 120deg,
      #4e79a7 0,
      #4e79a7 38%,
      /* #f28e2c 0,
			#f28e2c 61%, */ /* #e15759 0,
			#e15759 77%, */
        /* #76b7b2 0,
			#76b7b2 87%, */ #59a14f 0,
      #59a14f 93%,
      #edc949 0,
      #edc949 100%
    );
  position: relative;
  width: 100%;
  min-height: 350px;
  background-color: #f2f2f2;
  margin: 0;
}

.pie-chart h2 {
  position: absolute;
  margin: 1rem;
}

.pie-chart cite {
  position: absolute;
  bottom: 0;
  font-size: 80%;
  padding: 1rem;
  color: gray;
}

.pie-chart figcaption {
  position: absolute;
  bottom: 1em;
  right: 1em;
  font-size: smaller;
  text-align: right;
}

.pie-chart span:after {
  display: inline-block;
  content: "";
  width: 0.8em;
  height: 0.8em;
  margin-left: 0.4em;
  height: 0.8em;
  border-radius: 0.2em;
  background: currentColor;
}

/* Progress status */
.progress_status {
  width: 200px;
  height: 200px;
  position: absolute;
  left: 50%;
  top: 80%;
  transform: translate(-50%, -50%);
  color: var(--darkBlue);
  font: normal 64px Optima;
  text-align: center;
}

.progress_status:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  font-size: 0;
}

.progress_status:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  border: 30px solid #fa0;
  border-radius: 50%;
  box-sizing: border-box;
}

.progress_status b:after,
.progress_status b:before {
  content: "";
  position: absolute;
  z-index: 2;
  overflow: hidden;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: 30px solid;
  border-radius: 50%;
  border-color: transparent;
  border-top-color: #0af;
  box-sizing: border-box;
  transform: rotate(45deg);
}

.progress_status b::after {
  transform: rotate(99deg);
  z-index: 3;
}

/* activity log */
.dateclass {
  width: 30%;
  border: 1px solid darkgrey;
  margin-left: 0px !important;
}

.dateclass.placeholderclass::before {
  width: 100%;
  content: attr(placeholder);
  margin-left: 0px !important;
}

.dateclass.placeholderclass:hover::before {
  margin-left: 0px !important;
  width: 0%;
  content: "";
}

/* design activity log box */
/* .container {
	padding: 5% 5%;
  }
   */
/* CSS talk bubble */
.talk-bubble {
  margin: 40px;
  margin-top: 0px;
  display: inline-block;
  position: relative;
  width: 470px;
  height: auto;
  background-color: rgb(223, 219, 219);
  margin-left: 2px;
}

.border {
  border: 8px solid #666;
}

.round {
  /* border-radius: 30px; */
  /* -webkit-border-radius: 30px; */
  /* -moz-border-radius: 30px; */
}

/* Right triangle placed top left flush. */
.tri-right.border.left-top:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -40px;
  right: auto;
  top: -8px;
  bottom: auto;
  border: 32px solid;
  border-color: #666 transparent transparent transparent;
}

.tri-right.left-top:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -20px;
  right: auto;
  top: 0px;
  bottom: auto;
  border: 22px solid;
  border-color: rgb(223, 219, 219) transparent transparent transparent;
}

/* Right triangle, left side slightly down */
.tri-right.border.left-in:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -40px;
  right: auto;
  top: 30px;
  bottom: auto;
  border: 20px solid;
  border-color: #666 #666 transparent transparent;
}

.tri-right.left-in:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -20px;
  right: auto;
  top: 38px;
  bottom: auto;
  border: 12px solid;
  border-color: rgb(223, 219, 219) rgb(223, 219, 219) transparent transparent;
}

/*Right triangle, placed bottom left side slightly in*/
.tri-right.border.btm-left:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -8px;
  right: auto;
  top: auto;
  bottom: -40px;
  border: 32px solid;
  border-color: transparent transparent transparent #666;
}

.tri-right.btm-left:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: 0px;
  right: auto;
  top: auto;
  bottom: -20px;
  border: 22px solid;
  border-color: transparent transparent transparent rgb(223, 219, 219);
}

/*Right triangle, placed bottom left side slightly in*/
.tri-right.border.btm-left-in:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: 30px;
  right: auto;
  top: auto;
  bottom: -40px;
  border: 20px solid;
  border-color: #666 transparent transparent #666;
}

.tri-right.btm-left-in:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: 38px;
  right: auto;
  top: auto;
  bottom: -20px;
  border: 12px solid;
  border-color: rgb(223, 219, 219) transparent transparent rgb(223, 219, 219);
}

/*Right triangle, placed bottom right side slightly in*/
.tri-right.border.btm-right-in:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: 30px;
  bottom: -40px;
  border: 20px solid;
  border-color: #666 #666 transparent transparent;
}

.tri-right.btm-right-in:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: 38px;
  bottom: -20px;
  border: 12px solid;
  border-color: rgb(223, 219, 219) rgb(223, 219, 219) transparent transparent;
}

/*
	  left: -8px;
	right: auto;
	top: auto;
	  bottom: -40px;
	  border: 32px solid;
	  border-color: transparent transparent transparent #666;
	  left: 0px;
	right: auto;
	top: auto;
	  bottom: -20px;
	  border: 22px solid;
	  border-color: transparent transparent transparent lightyellow;

  /*Right triangle, placed bottom right side slightly in*/
.tri-right.border.btm-right:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: -8px;
  bottom: -40px;
  border: 20px solid;
  border-color: #666 #666 transparent transparent;
}

.tri-right.btm-right:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: 0px;
  bottom: -20px;
  border: 12px solid;
  border-color: rgb(223, 219, 219) rgb(223, 219, 219) transparent transparent;
}

.fxzUIu {
  padding: 0px 32px 0 6px !important;
}

.fSQziN {
  margin-top: 20px !important;
}

.fbhklE {
  font-weight: bold !important;
}

.iAwKFK:not(:last-of-type) {
  border-bottom-style: solid;
  border-bottom-width: 2px !important;
  border-bottom-color: #eff6ff !important;
}

.dNgLTz {
  border-bottom-width: 2px !important;
  border-bottom-color: #eff6ff !important;
}

.dNgLTz:not(:last-of-type) {
  background: white !important;
  border-bottom-width: 2px !important;
  border-bottom-color: #eff6ff !important;
}

.hCBnvI {
  border-bottom-width: 4px !important;
  border-bottom-color: #eff6ff !important;
}

.fbhklE {
  border-bottom-width: 4px !important;
  border-bottom-color: #eff6ff !important;
}

.bhoXfZ,
.bhoXfZ {
  border-top-width: 2px !important;
  border-top-color: #eff6ff !important;
}

.gZzlir {
  display: none !important;
}

.gzECfY {
  background: #eff6ff !important;
  padding: 0 !important;
}

.bzFOCm {
  background: white !important;
  border-radius: 20px !important;
  height: 32px !important;

  border: none !important;
  font-weight: 600 !important;
}

.fSQziN {
  overflow-x: hidden !important;
}

.kvIJMH:not(:last-of-type) {
  border-bottom-style: none !important;
}

.kvIJMH {
  background-color: #f5f5f5 !important;
}

.kAsFAp:not(:last-of-type) {
  border-bottom-style: none !important;
}

.kAsFAp:not(:last-of-type) {
  background-color: #f5f5f5 !important;
}

.kdhfKA:not(:last-of-type) {
  border-bottom-style: none !important;
}

.kdhfKA:not(:last-of-type) {
  background-color: #ffffff !important;
}

.iXgrnO {
  min-height: 36px !important;
}

.cagtUi {
  font-weight: 700 !important;
}

.bpgWLe {
  height: 440px !important;
  position: relative !important;
  box-sizing: border-box !important;
  display: flex !important;
  flex-direction: column !important;
  width: 100% !important;
  max-width: 100% !important;
  color: #000000 !important;
  background-color: white !important;
}

.glxame:not(:last-of-type) {
  border-bottom-style: none !important;
}

.cta {
  background-color: transparent !important;
  /* / border: 1px solid lightgrey; / */
  border-radius: 0px !important;
  /* / color: tomato; / */
  display: inline-block;
  font-family: "Open Sans", sans-serif;
  font-size: none !important;
  font-weight: 700;
  /* / margin-bottom: 1em; /
	/ padding: 0.3em 1em; / */
  text-decoration: none;
}

.overlay {
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  z-index: 5 !important;
}

.modaloverlay {
  background: rgba(0, 0, 0, 0.8);
  bottom: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  -webkit-transition: opacity 400ms ease-in;
  -moz-transition: opacity 400ms ease-in;
  transition: opacity 400ms ease-in;
  z-index: -1;
  display: none;
}

.modaloverlay:target {
  display: block;
  opacity: 1;
  pointer-events: auto;
  z-index: 99999;
}

.modaloverlay .modalContainer {
  background-color: #f2f2f2;
  height: auto;
  padding-bottom: 10px;
  position: relative;
  margin: 0 auto;
  border-radius: 10px;
  /* / padding: 3em; / */
  overflow-y: auto !important;
  -webkit-overflow-scrolling: touch;
}

.modaloverlay .modalContainer::-webkit-scrollbar {
  width: 8px;
}

.modaloverlay .modalContainer::-webkit-scrollbar-track {
  /* / -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);  / */
  border-radius: 10px;
}

.modaloverlay .modalContainer::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* / -webkit-box-shadow: inset 0 0 6px rgba(248, 7, 7, 0.5);  / */
  background-color: rgb(196, 193, 193);
}

@media (min-width: 500px) {
  .modaloverlay .modalContainer {
    height: auto;
    margin: 5% auto;
    max-height: 57em;
    max-width: 66em;
    width: 50%;
  }
}

.modaloverlay .modalContainer > iframe,
.modaloverlay .modalContainer > div {
  border: none;
  width: 100%;
  height: auto !important;
  overflow-y: auto !important;
}

/* .modaloverlay .closeContainer {
	background-color: #e4e6eb;
	color: #525963;
	font-size: 22px;
	padding: 0px 8px;
	position: absolute;
	right: 4px;
	border-radius: 50%;
	text-align: center;
	text-decoration: none;
	top: 4px;
	z-index: 1;
  } */
/* .modaloverlay .closeContainer:hover {
	background-color: #e4e6eb;
	color: #525963;
	font-size: 24px;
	padding: 0px 12px;
	position: absolute;
	right: 4px;

	border-radius: 50%;
	text-align: center;
	text-decoration: none;
	top: 4px;
	z-index: 1;
} */

@media screen and (min-width: 0px) and (max-width: 800px) {
  .fSQziN {
    width: 220% !important;
  }

  /* .datatable_header
	{
		width:90% !important;
	} */
}

@media screen and (min-width: 0px) and (max-width: 800px) {
  .fSQziN {
    overflow-x: auto !important;
  }
}

.logout_account:hover {
  background: transparent !important;
}

/* / RESPONSIVE UPLOAD IMAGE / */
.add-img {
  margin-left: 0px;
}

.image-logo {
  width: 100px !important;
  height: 100% !important;
  margin-left: 0px;
}

@media screen and (min-width: 250px) and (max-width: 1280px) {
  .img-label {
    margin-left: 10px;
  }
}

@media screen and (min-width: 250px) and (max-width: 1280px) {
  .img-text {
    margin-left: -30px !important;
  }

  /* .img-button{
		margin-left: -28px!important;
		margin-top: 4px!important;

	} */
}

.fxzUIu {
  padding: 0px 32px 0 2px !important;
}

/* Right triangle, right side slightly down*/
.tri-right.border.right-in:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: -40px;
  top: 30px;
  bottom: auto;
  border: 20px solid;
  border-color: #666 transparent transparent #666;
}

.tri-right.right-in:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: -20px;
  top: 38px;
  bottom: auto;
  border: 12px solid;
  border-color: rgb(223, 219, 219) transparent transparent rgb(223, 219, 219);
}

/* Right triangle placed top right flush. */
.tri-right.border.right-top:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: -40px;
  top: -8px;
  bottom: auto;
  border: 32px solid;
  border-color: #666 transparent transparent transparent;
}

.tri-right.right-top:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: -20px;
  top: 0px;
  bottom: auto;
  border: 20px solid;
  border-color: rgb(223, 219, 219) transparent transparent transparent;
}

/* talk bubble contents */
.talktext {
  padding: 1em;
  text-align: left;
  line-height: 1.5em;
}

.talktext p {
  /* remove webkit p margins */
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
}

/* activity log */
/*- Screen Sizes*/
/*import fonts*/
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css");
/* @import url("https://fonts.googleapis.com/css?family=Open+Sans"); */
@import url("https://fonts.googleapis.com/css?family=Oswald");
/*media queries*/
/* html {
  box-sizing: border-box;
} */

/* *, *:before, *:after {
  box-sizing: inherit;
} */

/* .wrapper {
  margin: 3px auto;

  max-width: 1000px;
} */

.timeline {
  line-height: 1.5em;
  font-size: 14px;
  transition: all 0.4s ease;
  position: relative;
  counter-reset: section;
}

.timeline:before {
  content: "";
  width: 5px;
  height: 100%;
  background: darkgrey;
  position: absolute;
  top: 0;
  left: -3.313em;
  border-radius: 40px 40px;
}

.timeline--entry {
  position: relative;
  background-color: #eeeeee;

  margin-bottom: 0px !important;
}

.timeline--entry__title {
  background-color: #dddddd;
  font-family: "Oswald", Georgia, Cambria, "Times New Roman", Times, serif;
  font-weight: 300;
  color: #339dd8;
  text-align: left;
  padding: 6px 0px 0px 10px;
  font-size: 15px;

  /* padding: 1em; */
}

.timeline--entry__title:before {
  content: "";
  display: inline-block;
  width: 1em;
  height: 1em;
  position: absolute;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  left: -1em;
  top: 1em;
  border-right: 10px solid #dddddd;
}

.timeline--entry__detail {
  background-color: #dddddd;
  /* padding: 1em; */
  padding: 0px 0px 5px 10px;
  text-align: left;
  font-size: 14px;
}

.timeline--entry:before {
  content: "";
  font-family: "Oswald", Georgia, Cambria, "Times New Roman", Times, serif;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3em;
  height: 3em;

  background-color: var(--blue);
  border: 0.2em solid white;
  border-radius: 50%;
  position: absolute;
  counter-increment: section;
  content: counter(section);
  text-shadow: 0 1px 0 #424242;
  left: -4.5em;
  margin-bottom: 0px;
}

@supports (display: grid) {
  @media (min-width: 768px) {
    .timeline {
      display: grid;
      grid-gap: 4em;
      grid-template-areas: ".  entry1" "entry2  ." ". entry3" "entry4 ." ". entry5";
    }

    .timeline:before {
      left: 49.5%;
    }

    .timeline--entry:nth-child(1) {
      grid-area: entry1;
    }

    .timeline--entry:nth-child(2) {
      grid-area: entry2;
    }

    .timeline--entry:nth-child(3) {
      grid-area: entry3;
    }

    .timeline--entry:nth-child(4) {
      grid-area: entry4;
    }

    .timeline--entry:nth-child(5) {
      grid-area: entry5;
    }

    .timeline--entry:nth-of-type(odd):before {
      left: -3.5em;
    }

    .timeline--entry:nth-of-type(even):not(:nth-of-type(odd))
      .timeline--entry__title:before {
      left: 100%;
      border-left: 10px solid #dddddd;
      border-right: 0;
    }

    .timeline--entry:nth-of-type(even):before {
      left: 102%;
    }
  }
}

/* datatable */
/* .dataTables_filter input{

	border: 1px solid black !important;

	color: black !important;
	margin-left:10px !important;
 }
 .dataTables_filter input:focus
 {
	color: black !important;
	border: 1px solid black !important;
	box-shadow: none;
 }
 .dataTables_filter {
	width: 100% !important;

	margin-left: 330px !important;
 }

 .dataTables_length {
	width: 45% !important;

	float: left !important;

 }
 .dataTables_length select{


	color: black !important;
	margin-left:0px !important;
	border: 1px solid black !important;
 }
 .dataTables_length select:focus{
	border: 1px solid black !important;
	border: 1px solid grey;
	color: black !important;
	margin-left:0px !important;
	box-shadow: none;
 } */

table.dataTable.tablesorter thead th,
table.dataTable.tablesorter tfoot th {
  background-color: #d6e9f8;
  text-align: left;
  border: 1px solid #ccc;
  font-size: 11px;
  padding: 4px;
  color: #333;
}

::placeholder {
  margin-left: 0px !important;
}

/* style table */
.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

/* student table */
.student_table {
  width: 100%;
  padding: 5px;
  border: none;
}

.student_table th {
  color: #1f3977;
  float: left;
  margin-left: 10px;
  border: none;
}

.student_table td {
  color: #1f3977;
  float: left;
  border: none;
}

.table {
  /* border-collapse: separate !important;
    border-spacing: 0 2px !important;
	margin-top: 20px; */
}

.table tr {
  background: white;
  border: none !important;
}

.table td {
  text-align: center;
}

/* modalpop up animation */
.popup-flex button {
  border: none;
  background: #333;
  color: #fff;
  padding: 10px 30px;
  border-radius: 5px;
  font-size: 17px;
  cursor: pointer;
  font-family: "Saira Semi Condensed", sans-serif;
}

.popup-flex {
  margin: 30px;
}

.popup-container {
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  transform: scale(1.3);
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(21, 17, 17, 0.61);
  display: flex;
  align-items: center;
}

.popup-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
}

.popup-content p {
  font-size: 17px;
  padding: 10px;
  line-height: 20px;
}

.popup-content span {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.popup-content span:hover,
.popup-content span:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.show {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}

.more-articles {
  margin: 30px 30px 50px;
  font-size: 20px;
}

.more-articles a {
  margin: 0 10px;
  text-decoration: underline;
}

.onlineStatus {
  background: #21bd5a;
  border: 2px solid white;
  border-radius: 50%;
  display: inline-block;
  width: 12px;
  height: 12px;
  position: absolute;
  margin-left: 38px;
  margin-top: 35px;
}

.ring-container {
  position: absolute;
}

.circle {
  width: 12px;
  height: 12px;
  background-color: #62bd19;
  border-radius: 50%;
  position: absolute;
  top: 35px;
  left: 43px;
}

.ringring {
  border: 3px solid #62bd19;
  -webkit-border-radius: 30px;
  height: 20px;
  width: 20px;
  position: absolute;
  left: 38px;
  top: 30px;
  -webkit-animation: pulsate 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  opacity: 0;
}

@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

/* dropdown on hover */
.dropbtn {
  color: white;
  font-size: 16px;
  border: none;
  cursor: pointer;
  background: white;
  padding: 0px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  margin-top: 10px;
  border: 1px solid none;
  border-radius: 10px;
  background-color: #f9f9f9;
  width: 280px;

  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  height: auto;
  width: auto;
  text-decoration: none;
  display: block;
}

/* .dropdown-content a:hover {
	background-color: #f1f1f1;
	border-radius: 10px;
}

.dropdown:hover .dropdown-content {
	display: block;
}

.dropdown:hover .dropbtn {
	background-color: white;
} */

.notification_dropup {
  padding-top: 10px;
}

.notification_dropup:hover {
  background: #e5f1ff;
}

/* vertical dropdown menu */

/* * {
	margin: 0;
	padding: 0;

	box-sizing: border-box;
  } */

.profile_dropdown {
  /* position: ; */
  /* top: 50%;
	left: 50%;
	width: 300px; */
  background: transparent;
  color: black !important;
  font-size: 16px;
  /* transform: translateX(-50%) translateY(-50%); */
  /* box-shadow: 0 0 10px rgba(0,0,0,0.9); */
}

.profile_dropdown:hover {
  color: white !important;
}

.profile_dropdown input[type="checkbox"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  display: block;
  cursor: pointer;
}

.profile_dropdown ul {
  margin: 0;
  padding: 0;
}

.profile_dropdown a {
  display: block;
  padding: 15px 20px;
  color: black;
  text-decoration: none;
}

.profile_dropdown a:hover {
  display: block;
  padding: 15px 20px;
  color: black !important;
  text-decoration: none;
}

.profile_dropdown li {
  position: relative;
  float: left;
  width: 100%;
  list-style: none;
  color: black;
  transition: 0.5s;
  /* border-top: 1px solid #555; */
}

li {
  list-style-type: none;
}

.profile_dropdown li:first-child {
  border-top: none;
}

.profile_dropdown ul > li.sub > a:after {
  pointer-events: none;
  position: relative;
  float: right;
  font-family: "Font Awesome 5 Free";
  content: "\f054";
  font-weight: 900;
  margin-top: -0.1em;
  font-size: 1.2em;
  vertical-align: middle;
  /* transition: 0.5s; */
}

.profile_dropdown li:hover {
  /* background: var(--darkBlue) !important; */
  color: black !important;
  border: none;
}

.profile_dropdown .submenu {
  max-height: 0;
  overflow: hidden;
  border-top: none;
  /* transition: max-height 0.5s ease-in-out; */
}

.profile_dropdown input[type="checkbox"]:checked ~ .submenu {
  max-height: 999px;
  border-top: 1px solid #555;
}

.profile_dropdown input[type="checkbox"]:checked ~ a:after {
  transform: rotate(90deg);
}

.profile_dropdown .submenu a {
  padding: 10px 20px;
}

.profile_dropdown .submenu li {
  list-style-position: inside;
  padding: 10px 20px;
  list-style-type: square;
  /* background: #333; */
}

.profile_dropdown .submenu li.sub {
  list-style: none;
}

.profile_dropdown .submenu li li {
  background: transparent;
}

.profile_dropdown .submenu li li li {
  background: #2a2a2a;
}

/* dropdown notification */
.dropbtn-notification {
  color: white;
  font-size: 16px;
  border: none;
  cursor: pointer;
  background: white;
  padding: 0px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.aaaactive {
  background-color: #1f3977 !important;
  color: white !important;
  border: none !important;
}

.dropdown-content-notification {
  display: none;
  position: absolute;
  right: 0;
  margin-top: 10px;
  border: 1px solid none;
  border-radius: 10px;
  background-color: #f9f9f9;
  width: 280px;

  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content-notification a {
  color: black;
  height: auto;
  width: auto;
  text-decoration: none;
  display: block;
}

/* dashboard */
.small-box {
  /* border: 1px solid darkgrey; */
  background: #ffffff;
  margin-bottom: 0;
  border-radius: 1px;
  /* padding: 10px; */
  color: black;

  width: 100% !important;
  /* border-radius: 10px!important; */
}

/* .small-box::-webkit-scrollbar {
	 width: 5px;
 } */

/* .small-box::-webkit-scrollbar-track {

	 border-radius: 8px;
 } */

/* .small-box::-webkit-scrollbar-thumb {
	 border-radius: 8px;

	 background-color: rgb(196, 193, 193);
 } */
@media only screen and (max-width: 600px) {
  .small-box {
    width: 100%;
  }
}

.nextMonthCalender {
  width: 100%;
  border: none;
  margin: 0 !important;
  padding: 5px 25px !important;
  border: none !important;
  background-color: transparent !important;
}

/* react calender */
.react-calendar {
  border: none;
  /* font-family: Arial, Helvetica, sans-serif; */
  flex-wrap: wrap;
  /* margin: 10px 40px; */
  padding: 5px;
}

.react-calendar--doubleView {
  width: 700px;
  border: 1px solid red;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0 !important;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  /* padding: 0 !important;
	margin: 0px !important; */
  /* font-size: 2rem !important; */
  /* border:1px solid blue !important; */
  /* color: rgb(15, 70, 15); */
  /* width:10px !important; */
  font-weight: 500 !important;
  font-size: 12px !important;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
  /* border-radius: 50%;
	width: 25px;
	height: 25px; */
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}

/* .react-calendar__navigation {
	height: 0px !important;
	margin: 0px !important;
	padding: 0px !important;


}

.react-calendar__navigation button {
	width: 0px;
	height: 0px;
	background: none;
	display: none;

	padding: 0;
	margin: 0;
}

.react-calendar__navigation button[disabled] {
	background-color: #f0f0f0;

	display: none;
} */

.react-calendar__month-view__weekdays {
  /* text-align: center; */
  text-transform: none !important;
  /* font-weight: bold; */
  height: 30px !important;
  width: 100% !important;
  font-size: 15px !important;
  /* padding: 0px !important;
	margin: 0 !important; */
}

.react-calendar__month-view__weekdays {
  height: 20px !important;
}

.react-calendar__month-view__weekdays__weekday {
  height: 20px !important;
  width: 10px !important;
  font-size: 10px !important;
  text-align: center !important;
  font-family: Poppins;
  /* border: 0.5px solid #eceaea !important;
	background-color: #d10000 !important;
	color: white !important; */
  font-weight: 500 !important;
}

.react-calendar__month-view__weekNumbers {
  font-weight: bold;
  border: 1px solid red !important;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  /* display: flex;
	align-items: center;
	justify-content: center;
	font-size: 12px !important; */
  /* padding: calc(0.75em / 0.75) calc(0.5em / 0.75); */
}

.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: white !important;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  /* padding: 2em 0.5em; */
}

.react-calendar__tile {
  width: 10px !important;
  height: 30px !important;
  text-align: center;
  font-size: 11px !important;
  background-color: white !important;
  /* border: 1px solid #eceaea !important; */
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  /* background-color: red !important; */
}

.react-calendar__tile--now {
  background: #ffff76;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}

.react-calendar__tile--hasActive {
  background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active {
  background: none !important;
  color: black !important;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

/* .react-calendar__navigation__arrow {
	display: none;
	color: red;
	padding: 0;
	margin: 0;
} */

.react-calendar__navigation__next2-button {
  font-size: 19px !important;
}

.react-calendar__navigation__next-button {
  font-size: 19px !important;
}

.react-calendar__navigation__prev-button {
  font-size: 19px !important;
}

.react-calendar__navigation__prev2-button {
  font-size: 19px !important;
}

.highlight abbr {
  /* color: white !important; */
  border: none !important;
  /* background: #1f3977 !important; */
  background: #2d5dd033;
  border-radius: 50%;
  padding: 4px 5px !important;
  text-align: center !important;
  width: 10px !important;
  height: 20px !important;
  font-weight: 600;
}

.current_date abbr {
  border: none !important;
  /* background: #1f3977 !important; */
  background: #15a31233;
  border-radius: 50%;
  padding: 6px 7px !important;
  text-align: center !important;
  width: 10px !important;
  height: 20px !important;
  font-weight: 600;
}
.desc_class * {
  font-size: 10px !important;
  font-family: "Poppins";
  color: #1d1d1d !important;
  margin-left: 3px;
}

/* .calendar header .month {
    padding: 0;
    margin: 0;
    display: none;
}

 .calendar header .btn-prev  {
   display: none;
}
.calendar header .btn-next {
	display: none;
 }  */
/* modal pop up animation */
.modal.fade {
  opacity: 1;
  background: rgba(243, 242, 239, 0.3);
}

.modal.fade .modal-dialog {
  /* -webkit-transform: translate(0);
	-moz-transform: translate(0);
	transform: translate(0); */
}

.faq_categories {
  text-align: left !important;
  font-size: 13px !important;
  border: 1px solid #eaecef !important;
  padding: 5px 10px !important;
  border-radius: 3px !important;
  margin: 7px 0px !important;
}

.react-tabs__tab--selected {
  color: var(--blue) !important;
  background: white !important;
  border-top: 1px solid var(--blue) !important;
  border-left: 1px solid var(--blue) !important;
  border-right: 1px solid var(--blue) !important;
}

.ticket_tabs__tab--selected {
  background-color: red;
}

.faq_tabs .react-tabs__tab--selected {
  color: white !important;
  background: #1f3977 !important;
  border: none !important;
}

.react-tabs {
  display: flex;

  width: 470px;
  height: 400px;
}

.react-tabs__tab-list {
  display: flex;
  flex-direction: column;
  width: 170px;
  margin: 0;
  padding: 0;

  /* color: white; */
  /* background: #3c3e43; */
}

.react-tabs__tab {
  /* height: 70px; */
  list-style: none;
  padding: 12px 6px;
  cursor: pointer;
  color: #bbb;
}

.react-tabs__tab-panel {
  display: none;
  width: 300px;
}

.tab-panel {
  display: none;
  margin-top: 0;
  width: 500px !important;
  border: 1px solid red;
}

.react-tabs__tab-panel--selected {
  display: block;
}

.react-tabs__tab {
  padding-left: 10px;
}

.react-tabs__tab--selected {
  padding-left: 21px;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;

  color: #2d5dd0 !important;
  font-weight: 500 !important;
  font-size: 14px !important;
}

.panel-content {
  text-align: center;
}

/* animation to dropdown */
/* .dropdown-content {
	animation-duration: 3s;
	animation-name: slidein;
  }

  @keyframes slidein {
	0% {
		top: 0;
	  }

	  100% {
		top:40px;
	  }
  } */
.addcategory:hover {
  color: white;
}

.addnewscategory {
  color: var(--darkBlue);
}

/* hexagonal image */

.main-wrapper {
  /* width: 90%;
	max-width: 900px; */
  /* margin: 3em auto; */
  margin: 0px;
  text-align: center;
}

.badge {
  position: relative;
  margin: 5px 30px 20px 20px;
  width: 4em;
  height: 6.2em;
  border-radius: 10px;
  display: inline-block;
  top: 0;
  transition: all 0.2s ease;
}

.badge:before,
.badge:after {
  position: absolute;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  background: inherit;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.badge:before {
  transform: rotate(60deg);
}

.badge:after {
  transform: rotate(-60deg);
}

.badge:hover {
  top: -4px;
}

.badge .circle {
  width: 60px;
  height: 60px;
  position: absolute;
  background: #fff;
  z-index: 10;
  border-radius: 50%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.badge .circle i.fa {
  font-size: 2em;
  margin-top: 8px;
}

.badge .font {
  display: inline-block;
  margin-top: 1em;
}

.badge .ribbon {
  position: absolute;
  border-radius: 4px;
  padding: 5px 5px 4px;
  width: 100px;
  z-index: 11;
  color: #fff;
  bottom: 12px;
  left: 50%;
  margin-left: -55px;
  height: 15px;
  font-size: 14px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.27);
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  text-transform: uppercase;
  background: linear-gradient(to bottom right, #555 0%, #333 100%);
  cursor: default;
}

.yellow {
  background: linear-gradient(to bottom right, #ffeb3b 0%, #fbc02d 100%);
  color: #ffb300;
}

.orange {
  background: linear-gradient(to bottom right, #ffc107 0%, #f57c00 100%);
  color: #f68401;
}

.pink {
  background: linear-gradient(to bottom right, #f48fb1 0%, #d81b60 100%);
  color: #dc306f;
}

.red {
  background: linear-gradient(to bottom right, #f4511e 0%, #b71c1c 100%);
  color: #c62828;
}

.purple {
  background: linear-gradient(to bottom right, #ab47bc 0%, #4527a0 100%);
  color: #7127a8;
}

.teal {
  background: linear-gradient(to bottom right, #4db6ac 0%, #00796b 100%);
  color: #34a297;
}

.blue {
  background: linear-gradient(to bottom right, #4fc3f7 0%, #2196f3 100%);
  color: #259af3;
}

.blue-dark {
  background: linear-gradient(to bottom right, #1976d2 0%, #283593 100%);
  color: #1c68c5;
}

.green {
  background: linear-gradient(to bottom right, #cddc39 0%, #8bc34a 100%);
  color: #7cb342;
}

.green-dark {
  background: linear-gradient(to bottom right, #4caf50 0%, #1b5e20 100%);
  color: #00944a;
}

.silver {
  background: linear-gradient(to bottom right, #e0e0e0 0%, #bdbdbd 100%);
  color: #9e9e9e;
}

.gold {
  background: linear-gradient(to bottom right, #e6ce6a 0%, #b7892b 100%);
  color: #b7892b;
}

footer {
  text-align: center;
  margin: 5em auto;
}

footer a {
  text-decoration: none;
  display: inline-block;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: #111;
  border: 0.5px dashed #f8f8f8;
  color: #999;
  margin: 5px;
}

footer a:hover {
  background: #222;
}

footer a .icons {
  margin-top: 12px;
  display: inline-block;
  font-size: 20px;
}

/* tab menu */
.ah-tab-wrapper {
  position: relative;
}

.ah-tab-wrapper *,
.ah-tab-wrapper *:before,
.ah-tab-wrapper *:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.ah-tab {
  border-bottom: 1px solid #ebebeb;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  letter-spacing: -0.3em;
}

.ah-tab::before,
.ah-tab::after {
  bottom: 0;
  content-jump-tab: "";
  height: 100%;
  opacity: 0;
  position: absolute;
  z-index: 1;
  transition: opacity 0.3s;
}

.ah-tab::before {
  left: -100%;
  width: 30px;
  background: -moz-linear-gradient(
    right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 70%,
    rgba(255, 255, 255, 1) 99%
  );
  background: -webkit-linear-gradient(
    right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 70%,
    rgba(255, 255, 255, 1) 99%
  );
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 70%,
    rgba(255, 255, 255, 1) 99%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=1);
}

.ah-tab::after {
  right: 100%;
  width: 80px;
  background: -moz-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 40%,
    rgba(255, 255, 255, 1) 99%
  );
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 40%,
    rgba(255, 255, 255, 1) 99%
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 40%,
    rgba(255, 255, 255, 1) 99%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=1);
}

.ah-tab-overflow-left::before {
  opacity: 1;
  left: 0;
}

.ah-tab-overflow-right::after {
  opacity: 1;
  right: 0;
}

.ah-tab-overflow-wrapper {
  position: absolute;
  width: 43px;
  height: 100%;
  right: 100%;
  opacity: 0;
  top: 0;
  z-index: 1;
  text-align: center;
}

.ah-tab-overflow-wrapper[data-ah-tab-active="true"] {
  right: 0;
  opacity: 1;
  transition: opacity 0.3s;
}

.ah-tab-overflow-menu {
  /* background: transparent url(images/ah-tab-overflow-menu.svg) center center no-repeat; */
  background-size: 18px;
  display: inline-block;
  width: 100%;
  height: 100%;
  border: 0;
  cursor: pointer;
  transition: opacity 0.3s;
  opacity: 0.7;
}

.ah-tab-overflow-wrapper:hover .ah-tab-overflow-menu {
  opacity: 1;
}

.ah-tab-overflow-list {
  position: absolute;
  top: -1000%;
  right: 0;
  text-align: left;
  background-color: #fff;
  border: 1px solid #e9e9e9;
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.09);
  list-style: none;
  transition: opacity 0.3s;
  opacity: 0;
  max-width: 290px;
}

.ah-tab-overflow-list::after {
  content-jump-tab: " ";
  display: block;
  position: absolute;
  top: -14px;
  right: 14px;
  width: 0;
  height: 0;
  border: 7px solid transparent;
  border-bottom-color: #fff;
}

.ah-tab-overflow-list::before {
  content-jump-tab: " ";
  display: block;
  position: absolute;
  top: -16px;
  right: 13px;
  width: 0;
  height: 0;
  border: 8px solid transparent;
  border-bottom-color: #cdcdcd;
}

.ah-tab-overflow-wrapper:hover .ah-tab-overflow-list {
  top: 99%;
  padding: 10px 0;
  opacity: 1;
}

.ah-tab-overflow-list:hover {
  display: block;
}

.ah-tab-item {
  color: grey;
  /* background: red; */
  cursor: pointer;
  display: inline-block;
  letter-spacing: normal;
  transition: all 0.3s;
  text-decoration: none;
  /* padding:10px; */
  font-size: 16px;
}

.ah-tab {
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 10px;
}

.ah-tab::-webkit-scrollbar {
  margin-top: 10px;
  width: 100%;
  border-radius: 10px;
  height: 5px;
}

.ah-tab::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  margin-top: 10px;
}

.ah-tab::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background-color: darkgrey;
  margin-top: 10px;
}

.ah-tab > .ah-tab-item {
  border-bottom: 3px solid transparent;
  margin-right: 30px;
  padding: 15px 0;
}

@media (min-width: 768px) {
  .ah-tab > .ah-tab-item {
    margin-right: 50px;
  }
}

.ah-tab-item[data-ah-tab-active="true"] {
  color: white;
  /* text-decoration:none; */
  /* color:#fff; */
  /* font-family:arial; */
  /* background-color:#1F3977 !important; */
  padding-bottom: 50px;
  border-radius: 0px 0px 100px 100px;
  border-bottom: 10px solid #c7c4c4;
  transition: 0.4s;
}

.ah-tab > .ah-tab-item:hover {
  border-bottom: 3px solid #c7c4c4;
  transition: 0.4s;
}

.ah-tab > .ah-tab-item[data-ah-tab-active="true"] {
  border-bottom-color: #c7c4c4;
  transition: 0.4s;
}

.ah-tab-overflow-list > .ah-tab-item {
  border-left: 3px solid transparent;
  display: block;
  overflow: hidden;
  padding: 10px 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ah-tab-overflow-list > .ah-tab-item:hover {
  border-left-color: #8b8b8b;
  transition: 0.4s;
}

.ah-tab-overflow-list > .ah-tab-item[data-ah-tab-active="true"] {
  border-left-color: #215393;
}

.ah-tab-content-jump-tab-wrapper {
  margin: 30px 0;
}

.ah-tab-content-jump-tab {
  display: none;
  padding: 30px;
  border: 1px solid #ebebeb;
}

.ah-tab-content-jump-tab[data-ah-tab-active="true"] {
  display: block;
}

/* table */
.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  width: 100%;
  font-size: 0.9em;
  font-family: sans-serif;
  /* min-width: 400px; */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
  background-color: var(--darkBlue);
  color: #ffffff;
  text-align: left;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid var(--blue);
}

.styled-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}

abbr[title] {
  border-bottom: none !important;
  cursor: inherit !important;
  text-decoration: none !important;
}

/* input emoji */
.full-width {
  width: 100%;
  height: 100vh;
  display: flex;
}

.full-width .justify-content-center {
  display: flex;
  align-self: center;
  width: 100%;
}

.full-width .lead.emoji-picker-container {
  width: 300px;
  display: block;
}

.full-width .lead.emoji-picker-container input {
  width: 100%;
  height: 50px;
}

/* faq categories */

.proposal {
  width: 100%;
}

/* ui
.ui-tabs-vertical
{
	width: 55em;
}
  .ui-tabs-vertical .ui-tabs-nav
  {
	  padding: .2em .1em .2em .2em;
	  float: left;
	  width: 12em;
	}
  .ui-tabs-vertical .ui-tabs-nav li
   {
	   clear: left;
	   width: 100%;
	   border: 1px solid black;
	   background: #f4f4f4;
	   border-bottom-width: 1px !important;
	   border-right-width: 1px !important;
	   margin: 0 -1px .2em 0;
	}
  .ui-tabs-vertical .ui-tabs-nav li a
  {
	  display:block;
	}
  .ui-tabs-vertical .ui-tabs-nav li.ui-tabs-active
   {
	   padding-bottom: 5px;

	   padding-right: .1em;
	  background: var(--blue);
	color: white;
	   border-right-width: 1px;
	   border-right-width: 1px;
	}
  .ui-tabs-vertical .ui-tabs-panel
  {
	  padding: 1em;
	   float: right;
	   color: white;
	   width: 40em;
	} */
@import url("https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900&display=swap");

.unicircle_login {
  position: relative;
  min-height: 100vh;
  box-sizing: border-box;
  /* background-color: #f4f4f4; */
  /* background-image: url("../../public/dist/img/login_image.png"); */
  display: grid;
  width: 100%;
  height: 100%;
  padding: 0px !important;
  margin: 0;
}

.unicircle_login .container {
  position: absolute;

  max-width: 100%;
  display: grid;
  height: 100vh;

  background: #f4f4f4;
  overflow: hidden;
  margin: 0px !important;
  padding: 0 !important;
}

@media screen and (min-width: 0px) and (max-width: 800px) {
  .unicircle_login .container {
    max-width: 100%;

    height: 100vh;
  }

  .unicircle_login .container .user .imgBx {
    width: 100%;
    height: 100%;
  }
}

.right_side_img {
  width: 100%;

  /* margin-top: 50px; */
  /* padding: 0px 50px 50px 50px; */
}

.unicircle_login .container .user {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
}

.unicircle_login .container .user .imgBx {
  position: relative;
  width: 100%;
  height: 100%;
  background: #f4f4f4;
  margin-right: 0 !important;
  transition: 0.5s;
}

/* .unicircle_login .container .user .imgBx img {
		position: absolute;
		top: 0px;
		left: 0px;

	  } */

.unicircle_login .container .user .formBx {
  position: relative;
  width: 50%;
  height: 100%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  transition: 0.5s;
}

.unicircle_login .container .user .formBx form h2 {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
  width: 100%;
  margin-bottom: 10px;
  color: #555;
}

.unicircle_login .container .user .formBx form input {
  position: relative;
  width: 100%;
  padding: 10px;
  background: #f5f5f5;
  color: #333;
  border: none;
  outline: none;
  box-shadow: none;
  margin: 8px 0;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 300;
}

.unicircle_login .container .user .formBx form input[type="submit"] {
  max-width: 100px;
  background: #677eff;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  transition: 0.5s;
}

.unicircle_login .container .user .formBx form .signup {
  position: relative;
  margin-top: 0px;
  font-size: 12px;
  letter-spacing: 1px;
  color: white;
  text-transform: uppercase;
  font-weight: 300;
}

.unicircle_login .container .user .formBx form .signup a {
  font-weight: 600;
  text-decoration: none;
  color: #677eff;
}

.unicircle_login .container .signupBx {
  pointer-events: none;
}

.unicircle_login .container.active .signupBx {
  pointer-events: initial;
}

.unicircle_login .container .signupBx .formBx {
  left: 100%;
}

.unicircle_login .container.active .signupBx .formBx {
  left: 0;
}

.unicircle_login .container .signupBx .imgBx {
  left: -100%;
}

.unicircle_login .container.active .signupBx .imgBx {
  left: 0%;
}

.unicircle_login .container .signinBx .formBx {
  left: 0%;
}

.unicircle_login .container.active .signinBx .formBx {
  left: 100%;
}

.unicircle_login .container .signinBx .imgBx {
  left: 0%;
}

.unicircle_login .container.active .signinBx .imgBx {
  left: -100%;
}

@media (max-width: 991px) {
  .unicircle_login .container {
    max-width: 400px;
  }

  .unicircle_login .container .imgBx {
    display: none;
  }

  .unicircle_login .container .user .formBx {
    width: 100%;
  }
}

.admin_dashboard h1 {
  /* margin-left: 80px; */
  color: white;
  font-size: 50px !important;
  font-weight: bold;
  text-shadow: 3px 0px white;
  line-height: 45px;
  margin-bottom: 50px;
  margin-top: 50px;
}

/* login pop up message */
.loginfailed-text {
  color: red;
  cursor: pointer;
  /* top: 0px !important; */
  /* text-align: center !important; */
  margin-left: 50px;
  margin-top: 5px;
}

.login-text {
  color: green;
  /* text-align: center; */
  margin-left: 50px;
  margin-top: 5px;
  /* font-size: 5vw;  */
  cursor: pointer;
  /* top: 0px !important; */
}

.myAlert-bottom {
  /* position: absolute;
		top: 290px !important; */
  border-radius: 5px;
  /* / left:50px; / */
  width: 100%;
  border-left: 5px solid green;
  height: auto;
  background-color: #f4f4f4;
  color: red;
  /* display: block; */
  min-height: 30px;
}

.failed-bottom {
  /* position: absolute;
		top: 290px !important; */
  border-radius: 5px;
  /* / left:50px; / */
  width: 100%;
  border-left: 5px solid red;
  height: auto;
  background-color: #f4f4f4;
  color: red;
  display: none;
  min-height: 30px;
}

/* .failed-bottom
	{
	  position: absolute;
	  top: 290px !important;
	  border-radius: 5px;
	  left:50px;
	width: 27%;
	border-left: 5px solid red;
	height:30px;
	background-color: #f4f4f4;
	color: red;
	display: none;


	} */

/* @keyframes example {
		0%   {left:20px; top:0px;}
		25%  {left:150px; top:0px;}

		100% {left:20px; top:0px;}
	  }
	  @keyframes sucess_example {
		0%   {left:20px; top:0px;}
		25%  {left:150px; top:0px;}

		100% {left:20px; top:0px;}
	  } */

.unicircle_img {
  /* background-image: url(../Images/image.png); */
  /* position:absolute; */
  background-repeat: no-repeat;
  background-size: cover;
  /* margin:10px ; */
  margin-top: 0px;
  width: 100px;
  /* border: 1px solid white; */
  height: 120px;
}

li {
  list-style-type: none;
}

/* .alert {
  padding: 20px;
  background-color: #f44336;
  color: white;
} */

.fc-right {
  display: none;
}

.success_img {
  /* background-image: url(../Images/success.gif); */
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 10px;
  margin-top: 0px;
  width: 35px;
  /* border: 1px solid white; */
  height: 30px;
  margin-left: 8px;
}

.fail_img {
  /* background-image: url(../Images/wrong.gif); */
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 10px;
  margin-top: 0px;
  width: 30px;
  /* border: 1px solid white; */
  height: 30px;
  margin-left: 8px;
}

.list-inline-item {
  font-size: 12px;
  text-align: center;
}

label {
  cursor: pointer;
}

#upload-photo {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

/* student tabs*/
@import url("https://fonts.googleapis.com/css?family=Lato");

.tabs {
  width: 100%;
  height: auto;
  float: none;
  list-style: none;
  padding: 10px 0px 0px 28px;
  text-align: center;
  position: relative;
  margin: 3px 0 0 0px;
}

.tabs li {
  float: left;
  display: block;
  margin-right: 5px;
  font-size: 10px !important;
  font-weight: 500;
}

.job_logo_img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  object-fit: contain;
}

#blah img {
  width: 100% !important;
  height: 100% !important;
}
/* ---------------------------------*/
/* .student_inner_div{

		margin-left: 15px;
		margin-top: 100px;
		background: rgb(255, 255, 255);
		width: 695px;
		border-radius: 7px;

 } */

/* #file-ip-1-preview img {
	width: 25% !important;
	height: 100% !important;
} */

.fileContainer p {
  display: none !important;
}

.fileContainer .chooseFileButton {
  padding: 6px 23px;
  background: rgba(71, 121, 240, 0.3) !important;
  border-radius: 30px;
  color: #2d5dd0 !important;
  font-weight: 300;
  font-size: 14px;
  margin-top: -28px !important;
  margin-right: auto !important;
  transition: all 0.2s ease-in;
  cursor: pointer;
  outline: none;
  border: none;
}

.fileContainer .uploadPictureContainer {
  width: 8% !important;
  margin: 0% !important;
  padding: 10px;
  background: #edf2f6;
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
  box-shadow: 0 0 8px 2px rgb(0 0 0 / 10%);
  border: 1px solid #d0dbe4;
  position: relative;
  margin-right: 12px !important;
}

.fileContainer .deleteImage {
  position: absolute !important;
  top: 0px !important;
  right: -9px !important;
  color: #fff !important;
  background: #ff4081 !important;
  border-radius: 50% !important;
  text-align: center !important;
  cursor: pointer !important;
  font-size: 7px !important;
  font-weight: bold !important;
  line-height: 12px !important;
  width: 12px !important;
  height: 12px !important;
}

.fileContainer .uploadPicturesWrapper {
  width: 500px;
  overflow-x: scroll !important;
}

.fileUploader {
  width: 500px;
}

.tabs input[type="radio"] {
  position: absolute;
  top: 0;
  left: -9999px;
}

.tabs label {
  display: block;
  padding: 5px 21px;
  height: 40px;
  border-radius: 5px 5px 0 0;
  font-size: 15px;
  font-weight: normal;
  /* text-transform: uppercase; */
  background: #2b6dd4;
  color: white;
  cursor: pointer;
  position: relative;
  /* top: 4px; */
  /* -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; */
}

.tabs label:hover {
  background: #703688;
}

.tabs .tab-content {
  z-index: 2;
  display: none;
  overflow: hidden;
  width: 100%;
  font-size: 17px;
  line-height: 20px;
  padding: 25px;
  position: absolute;
  top: 40px;
  left: 0;
  background: white;
}

.tabs [id^="tab"]:checked + label {
  top: 0;
  /* padding-top: 17px; */
  background: white;
  color: var(--blue);
}

p.link {
  clear: both;
  margin: 380px 0 0 15px;
}

p.link a {
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block;
  color: #fff;
  padding: 5px 10px;
  margin: 0 0px;
  background-color: #612e76;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

p.link a:hover {
  background-color: #522764;
}

/* multuple image */
/* * {
	box-sizing: border-box;
  } */

/* img {
	max-width: 100%;
  } */

.gallery-wrapper {
  max-width: 960px;
  width: 100%;

  margin: 0 auto;
  padding: 0 1em;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  grid-gap: 10px;
}

.gallery-wrapper .image-wrapper a {
  padding: 0.5em;
  display: block;

  width: 250px;
  height: 250px;
  text-decoration: none;
  color: #333;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  transition: all 200ms ease-in-out;
}

.gallery-wrapper .image-wrapper a:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}

.gallery-wrapper .image-wrapper a img {
  width: 100%;
}

.gallery-lightboxes .image-lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0ms ease-in-out;
}

.gallery-lightboxes .image-lightbox:target {
  opacity: 1;
  visibility: visible;
}

.gallery-lightboxes .image-lightbox:target .image-lightbox-wrapper {
  opacity: 1;
  transform: scale(1, 1) translateY(0);
}

.gallery-lightboxes .image-lightbox .image-lightbox-wrapper {
  transform: scale(0.95, 0.95) translateY(-30px);
  transition: opacity 500ms ease-in-out, transform 500ms ease-in-out;
  opacity: 0;
  margin: 1em auto;
  max-width: 75%;
  padding: 0.5em;
  display: inline-block;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
  position: relative;
}

.gallery-lightboxes .image-lightbox .image-lightbox-wrapper .close {
  width: 1.5em;
  height: 1.5em;
  background: #000;
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  border-radius: 50%;
  box-shadow: 0 0 0 2px white inset, 0 0 5px rgba(0, 0, 0, 0.5);
  position: absolute;
  right: -1em;
  top: -1em;
}

.gallery-lightboxes .image-lightbox .image-lightbox-wrapper .close:before {
  content: "";
  display: block;
  width: 10px;
  height: 2px;
  background: #fff;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -1px 0 0 -5px;
  transform: rotate(-45deg);
}

.gallery-lightboxes .image-lightbox .image-lightbox-wrapper .close:after {
  content: "";
  display: block;
  width: 10px;
  height: 2px;
  background: #fff;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -1px 0 0 -5px;
  transform: rotate(45deg);
}

.gallery-lightboxes .image-lightbox .image-lightbox-wrapper .arrow-left {
  position: absolute;
  top: 0;
  right: 50%;
  bottom: 0;
  left: 0;
}

.gallery-lightboxes .image-lightbox .image-lightbox-wrapper .arrow-left:before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #fff;
  border-bottom: 0;
  border-right: 0;
  border-radius: 4px 0 0 0;
  position: absolute;
  top: 50%;
  right: 100%;
  cursor: pointer;
  transform: rotate(-45deg) translateY(-50%);
}

.gallery-lightboxes .image-lightbox .image-lightbox-wrapper .arrow-right {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 50%;
}

.gallery-lightboxes
  .image-lightbox
  .image-lightbox-wrapper
  .arrow-right:before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  border: 2px solid #fff;
  border-bottom: 0;
  border-left: 0;
  border-radius: 0 4px 0 0;
  position: absolute;
  top: 50%;
  left: 100%;
  cursor: pointer;
  transform: rotate(45deg) translateY(-50%);
}

.gallery-lightboxes .image-lightbox .image-lightbox-wrapper img {
  margin: 0 auto;
  max-height: 70vh;
}

.close-btn {
  color: #000;

  font-size: 30px;
}

.close-btn:hover,
.close-btn:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal-body {
  margin-top: 10px;

  padding: 0;
}

.modal-footer {
  /* background:#eee; */
  /* padding:10px; */
  color: #000;
  text-align: center;
}

.modal-footer h3 {
  margin: 0;
}

@keyframes modalopen {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

#search_result {
  position: absolute;
  top: 62px;
  left: 594px;
  border: 1px solid #111;
  padding: 1em 7.7em;
  display: none;
}

.autocomplete {
  background: #fff;
  position: relative;
}

.autocomplete .close {
  position: absolute;
  font-size: 13px;
  z-index: 10;
  top: 17px;
  left: calc(100% - 50px);
  color: #000;
  cursor: pointer;
  display: none;
}

.autocomplete .close.visible {
  display: block;
}

.dialog {
  width: 51%;
  display: none;
  min-height: 40px;
  max-height: 329px;
  overflow: scroll;
  border-top: 1px solid #f4f4f4;
  z-index: 12 !important;
  margin: 6px 145px;

  position: absolute;
}

.dialog.open {
  display: block;
  background: #fff;
  z-index: 11 !important;
  color: black;
}

.dialog div {
  padding: 20px 10px;
  font-size: 15px;
  cursor: pointer;
  transition: all 0.2s;
  position: absolute;
  left: 93px;
  width: 100%;
  height: 100%;
}

.vertical-date {
  list-style: none;
  padding: 1em;
  text-align: left;
  border-bottom: 2px solid #000;
}

.list-daynumber {
  line-height: 0.5em;
  font-size: 46px;
  font-weight: 800;
  opacity: 0.9;
}

.list-monthname {
  font-size: 32px;
  opacity: 0.6;
  border-bottom: 5px solid #ff4d55;
  width: 59px;
}

.duration {
  display: block;
  color: #908383;
  font-weight: 600;
  margin-top: 0.8em;
}

.s_title {
  color: #ed8392;
  font-size: 1.3em;
  margin: -13px 34px;
  position: absolute;
  font-weight: 900;
}

.s_des {
  font-size: 16px;
  font-style: italic;
}

/* horizontal tabs */
#exTab1 .tab-content {
  color: white;
  background-color: #428bca;
  padding: 5px 15px;
}

#exTab4 .tab-content {
  color: white;
  background-color: #428bca;
  padding: 5px 15px;
  /* border: 0.2px solid #c4c4c4; */

  margin-bottom: 0;
}

#exTab2 h3 {
  color: white;
  background-color: #428bca;
  padding: 5px 15px;
}

/* remove border radius for the tab */

#exTab1 .nav-pills > li > a {
  border-radius: 0;
}

/* change border radius for the tab , apply corners on top*/

#exTab3 .nav-pills > li > a {
  border-radius: 4px 4px 0 0;
}

#exTab3 .tab-content {
  color: white;
  background-color: #428bca;
  padding: 5px 15px;
}

canvas {
  width: 400px;
  height: 200px;
  position: absolute;
  border: 3px solid #000000;
}

.addCourse {
  display: none;
}

/* ACTIVITY LOG DROPDOWN */
.navbar {
  min-height: 40px !important;
}

.pen .navbar-toggle {
  border: none;
  background: transparent !important;
}

.pen .navbar-toggle:hover {
  background: transparent !important;
}

.pen .navbar-toggle .icon-bar {
  width: 22px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

/* ANIMATED X */
.navbar-toggle.x .icon-bar:nth-of-type(1) {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 10% 10%;
  -ms-transform-origin: 10% 10%;
  transform-origin: 10% 10%;
}

.navbar-toggle.x .icon-bar:nth-of-type(2) {
  opacity: 0;
  filter: alpha(opacity=0);
}

.navbar-toggle.x .icon-bar:nth-of-type(3) {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 10% 90%;
  -ms-transform-origin: 10% 90%;
  transform-origin: 10% 90%;
}

/* ANIMATED X COLLAPSED */
.navbar-toggle.x.collapsed .icon-bar:nth-of-type(1) {
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
}

.navbar-toggle.x.collapsed .icon-bar:nth-of-type(2) {
  opacity: 1;
  filter: alpha(opacity=100);
}

.navbar-toggle.x.collapsed .icon-bar:nth-of-type(3) {
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
}

/* END ANIMATED X */

/* ANIMATED RIGHT ARROW */
.navbar-toggle.rarr.collapsed .icon-bar:nth-of-type(1) {
  transform: translate3d(0, 0, 0) rotate(0deg);
  transform-origin: right top;
  width: 22px;
}

.navbar-toggle.rarr.collapsed .icon-bar:nth-of-type(2) {
  transform: translate3d(0, 0, 0) rotate(0deg);
}

.navbar-toggle.rarr.collapsed .icon-bar:nth-of-type(3) {
  transform: translate3d(0, 0, 0) rotate(0deg);
  transform-origin: right bottom;
  width: 22px;
}

.navbar-toggle.rarr .icon-bar:nth-of-type(1) {
  transform: translate3d(11px, 6px, 0) rotate(45deg);
  transform-origin: right top;
  width: 11px;
}

.navbar-toggle.rarr .icon-bar:nth-of-type(2) {
  /* transform: scale(0.75); */
}

.navbar-toggle.rarr .icon-bar:nth-of-type(3) {
  transform: translate3d(11px, -6px, 0) rotate(-45deg);
  transform-origin: right bottom;
  width: 11px;
}

/* END ANIMATED RIGHT ARROW */

/* ANIMATED LEFT ARROW */
.navbar-toggle.larr.collapsed .icon-bar:nth-of-type(1) {
  transform: translate3d(0, 0, 0) rotate(0deg);
  width: 22px;
}

.navbar-toggle.larr.collapsed .icon-bar:nth-of-type(2) {
  transform: translate3d(0, 0, 0) rotate(0deg);
}

.navbar-toggle.larr.collapsed .icon-bar:nth-of-type(3) {
  transform: translate3d(0, 0, 0) rotate(0deg);
  width: 22px;
}

.navbar-toggle.larr .icon-bar:nth-of-type(1) {
  transform: translate3d(0px, 6px, 0) rotate(45deg);
  transform-origin: left top;
  width: 11px;
}

.navbar-toggle.larr .icon-bar:nth-of-type(3) {
  transform: translate3d(0px, -6px, 0) rotate(-45deg);
  transform-origin: left bottom;
  width: 11px;
}

/* END ANIMATED LEFT ARROW */

/* ANIMATED UP ARROW */
.navbar-toggle.uarr.collapsed .icon-bar:nth-of-type(1) {
  transform: translate3d(0, 0, 0) rotate(0deg);
  width: 22px;
}

.navbar-toggle.uarr.collapsed .icon-bar:nth-of-type(2) {
  transform: translate3d(0, 0, 0) rotate(0deg);
}

.navbar-toggle.uarr.collapsed .icon-bar:nth-of-type(3) {
  transform: translate3d(0, 0, 0) rotate(0deg);
  width: 22px;
}

.navbar-toggle.uarr .icon-bar:nth-of-type(1) {
  transform: translate3d(1px, 0, 0) rotate(-45deg);
  transform-origin: center center;
  width: 11px;
}

.navbar-toggle.uarr .icon-bar:nth-of-type(2) {
  transform: rotate(-90deg);
  transform-origin: center center;
}

.navbar-toggle.uarr .icon-bar:nth-of-type(3) {
  transform: translate3d(0px, -14px, 0) rotate(-135deg);
  transform-origin: right top;
  width: 11px;
}

/* END ANIMATED UP ARROW */

/* ANIMATED PLUS */
.navbar-toggle.plus.collapsed .icon-bar:nth-of-type(1) {
  transform: translate3d(0, 0, 0) rotate(0deg);
}

.navbar-toggle.plus.collapsed .icon-bar:nth-of-type(2) {
  transform: translate3d(0, 0, 0) rotate(0deg);
}

.navbar-toggle.plus.collapsed .icon-bar:nth-of-type(3) {
  transform: translate3d(0, 0, 0) rotate(0deg);
}

.navbar-toggle.plus .icon-bar:nth-of-type(1) {
  transform: translate3d(0px, 6px, 0) rotate(90deg);
  transform-origin: center center;
}

.navbar-toggle.plus .icon-bar:nth-of-type(3) {
  transform: translate3d(0px, -6px, 0) rotate(-90deg);
  transform-origin: center center;
}

/* END ANIMATED PLUS */

/* ANIMATED MINUS */
.navbar-toggle.minus.collapsed .icon-bar:nth-of-type(1) {
  transform: translate3d(0, 0, 0) rotate(0deg);
}

.navbar-toggle.minus.collapsed .icon-bar:nth-of-type(2) {
  transform: translate3d(0, 0, 0) rotate(0deg);
}

.navbar-toggle.minus.collapsed .icon-bar:nth-of-type(3) {
  transform: translate3d(0, 0, 0) rotate(0deg);
}

.navbar-toggle.minus .icon-bar:nth-of-type(1) {
  transform: translate3d(0, 6px, 0);
}

.navbar-toggle.minus .icon-bar:nth-of-type(3) {
  transform: translate3d(0, -6px, 0);
}

.elevation-4 {
  box-shadow: none !important;
}

/* END ANIMATED MINUS */

/* FORCE NAVBAR-TOGGLE */

.pen .navbar-header {
  float: none;
}

.pen .navbar-toggle {
  display: block;
}

.pen .navbar-collapse {
  border-top: 1px solid transparent;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
}

.pen .navbar-collapse.collapse {
  display: none !important;
}

.pen .navbar-nav {
  float: none !important;
  margin: 7.5px -15px;
}

.pen .navbar-nav > li {
  float: none;
}

.pen .navbar-nav > li > a {
  padding-top: 10px;
  padding-bottom: 10px;
}

.pen .navbar-collapse.collapse.in {
  display: block !important;
}

.pen .navbar-brand {
  font-size: 14px;
}

.event-color-c {
  display: flex;
  margin: 16px;
  align-items: center;
  cursor: pointer;
}

.event-color-label {
  flex: 1 0 auto;
}

.event-color {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  margin-right: 10px;
  margin-left: 240px;
  background: #5ac8fa;
}

.crud-color-row {
  display: flex;
  justify-content: center;
  margin: 5px;
}

.crud-color-c {
  padding: 3px;
  margin: 2px;
}

.crud-color {
  position: relative;
  min-width: 46px;
  min-height: 46px;
  margin: 2px;
  cursor: pointer;
  border-radius: 23px;
  background: #5ac8fa;
}

.crud-color-c.selected,
.crud-color-c:hover {
  box-shadow: inset 0 0 0 3px #007bff;
  border-radius: 48px;
}

.crud-color:before {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -10px;
  margin-left: -10px;
  color: #f7f7f7;
  font-size: 20px;
  text-shadow: 0 0 3px #000;
  display: none;
}

.crud-color-c.selected .crud-color:before {
  display: block;
}

.course::-webkit-scrollbar {
  width: 5px;
}

.course::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);  */
  border-radius: 8px;
}

.course::-webkit-scrollbar-thumb {
  border-radius: 8px;
  /* -webkit-box-shadow: inset 0 0 6px rgba(248, 7, 7, 0.5);  */
  background-color: rgb(196, 193, 193);
}

/* Event Calender Pallavi :start */

#EventLog b {
  color: #388e3c;
}

hr {
  margin: 1px 10px 1px 0px;
  border-top: 1px solid #eee;
}

/* custom code end*/

.e-schedule .e-schedule-toolbar .e-icon-schedule-print::before {
  content: "\e973";
}

.e-schedule .e-schedule-toolbar .e-icon-schedule-pdf::before {
  content: "\e7c8";
}

.custom-field-row {
  margin-bottom: 20px;
}

.multi-prop div {
  padding-left: 0;
  padding-top: 0;
}

.rsc {
  margin-left: 310px;
  margin-top: 26px;
}

/* / appointment table status button / */
.status_button {
  border: none;
  padding: 3px;
  margin-top: 0;
  /* / background:#1F3977; / */
  flex-wrap: wrap;
  border-radius: 5px;
  width: 80px;
  text-align: center;
}

.status_text {
  font-size: 12.25px;
  font-weight: 400;
  font-family: Poppins;
  text-align: center;
  color: white;
}

/* Event Calender Pallavi end */

.rc-tabs {
  border: none !important;
  width: 95% !important;
}

.rc-tabs-tab {
  border: 0;
  font-size: 20px;
  background: transparent !important;
  margin-right: 30px !important;
  display: -ms-flexbox;
  display: flex;
  outline: none;
  cursor: pointer;
  position: relative;
  font-weight: lighter;
  -ms-flex-align: center;
  align-items: center;
}

.rc-tabs-ink-bar-animated {
  background: grey !important;
  height: 2px !important;
}

.rc-tabs-tab-active {
  color: #1f3977 !important;
  font-weight: 600 !important;
}

.rc-tabs-tab-btn {
  font-weight: 500 !important;
  line-height: 32px;
  font-size: 14px !important;
  color: #1f3977 !important;
}

.addOptions {
  width: 900px !important;
  height: 25px;
  margin-top: 5px;
  border: 1px solid grey;
  background: none !important;
  font-size: 12px;
  padding-left: 5px;
}

.remove_field {
  color: black !important;
  margin-left: 5px !important;
}

/* fqa dropdown */
.styles_faq-row-wrapper__3vA1D h2 {
  color: black !important;
  font-weight: bold !important;
  font-size: 14px !important;
  margin-top: 10px !important;
}

.faq-row {
  border: 1px solid #dbdbdd !important;
  margin-top: 3px !important;
  border-radius: 3px !important;
  padding: 0 !important;
  background-color: #eff6ff !important;
  margin-right: 10px !important;
}

.faq-title {
  margin-right: 10px !important;
}

.row-title {
  margin: 0 !important;
  font-size: 12px !important;
  color: black !important;
  margin-left: 6px !important;
  font-weight: 500 !important;
}

.styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-content__QOGZd
  .styles_row-content-text__2sgAB {
  margin: 0 !important;
  font-size: 12px !important;
  color: black !important;
  margin-left: 6px !important;
  margin-right: 6px !important;
}

.faq-body {
  margin-top: 10px !important;
}

.styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-title__1YiiY
  .styles_icon-wrapper__2cftw
  svg {
  margin-top: 0px !important;
  font-size: 5px !important;
}

.styles_faq-row-wrapper__3vA1D .styles_row-body__1NvUo .styles_faq-row__2YF3c {
  background-color: #e4e9f3 !important;
  border: 0.4px solid rgba(0, 0, 0, 0.3);
}

.styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-title__1YiiY
  .styles_row-title-text__1MuhU {
  color: #1f3977 !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

.arrow-image {
  background-image: none !important;
  z-index: 50;
}

.styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-title__1YiiY.styles_expanded__3elPy
  + .styles_row-content__QOGZd {
  background: white !important;
  border: 0.4px solid rgba(0, 0, 0, 0.3) !important;
  box-sizing: border-box !important;
  border-radius: 0px 0px 2px 2px !important;
}

.styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-title__1YiiY
  .styles_icon-wrapper__2cftw {
  top: 10px !important;
  max-width: 18px !important;
  max-height: 18px !important;
}

.poi-info-window .full-width {
  width: 300px !important;
  height: 25px !important;
}

.product_item_list {
  list-style: disc !important;
}

.lecture_image:hover {
  transform: scale(1.1);
}

.calender_date_time {
  color: #1f3977;
  font-size: 12px;
}

.view_event_calendar {
  /* border-bottom:1px solid #dbdbdd; */
  width: 100%;
  font-style: normal !important;
  padding-top: 4px;
  background: transparent;
  font-weight: 400;
  font-size: 12px;
  border-radius: 5px;
}

/* attdence chart */
.donutchart {
  width: 75% !important;
  height: 75% !important;
}

text {
  font-size: 23px !important;
  font-weight: 500;
}

.Ticket_line_graph text {
  font-size: 12px !important;
}

.donutchart-legend-item-label {
  color: red !important;
}

.donutchart-legend-item-label {
  border: 1px solid red !important;
}

/* bar chart for campus news */
.chart rect {
  fill: crimson;
}

/* multi select dropdown */
select {
  font-family: "poppins";
  color: #9e9e9e;
  /* margin: 5px 0px 0px 5px !important; */
  padding-left: 3px;
  font-size: 9px !important;
  font-weight: 400;
}

#search::placeholder {
  padding-left: 0 !important;
  color: rgba(0, 0, 0, 0.6) !important;
  font-size: 9px !important;
}

.input_fields::placeholder {
  color: black !important;
  font-size: 9px !important;
}

.year_input::placeholder {
  color: #4779f0 !important;
  font-size: 9px !important;
}

.year_input_schedule::placeholder {
  color: black !important;
  font-size: 9px !important;
}

.cutsome_answers::placeholder {
  color: black !important;
  font-size: 9px !important;
}

#mySelect {
  color: black !important;
  font-size: 9px !important;
  font-weight: 500 !important;
}

.css-yk16xz-control {
  height: 34px !important;
  border-radius: 0px !important;
  border: 1px solid rgba(0, 0, 0, 0.5) !important;
  min-height: 0 !important;
}

.css-tj5bde-Svg {
  color: grey !important;
}

.css-1wa3eu0-placeholder {
  padding: 0px !important;

  color: #9e9e9e !important;
  margin: 5px 0px 0px 0px !important;
  padding-left: 0px !important;
  font-size: 9px !important;

  position: relative !important;
  text-align: center !important;
  top: 0% !important;
}

.css-1okebmr-indicatorSeparator {
  width: 0 !important;
}

.css-tlfecz-indicatorContainer {
  color: #9e9e9e;
  font-size: 13px !important;
  font-weight: 500;
  margin-top: 0px !important;
}

.form-select {
  border-radius: 0 !important;
  color: #9e9e9e;
  font-size: 9px !important;
  /* font-weight: 500; */
  background-size: 0;
}

.form-select::placeholder {
  color: #9e9e9e !important;
  margin: 5px 0px 0px 5px !important;
  /* padding-left: 2px !important; */
  font-size: 9px !important;
}

.css-g1d714-ValueContainer {
  height: 34px !important;
}

/*!
 * FullCalendar v1.6.4 Print Stylesheet
 * Docs & License: http://arshaw.com/fullcalendar/
 * (c) 2013 Adam Shaw
 */

/*
 * Include this stylesheet on your page to get a more printer-friendly calendar.
 * When including this stylesheet, use the media='print' attribute of the <link> tag.
 * Make sure to include this stylesheet IN ADDITION to the regular fullcalendar.css.
 */

/* Events
-----------------------------------------------------*/

/*
Calender Css start- Pallavi */

#EventLog b {
  color: #388e3c;
}

hr {
  margin: 1px 10px 1px 0px;
  border-top: 1px solid #eee;
}

.year-property-panel td {
  padding-bottom: 1rem;
}

/* custom code end*/

.e-schedule .e-schedule-toolbar .e-icon-schedule-print::before {
  content: "\e973";
}

.e-schedule .e-schedule-toolbar .e-icon-schedule-pdf::before {
  content: "\e7c8";
}

.custom-field-row {
  margin-bottom: 20px;
}

.multi-prop div {
  padding-left: 0;
  padding-top: 0;
}

.year-view.e-schedule .e-timeline-year-view .e-resource-column-table,
.year-view.e-schedule .e-timeline-year-view .e-resource-left-td {
  width: 120px;
}

.inline-edit.e-schedule .e-timeline-view .e-resource-left-td,
.inline-edit.e-schedule .e-timeline-month-view .e-resource-left-td {
  width: 150px;
}

/* Calender Css end (pallavi) */

.topnav .search-container {
  float: right;
  display: flex;
}

.topnav input[type="text"] {
  padding: 6px;
  margin-top: 0px;
  font-size: 17px;
  border: none;
}

.topnav .search-container button {
  float: right;
  padding: 6px 10px;
  margin-top: 8px;
  margin-right: 16px;
  background: #ddd;
  font-size: 17px;
  border: none;
  cursor: pointer;
}

.topnav .search-container button:hover {
  background: #ccc;
}

@media screen and (max-width: 600px) {
  .topnav .search-container {
    float: none;
  }

  .topnav a,
  .topnav input[type="text"],
  .topnav .search-container button {
    float: none;
    display: block;
    text-align: left;
    width: 100%;
    margin: 0;
    padding: 14px;
  }

  .topnav input[type="text"] {
    border: 1px solid #ccc;
  }
}

.switch_account:hover {
  background: rgb(241, 239, 239);
  border-radius: 10px;
}

@media screen and (min-width: 250px) and (max-width: 1200px) {
  .calendar-container {
    width: 100%;
    margin-top: 0px !important;
  }
}

@media screen and (min-width: 250px) and (max-width: 300px) {
  .small-box > .inner {
    padding: 2px;
  }
}

.calendar_inner {
  padding: 5px;
}

.displayTicket {
  /* padding: 10px; */
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.displayTicket::-webkit-scrollbar {
  width: 5px;
}

.displayTicket::-webkit-scrollbar-track {
  /* / -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);  / */
  border-radius: 8px;
}

.displayTicket::-webkit-scrollbar-thumb {
  border-radius: 8px;
  /* / -webkit-box-shadow: inset 0 0 6px rgba(248, 7, 7, 0.5);  / */
  background-color: rgb(196, 193, 193);
}

/* / RESPONSIVE MODAL / */
.cta {
  background-color: white;
  /* / border: 1px solid lightgrey; / */
  border-radius: 6px;
  /* / color: tomato; / */
  display: inline-block;
  font-family: "Open Sans", sans-serif;
  font-size: 1em;
  font-weight: 700;
  /* / margin-bottom: 1em; /
	/ padding: 0.3em 1em; / */
  text-decoration: none;
}

.cta:hover {
  font-weight: bold;
}

.modaloverlay {
  background: rgba(0, 0, 0, 0.8);
  bottom: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  -webkit-transition: opacity 400ms ease-in;
  -moz-transition: opacity 400ms ease-in;
  transition: opacity 400ms ease-in;
  z-index: -1;
  display: none;
}

.modaloverlay:target {
  display: block;
  opacity: 1;
  pointer-events: auto;
  z-index: 99999;
}

.table-cards .row .col-md-6 {
  margin-bottom: 12px !important;
}

.modaloverlay .modalContainer {
  background-color: #f2f2f2;
  height: auto;
  position: relative;
  margin: 0 auto;
  padding-bottom: 10px;
  border-radius: 10px;
  /* / padding: 3em; / */
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.modaloverlay .modalContainer::-webkit-scrollbar {
  width: 8px;
}

.modaloverlay .modalContainer::-webkit-scrollbar-track {
  /* / -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);  / */
  border-radius: 10px;
}

.modaloverlay .modalContainer::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* / -webkit-box-shadow: inset 0 0 6px rgba(248, 7, 7, 0.5);  / */
  background-color: rgb(196, 193, 193);
}

@media (min-width: 500px) {
  .modaloverlay .modalContainer {
    height: auto;
    margin: 50px auto;
    max-height: 57em;
    max-width: 66em;
    width: 40%;
  }
}

.modaloverlay .modalContainer > iframe,
.modaloverlay .modalContainer > div {
  border: none;
  width: 100%;
  height: auto;
}

.modaloverlay .closeContainer {
  background-color: #e4e6eb;
  color: #525963;
  font-size: 22px;
  padding: 0px 5px;
  position: absolute;
  right: 4px;

  border-radius: 50%;
  text-align: center;
  text-decoration: none;
  top: 4px;
  z-index: 1;
}

.modaloverlay .birthday {
  background-color: #f2f2f2;
  color: black;
  width: auto !important;
  font-weight: 600;
  font-size: 24px;
  padding: 8px 12px;
  right: 0;
  text-align: center;
  text-decoration: none;
  top: 0;
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
}

/* emoji picker*/

/* /  {
  list-style: none;
  border: none;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
} */
*/ #emoji-picker {
  display: flex;
  flex-flow: column nowrap;
  margin: 1em auto;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  /* / max-width: 320px; / */
  display: none;
  width: 100% !important;
}

#emoji-picker input[type="search"] {
  font-size: 14px;
  height: 30px;
  width: 98%;
  border: 1px solid #ccc;
  border-radius: 15px;
  padding: 0 12px;
  margin: 8px;
  outline: none;
}

#emoji-picker #container {
  max-height: 194px;
  overflow-y: scroll;
  padding: 0 8px;
}

#emoji-picker #container p {
  color: #90949c;
  font-size: 10px;
  text-transform: uppercase;
}

#emoji-picker #container ol {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin-top: 4px;
}

#emoji-picker #container ol li {
  cursor: default;
  width: 10%;
  position: relative;
  overflow: hidden;
}

#emoji-picker #container ol li:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 100%;
}

#emoji-picker #container ol li:hover {
  background: #e6e6e6;
  border-radius: 4px;
}

#emoji-picker #container ol li span {
  font-size: 24px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -42%);
}

#emoji-picker #categories {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
  color: black;
}

#emoji-picker #categories li {
  cursor: default;
  width: 10%;
  position: relative;
}

#emoji-picker #categories li:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 85%;
}

#emoji-picker #categories li.active {
  background: #cce2ff;
  border-radius: 4px;
}

#emoji-picker #categories li span {
  font-size: 16px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding-top: 4px;
}

.emoji-wysiwyg-editor {
  border: none !important;

  border-radius: 30px !important;
  background-color: #f0f2f5;
  height: 25px !important;
  padding-left: 15px !important;
}

.emoji-wysiwyg-editor::placeholder {
  font-size: 9px !important;
  /* font-weight: 500 !important; */
}

.emoji-picker-icon {
  right: 0px !important;
  top: 10px !important;
}

.emoji-menu {
  width: 100% !important;
  border: none !important;
  margin-top: 10px !important;
  z-index: 5 !important;
  position: relative !important;
  overflow-y: auto;
}

.emoji-menu .emoji-items-wrap {
  height: 80px !important;
  overflow-y: auto !important;
}

/* media query for responsiveness (pallavi) start */
@media screen and (min-width: 250px) and (max-width: 1280px) {
  .img-label {
    margin-left: 109px !important;
    margin-top: -9px;
  }
}

.publish_button {
  font-weight: 500;
  border: 0px solid rgb(0, 0, 0);
  color: white;
  border-radius: 2px;
  margin-left: 8px;
  background-color: rgb(0, 0, 0) !important;
  font-size: 10px;
  width: 118px;
  height: 28px;
  border-radius: 3px;
}
.publish_button2 {
  font-weight: 500;
  border: 0px solid rgb(0, 0, 0);
  color: white;
  border-radius: 2px;
  /* margin-left: 30px; */
  background-color: #1E5FD7 !important;
  font-size: 10px;
  width: 90px;
  height: 28px;
  border-radius: 3px;
  margin-right: 15px;
}

.add_faq_button {
  font-size: 10px;
  font-weight: 500;
  margin: 0px;
  background: #ffffff;
  align-items: center;
  border-radius: 3px;

  height: 27px;
  font-family: Poppins;

  color: #000000;
  width: auto;
  border: 1px solid;
}

.add_students_modal {
  z-index: 999999;
  border-radius: 5px;
  width: 180px;
  padding: 4px 0px 4px 0px;
  top: 15px;
  right: 29px;
  background: #ffffff;
  box-shadow: 1px 1px 1px 1px #b9b2b2;
}

#add_students_modal {
  border-radius: 5px;
  width: 160px;
  padding: 4px 0px 4px 0px;
  top: 15px;
  right: 29px;
  background: #ffffff;
  box-shadow: 1px 1px 1px 1px #b9b2b2;
}

.add_students_button {
  width: 100%;
  border: none;
  padding: 2px 8px;
  background: white;
}

.hover_class {
  background: #ffffff;
  transition: all 0.3s ease-in-out;
}

.hover_class:hover {
  background: #d9d9d9;
}

.add_student_list_modal {
  background: white;
  width: 373px;
  height: auto;
}

.student_add_new_modal {
  /* background: rgba(0, 0, 0, 0.8); */
  bottom: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  /* -webkit-transition: opacity 400ms ease-in;
	-moz-transition: opacity 400ms ease-in;
	transition: opacity 400ms ease-in; */
  /* z-index: -1; */
  display: none;
}

.student_add_new_modal:target {
  display: block;
  opacity: 1;
  pointer-events: auto;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: "0px 2px 2px rgba(0, 0, 0, 0.2)";
}

.edit_campus_modal {
  z-index: 1;
  border-radius: 5px;
  width: 180px;
  padding: 2px 10px 10px 10px;
  top: 30px;
  right: 10px;
  background: #ffffff;
  box-shadow: 1px 1px 1px 1px #c4c4c4;
}

.campus_img {
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.campus_inner_div {
  margin-left: 15px;
  font-size: 10px;
  font-weight: 600;
  align-items: center;
  display: flex;
}

.flag_inner_div {
  margin-left: 15px;
  font-size: 10px;
  font-weight: 600;
}

.hover_class {
  background: #6e77811a;
  transition: all 0.2s ease-in-out;
  padding: 2px 10px;
  margin-top: 5px;
  border: none;
  width: 100%;
}

.hover_class:hover {
  background: #d9d9d9;
  cursor: pointer;
}

.sc-iIPllB cAKknD {
  border: 1px solid #d9d9d9;
}

.border_class {
  /* border: 1px solid #D9D9D9; */
  border-radius: 3px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
}

.border_class2 {
  /* border: 1px solid #D9D9D9; */
  border-radius: 3px;
  background: #ffffff;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
}
.border_class3 {
  /* border: 1px solid #D9D9D9; */
  border-radius: 3px;
  /* background: #ffffff; */
  /* box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2); */
}


.summerNote_font {
  font-size: 13px !important;
}

.summerNote_font p font span {
  font-size: 13px !important;
}

.bLdAtF {
  color: #1f3977 !important;
}

.gEuYxO {
  height: 45px !important;
}

.polls_response_section {
  background: rgb(245, 245, 245);
  height: 29px;
  border: 0.2px solid #c4c4c4;
  padding-left: 15px;
  color: #1f3977;
  font-weight: 500;
  font-size: 10px;
  border-radius: 3px;
  width: 90%;
}

.preview_response1 {
  display: flex;
  align-items: center;
  gap: 15px;
  width: 100%;
  height: 29px;
  background: #ffffff;
}

.preview_response2 {
  background: green;
  height: 20px;
  width: 20px;
  margin-left: 13px;
}

.preview_response3 {
  background: rgb(228, 233, 243);

  height: 29px;
  display: flex;
  align-items: center;
}

.preview_response4 {
  gap: 7px;
  margin-top: 5px;
}

.preview_response5 {
  background: rgb(255, 255, 255);
  margin-top: 10px;
  padding: 10px 10px;
}

.down_arrow {
  background-image: url(../Images/RightButton.png) !important;
  background-size: 7% 46%;
  background-repeat: no-repeat;
  background-position-x: 94%;
  background-position-y: 8px;
}

.invite_button {
  font-weight: 500;
  border: none;
  color: rgb(255, 255, 255);
  width: 130px;
  border-radius: 3px;
  margin-left: auto;
  background-color: rgb(0, 0, 0);
  padding: 7px 20px;
  font-size: 12px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;
  margin-bottom: 20px;
  margin-right: 5px;
}

.faq_dot {
  width: 4px;
  height: 4px;
  background: #1f3977;
  border-radius: 50%;
  margin: 6px 12px;
}

.calender_p {
  font-size: 14px;
  font-weight: 600;
  background: rgb(108, 122, 153);
  color: white;
}

.campus_event_carousel {
  align-items: center;
  justify-content: center;
  background: rgb(108, 122, 153);
  height: 32px;
}

/* Polls Droppdown css */
.main_div {
  height: 29px;
  width: 85%;
  border: 1px solid rgb(196, 196, 196);
  background: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
}

.checked_div {
  display: flex;
  flex-direction: row;
  height: 27px;
  align-items: center;
  margin: 0px 10px 0px 15px;
  cursor: pointer;
  justify-content: space-between;
}

.checked_div_p {
  font-size: 10px;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.checked_div_colorBox {
  height: 16px;
  width: 17px;
  display: none;
}

.checked_div_colorBox2 {
  height: 16px;
  width: 17px;
  display: none;
}

.checked_div_colorBox3 {
  height: 16px;
  width: 17px;
  display: none;
}

.checked_div_colorBox4 {
  height: 16px;
  width: 17px;
  display: none;
}

.checked_div_colorBox5 {
  height: 16px;
  width: 17px;
  display: none;
}

.selection_box {
  position: relative;
  z-index: 1045;
  padding: 12px 2px;
  height: 95px;
  border: 1px solid rgb(196, 196, 196);
  background: rgba(255, 255, 255);
  border-radius: 5px;
}

.selection_div {
  display: flex;
  flex-direction: row;
  background: rgb(255, 255, 255);
  margin-bottom: 10px;
  padding-left: 12px;
  cursor: pointer;
}

.selection_inner {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 25px;
}

.down_arrow_alignment {
  display: flex;
}

.down_arrow_image {
  width: 16px;
  height: 13px;
}

.polls_followup {
  width: 100% !important;
  height: 29px !important;
  padding: 4px !important;
  font-size: 10px !important;
  color: black !important;
  border: 1px solid rgb(196, 196, 196) !important;
  border-radius: 3px !important;
  box-sizing: border-box;
  font-weight: 500 !important;
  padding-left: 15px !important;
  appearance: none !important;
}

.polls_heading_h1 {
  color: black;
  font-weight: 600;
  font-family: Poppins;
  font-size: 11px;
  line-height: 24px;
  margin-left: 42px;
}

.dnVNKM {
  margin-left: 0px !important;
}

.datatable_padding {
  background: #ffffff;
  padding-top: 10px;
}

.h1_tag {
  margin-left: 40px;
}

.preview_description {
  height: 230px;
  overflow-y: auto;
}

.edit_polls_response {
  padding: 0px;
  color: rgb(31, 57, 119);
  font-weight: 500;
  font-size: 9px;
  margin-top: 4px;
  /* background: rgb(245, 245, 245); */
}

/* feeds css  */

.feed_main_images {
  width: 100%;
  height: 280px;
  /* object-fit: contain; */
}

/* .feed_cover_image_modal {
	background: rgba(243, 242, 239, 0.60);
	display: block;
	opacity: 1;
	pointer-events: auto;
	z-index: 99999;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 129%;
	width: 100%;
	position: absolute;
	left: 50%;
	top: 35%;
	transform: translate(-50%, -50%);
	box-shadow: "0px 2px 2px rgba(0, 0, 0, 0.2)",
} */

.create_post_modal {
  background: rgba(243, 242, 239, 0.6);
  display: block;
  opacity: 1;
  pointer-events: auto;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 113%;
  width: 100%;
  position: absolute;
  /* left: 45%;
	top: 17%; */
  left: 50%;
  top: 43%;
  transform: translate(-50%, -50%);
  box-shadow: "0px 2px 2px rgba(0, 0, 0, 0.2)";
}

/* .add_to_profile_modal {
	background: rgba(243, 242, 239, 0.60);
	display: block;
	opacity: 1;
	pointer-events: auto;
	z-index: 99999;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 146%;
	width: 100%;
	position: absolute;
	left: 50%;
	top: 27%;
	transform: translate(-50%, -50%);
	box-shadow: "0px 2px 2px rgba(0, 0, 0, 0.2)",
} */

/* .add_video_modal {
	background: rgba(243, 242, 239, 0.60);
	display: block;
	opacity: 1;
	pointer-events: auto;
	z-index: 99999;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 116%;
	width: 100%;
	position: absolute;

	left: 50%;
	top: 42%;
	transform: translate(-50%, -50%);
	box-shadow: "0px 2px 2px rgba(0, 0, 0, 0.2)",
} */

/* .add_award_modal {
	background: rgba(243, 242, 239, 0.60);
	display: block;
	opacity: 1;
	pointer-events: auto;
	z-index: 99999;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	position: absolute;

	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	box-shadow: "0px 2px 2px rgba(0, 0, 0, 0.2)",
} */

/* .edit_profile_modal{
	background: rgba(243, 242, 239, 0.60);
	bottom: 0;
	left: 0;
	opacity: 0;
	pointer-events: none;
	position: fixed;
	right: 0;
	top: 0; */
/* -webkit-transition: opacity 400ms ease-in;
	-moz-transition: opacity 400ms ease-in;
	transition: opacity 400ms ease-in;  */
/* z-index: -1;
	display: none;

} */
.edit_profile_modal {
  background: rgba(243, 242, 239, 0.6);
  display: block;
  opacity: 1;
  pointer-events: auto;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 185%;
  width: 100%;
  position: absolute;
  left: 50%;
  top: 80%;
  transform: translate(-50%, -50%);
  box-shadow: "0px 2px 2px rgba(0, 0, 0, 0.2)";
}

.add_comment_modal {
  background: rgba(243, 242, 239, 0.6);
  display: block;
  opacity: 1;
  pointer-events: auto;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  /* left: 46%;
	top: 23%; */
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: "0px 2px 2px rgba(0, 0, 0, 0.2)";
}

.feed_edit_delete_modal {
  background: rgba(243, 242, 239, 0.6);
  display: block;
  opacity: 1;
  pointer-events: auto;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 129%;
  width: 100%;
  position: absolute;
  left: 50%;
  top: 120%;
  transform: translate(-50%, -50%);
  box-shadow: "0px 2px 2px rgba(0, 0, 0, 0.2)";
}

.delete_cover_img_modal {
  background: rgba(243, 242, 239, 0.6);
  display: block;
  opacity: 1;
  pointer-events: auto;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 146%;
  width: 100%;
  position: absolute;
  /* left: 43%;
	top: 8%; */
  left: 50%;
  top: 27%;
  transform: translate(-50%, -50%);
  box-shadow: "0px 2px 2px rgba(0, 0, 0, 0.2)";
}

.delete_post_modal {
  background: rgba(243, 242, 239, 0.6);
  display: block;
  opacity: 1;
  pointer-events: auto;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 146%;
	width: 100%; */
  position: absolute;

  left: 12%;
  top: 150%;
  transform: translate(-50%, -50%);
  box-shadow: "0px 2px 2px rgba(0, 0, 0, 0.2)";
}

.modal-content {
  box-shadow: rgb(196, 196, 196) 2px 2px 2px 0px;
  border: none;
}

.feed_header_image {
  background-image: url(../Images/feed_background.png);
}

.cover_image {
  width: 100%;
  height: 210px;
}

.range_input {
  height: 6px;
  background-color: #ddd;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
}

.feed_imgs_size {
  height: 120px;
  width: 100%;
  object-fit: cover;
}

.feed_font_class {
  font-size: 15px;
  font-weight: 600;
  margin-top: 20px;
}

.feed_font_class2 {
  font-size: 11px;
  font-weight: 600;
}

.feed_comment_nine_class {
  font-size: 9px;
  font-weight: 500;
}

.lable_font_size {
  font-size: 10px;
  font-weight: 500;
  margin-bottom: 3px;
}

.modal_image {
  width: 15px;
  height: 15px;
}

.feed_image_div {
  justify-content: center;
  display: flex;
  align-items: center;
}

.bottom_border {
  border-bottom: 1px solid #d9d9d9;
}

/* .header_fade {
	opacity: 0;
	transition: opacity 1s;
  } */

.header_fade.fade-in {
  opacity: 1;
  transition: opacity 1s;
  /* animation-delay: 2s; */
  /* animation: 0.7s normal; */
}

.header_fade.fade-out {
  opacity: 0;
}

.add_post_image_contener {
  display: none;
  padding: 10px;
  position: absolute;
  bottom: 42px;
  right: 81px;
  border-radius: 20px !important;
}

.edit_cancel_button {
  border: none;
  color: rgb(31, 57, 119);
  background: transparent;
  font-weight: 500;
  font-size: 10px;
}

.edit_update_button {
  background-color: rgb(0, 0, 0);
  border-radius: 3px;
  padding: 7px 0px;
  color: #ffffff;
  width: 130px;
  font-weight: 600;
  font-size: 10px;
  border: none;
}

.edit_buttons_div {
  padding: 7px;
  gap: 25px;
  align-items: center;
  position: absolute;
  width: 92%;
  bottom: 10px;
  justify-content: end;
}

.feed_edit_buttons_div {
  padding: 7px;
  gap: 25px;
  align-items: center;
}

.edit_row_padding {
  /* padding: 10px !important; */
  padding: 10px 0px !important;
}

.edit_row_padding2 {
  /* padding: 10px !important; */
  padding: 10px 0px !important;
  margin-top: 5px !important;
}

.box_padding {
  /* padding: 10px 15px !important; */
  padding: 10px 0px !important;
  margin-top: 10px !important;
}

.box_padding2 {
  padding: 10px 15px !important;
  /* padding: 10px 0px !important; */
  margin-top: 10px !important;
}

.main_heading_h1 {
  color: black;
  font-weight: 600;
  font-family: Poppins;
  font-size: 10px;
  padding: 2px 20px;
}

.all_labels {
  color: rgb(31, 57, 119);
  font-size: 9px;
  font-weight: 500 !important;
  margin: 0px;
  margin-bottom: 5px;
}
.all_labels2 {
  color: #1F3977;
  font-size: 10px;
  font-weight: 600 !important;
  margin: 0px;
  margin-bottom: 5px;
}
.all_labels3 {
  color:#4AA081;
  font-size: 10px;
  font-weight: 700 !important;
  margin: 0px;
  margin-bottom: 5px;
}


.all_inputs {
  color: black;
  width: 100% !important;
  height: 28px;
  /* border: 1px solid rgb(196, 196, 196); */
  border: 1px solid #e4e4e4;
  box-sizing: border-box;
  font-size: 10px !important;
  padding: 5px;
  border-radius: 5px !important;
  outline: none !important;
}


.all_inputs::placeholder {
  font-size: 9px !important;
}

.all_inputs:hover,
.note-editable:hover {
  border: 1px solid blue;
}

.all_inputs2 {
  color: black;
  width: 70% !important;
  height: 32px;
  /* border: 1px solid rgb(196, 196, 196); */
  border: 1px solid #e4e4e4;
  box-sizing: border-box;
  font-size: 10px !important;
  padding: 5px;
  border-radius: 5px !important;
  outline: none !important;
}
.all_inputs2::placeholder {
  font-size: 9px !important;
}

.all_inputs2:hover,
.note-editable:hover {
  border: 1px solid blue;
}











.all_stars {
  color: rgb(235, 36, 36);
  font-weight: 600;
  margin-left: 3px;
  height: 15px;
  font-size: 11px;
}

.preview_edit_img {
  height: 18px;
  width: 18px;
  cursor: pointer;
}

.all_validations_h4 {
  color: red;
  font-size: 9px;
  font-weight: 500;
  margin-left: 0px;
}

.left_padding {
  /* padding-left: 20px; */
  width: 100%;
}

.success_msg {
  display: none;
  margin-top: 5px;
  width: 100%;
}

.css-camg44-MuiPaper-root-MuiAlert-root {
  font-size: 0.7rem !important;
  padding: 0px 16px !important;
}

.css-1vooibu-MuiSvgIcon-root {
  width: 0.8em !important;
  height: 0.8em !important;
}

.preview_polls {
  display: none;
  position: fixed;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.preview_polls_inner_div1 {
  padding: 15px;
  background: rgb(245, 245, 245);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 6px 6px;
  position: absolute;
  bottom: 0px;
  top: 0px;
  right: 0px;
  width: 500px;
  height: 100%;
}
.job_preview_polls_inner_div1 {
  padding: 15px;
  background: rgb(245, 245, 245);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 6px 6px;
  position: absolute;
  bottom: 0px;
  top: 0px;
  right: 0px;
  width: 450px;
  height: 100%;
}

.preview_category {
  display: none;
  position: fixed;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.edit_inner {
  padding: 10px;
  background: rgb(245, 245, 245);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 6px 6px;
  position: absolute;
  bottom: 0px;
  top: 0px;
  right: 0px;
  width: 480px;
  height: 100%;
}

.edit_inner_div {
  border-bottom: 2px solid rgb(21, 163, 18);
  transform: rotate(0.13deg);
  padding-bottom: 5px;
  align-items: center;
}

.edit_inner_div2 {
  border-bottom: 2px solid #4779F0;
  transform: rotate(0.13deg);
  padding-bottom: 5px;
  align-items: center;
}

.main_labels {
  color: black;
  font-size: 11px;
  font-weight: 600 !important;
  margin: 0px;
}

.cancel_img {
  cursor: pointer;
  height: 17px;
  width: 17px;
}

.edit_top_container {
  border-bottom: 2px solid #4779f0;
  transform: rotate(0.13deg);
  padding-bottom: 10px;
  align-items: center;
  margin-bottom: 7px;
}

.edit_top_label p {
  margin-top: 15px;
}

.edit_top_label {
  margin-left: 15px;
  /* margin-top: 13px; */
  margin-bottom: 4px;
  font-size: 10px;
  font-weight: 500;
  color: #6b6b6b;
}

.edit_top_label_2 .datemain {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border-radius: 2px; */
  /* border-color: #00BA13; */
  margin-top: 10px;
  margin-left: 10px;
}

.publishdate,
.closedate {
  margin-right: 20px; /* Space between the two dates */
  border-radius: 2px; /* Rounded corners */
  padding: 5px; /* Optional: Add padding to make the rounded corners more visible */
  /* Optional: Add background to make the border-radius visible */
}

.preview_font {
  font-size: 14px; /* Adjust font size if needed */
  color: #000; /* Set your desired font color */
}
/* ------------------------ */

.descriptionDiv {
  margin-top: 15px; /* Adds space above the description section */
  width: 100%; /* Adjusts width to fit the parent container */
}

.edit_border_class_2nine_font_class {
  width: 100%; /* Sets the width of the box */
  height: 270px; /* Sets the height of the box */
  position: relative; /* Allows positioning within the containing block */
  border-radius: 2px 0 0 0; /* Rounded corners on the top-left */
  border: 0.4px solid #4779f0; /* Thin solid border with blue color */
  /* background-color: #FFFFFF; */
  overflow-y: auto; /* Enables vertical scrolling if content overflows */
  padding: 10px; /* Adds space inside the box */
}

.desc_class {
  font-size: 14px; /* Sets the font size for the content */
  color: #333; /* Sets the text color */
  line-height: 1.6; /* Improves readability with line spacing */
}
/* ------------------------------ */

.ButtonPublish {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  bottom: 0%;
  position: absolute;
  right: 0%;
  background-color: #e1eaff;
  width: 97%;
  border-radius: 3%;
  bottom: 15px;
}
.publish_button_1 {
  font-weight: 500;
  border: 0px solid rgb(0, 0, 0);
  color: white;
  border-radius: 2px;
  margin-left: 8px;
  background-color: #1e5fd7 !important;
  font-size: 10px;
  width: 118px;
  height: 28px;
  border-radius: 3px;
}
/* {
    height: 170px;
    width: 100%;
    object-fit: contain;
} */
/* --------------- */

.preview_font_category {
  /* margin-top: 10px; */
  font-weight: 600;
  font-size: 14px;
  color: #0b0c0c;
  display: inline-flex;
}
.preview_font_title {
  font-weight: 600;
  font-size: 10px;
  color: #4aa081;
  display: inline-flex;
  width: 100%;
  padding-bottom: 10px;
}

/*

.edit_border_class {
	border-radius: 3px;
	background: #ffffff;
	box-shadow: 0 0 1px rgba(0, 0, 0, .125), 0 1px 3px rgba(0, 0, 0, .2);
	overflow: auto;
	padding: 5px;
} */

.edit_left_padding {
  /* padding-left: 10px; */
}

.edit_container {
  display: none;
  position: fixed;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.edit_container_inner {
  padding: 13px;
  background: rgb(245, 245, 245);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 6px 6px;
  position: absolute;
  bottom: 0px;
  top: 0px;
  right: 0px;
  width: 400px;
  height: 100%;
}

.all_edit_inputs {
  color: black;
  width: 100% !important;
  height: 30px;
  /* border: 1px solid rgb(196, 196, 196); */
  border: 1px solid #ced4da;
  box-sizing: border-box;
  font-size: 12px;
  padding: 5px;
  border-radius: 5px !important;
}

.edit_inputs_class {
  color: black;
  width: 100% !important;
  height: 30px;
  box-sizing: border-box;
  font-size: 9px;
  font-weight: 500;
  padding: 5px;
  border: 0;
  outline: none !important;
  appearance: none;
}

.edit_inputs_class2 {
	font-size: 12px !important;
	font-weight: 500 !important;
	padding: 5px 12px;
	border: 1px solid #ccc;
	border-radius: 5px;
	width: 100%;
	height: 30px;
	appearance: none;
	background-color: white;
	cursor: pointer;
  color:#1F3977;
  }
  .edit_inputs_classjob {
    color: black;
    width: 100% !important;
    height: 30px;
    box-sizing: border-box;
    font-size: 9px;
    font-weight: 500;
    padding: 2px;
    border: 0;
    outline: none !important;
    appearance: none;
  }

  .note-editor .note-editable {
    width: 100%;
    height: 170px !important; /* Force the height */
    overflow: auto;          /* Add scrollbars if content exceeds */
  }

.search_box_div {
  height: 27px;
  background: white;
  border: 1px solid lightgrey;
  display: flex;
  width: 100%;
  align-items: center;
  padding-left: 8px;
  border-radius: 20px;
}

.search_box_img {
  width: 15px;
  height: 15px;
}

.search_box {
  border: none !important;
  background: white;
  height: 23px !important;
  width: 100% !important;
  font-weight: 500;
  font-size: 12px;
  border-radius: 20px !important;
}

.search_box_padding {
  padding: 7px 0px !important;
  margin-bottom: 10px !important;
}

.create_button {
  border: 0px solid rgb(0, 0, 0);
  padding: 6px 10px;
  width: auto;
  align-items: center;
  justify-content: center;
  background: rgb(0, 0, 0);
  flex-wrap: wrap;
  border-radius: 3px;
  height: auto;
  margin: 0px;
  /* margin-right: 30px; */
  color: #ffffff;
}

.create_button_inner {
  font-size: 10px;
  font-weight: 500;
  font-family: Poppins;
}

.create_button_img {
  width: 15px;
  height: 15px;
  margin-left: 15px;
}

/* Comment section css */
.comment_section {
  display: none;
  max-height: 400px;
  overflow: auto;
  padding-bottom: 29px;
  transition: all 3s ease-in-out;
}

.max_content_class {
  width: max-content;
}

#comment_section {
  display: none;
  max-height: 400px;
  overflow: auto;
  padding-bottom: 10px;
  transition: all 3s ease-in-out;
}

.comment_section_transition {
  transition: all 1s ease-in-out;
}

#comment_section::-webkit-scrollbar {
  width: 4px;
}

#comment_section::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 5px;
}

#comment_section::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

.jdaLQQ {
  overflow: auto !important;
}

.gCaYkd {
  overflow: auto !important;
}

.jdaLQQ::-webkit-scrollbar {
  width: 3px;
}

.jdaLQQ::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 5px;
}

.jdaLQQ::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

.gCaYkd::-webkit-scrollbar {
  width: 3px;
}

.gCaYkd::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 5px;
}

.gCaYkd::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

.heart_image {
  width: 17px;
  height: 17x;
  background: rgb(150, 176, 231);
  padding: 4px;
  border-radius: 50%;
}

.comment_font {
  font-size: 11px;
  /* font-weight: 500; */
  overflow: auto;
}

.comment_display {
  display: none;
  height: auto;
  overflow: auto;
}

.main_comment {
  border-radius: 30px !important;
  border: 1px solid #d9d9d9;
  width: 100%;
  padding: 0px 5px;
  transition: all 0.5s ease-in-out;
}

.main_comment_reply {
  width: 100%;
  bottom: 47px;
  cursor: auto;
}

.delete_img {
  height: 15px;
  width: 15px;
  cursor: pointer;
}

.preview_button {
  margin: 0px 13px 0px 18px;
  border: 0.5px solid rgb(0, 0, 0);
  border-radius: 2px;
  width: 118px;
  align-items: center;
  display: flex;
  padding: 0px 10px;
  height: 28px;
}

.preview_font {
  font-weight: 500;
  font-size: 10px;
  color: #525252;
  display: inline-flex;
}

.preview_img_div {
  display: flex;
  width: 100%;
  justify-content: end;
}

.preview_img {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.buttons_div {
  align-items: center;
  justify-content: end;
  padding-right: 30px !important;
}
.buttons_div2 {
  align-items: center;
  justify-content: end;
  /* padding-right: 30px !important; */
}

@media screen and (min-width: 250px) and (max-width: 1280px) {
  .form-buttons1 {
    border-radius: 5px !important;
    /* background-color: rgb(41, 48, 67) !important; */
    padding: 10px 10px !important;
    font-size: 12px !important;
    font-weight: bold !important;
  }

  .form-buttons2 {
    font-weight: bold !important;
    border-radius: 5px !important;
    color: rgb(31, 57, 119) !important;
    margin-left: auto !important;
    /* background-color: white !important; */
    padding: 10px 10px !important;
    font-size: 12px !important;
    border: 1px solid rgb(31, 57, 119) !important;
  }

  .form-buttons3 {
    font-weight: bold !important;
    border-radius: 5px !important;
    margin-left: 5px !important;
    /* background-color: rgb(31, 57, 119) !important; */
    padding: 10px 4px !important;
    font-size: 12px !important;
  }

  /*
   .table-cards{
	margin-bottom: 12px
   }*/
  .table-cards .row .col-md-3 {
    margin-bottom: 12px !important;
  }

  .table-content {
    width: 100% !important;
    min-width: 474px;
    overflow-x: hidden !important;
  }

  .student-header {
    width: 100%;
    margin-left: auto;
    color: black;
    font-weight: bold;
  }

  .stud_button {
    width: 123px !important;
    margin-left: -14px;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    padding-left: 10px !important;
  }

  .plus-sign {
    font-size: 22px !important;
    /* margin-left: 256px !important; */
    margin-top: -24px !important;
    margin-bottom: -20px !important;
  }

  .expire-date-input {
    width: 84% !important;
  }

  .user-type {
    width: 100% !important;
  }

  .image-logo {
    width: 101px !important;
    margin-left: 0px;
  }

  .img-text {
    margin-left: -30px !important;
  }

  .img-button {
    margin-left: -28px !important;
    margin-top: 4px !important;
  }

  .add-img {
    margin-left: 0px;
  }

  .input-field {
    width: 100% !important;
    margin-left: 1px;
  }

  .Item-label {
    margin-left: 4px;
  }

  .input-field1 {
    width: 96% !important;
    margin-left: 1px;
  }

  .email-field {
    width: 91% !important;
  }

  .button-label {
    font-size: 10px !important;
    margin-left: 5px !important;
  }

  .subject-button {
    width: 123px !important;
    padding: 14px 0px !important;
  }

  .subject-label {
    margin-top: -18px !important;
    margin-left: 9px !important;
    width: 114px;
  }

  .subject-plus-button {
    margin-left: 5px;
    margin-top: 4px !important;
    margin-bottom: 2px;
  }

  .subject-header {
    margin-left: -12px !important;
  }

  .class-header {
    margin-left: -25px !important;
  }

  .class-button {
    width: 125px !important;
    padding-left: 19px !important;
  }

  .class-plus-button {
    margin-left: -11px;
  }

  .department-button {
    width: 175px;
    margin-left: -11px !important;
  }

  .department-plus-sign {
    font-size: 10px;
    margin-left: -2px;
  }

  .department-heading {
    font-size: 12px;
  }

  .department-header {
    margin-left: -56px !important;
  }

  .department-user-image {
    margin-left: 9px !important;
  }

  .department-user-title {
    margin-left: -51px !important;
    margin-top: 50px !important;
  }

  .appointment-header {
    margin-left: -57px;
  }

  .appointment-button {
    width: 172px !important;
    margin-left: -13px !important;
  }

  .appointment-plus-sign {
    margin-left: -11px;
  }

  .marketplace-header {
    margin-left: -56px;
  }

  .marketplace-button {
    margin-left: -11px;
    width: 96px;
  }

  .marketplace-plus-button {
    margin-left: -14px;
  }

  .job-button {
    width: 127px;
  }

  .job-header {
    margin-left: -58px !important;
  }

  .faq-header {
    margin-left: -25px;
  }

  .faq-button {
    width: 129px;
  }

  .grouptable-header {
    margin-left: -54px;
  }

  .group_table_button {
    width: 146px;
  }

  .news-button {
    width: 136px !important;
  }

  .newsform-radio {
    margin-top: 10px !important;
    margin-left: -19px !important;
  }

  .newsform-radio1 {
    margin-left: -11px !important;
  }

  .badge-button {
    margin-left: -91px !important;
    width: 144px !important;
    margin-top: 57px !important;
  }

  .badge-input {
    width: 90% !important;
  }

  .badge-plus-icon {
    margin-left: -16px !important;
  }

  .badge-image {
    margin-left: 95px !important;
  }

  .badge-button {
    margin-left: 86px !important;
    margin-top: 7px !important;
    width: 104px !important;
  }

  .polls-header {
    margin-left: -44px;
  }

  .polls-button {
    width: 135px;
  }

  .grp-label {
    margin-top: 41px !important;
    width: 100% !important;
    margin-left: -297px !important;
  }
}

@media screen and (max-width: 560px) {
  .job-header {
    margin-left: 14px !important;
  }
}

@media screen and (min-width: 500px) and (max-width: 1280px) {
  .dept-dropdown {
    width: 95% !important;
  }
}

@media screen and (max-width: 310px) {
  .img-button {
    margin-left: -28px;
    margin-top: 4px !important;
    height: 21px;
    font-size: 8px !important;
    padding-top: 5px !important;
  }
}

@media screen and (min-width: 250px) and (max-width: 499px) {
  .dept-dropdown {
    width: 89% !important;
  }
}

@media screen and (min-width: 750px) and (max-width: 1290px) {
  .set-radio {
    display: flex;
    flex: 100%;
  }

  .radio-section {
    margin-left: 200px;
    margin-top: -36px;
  }

  .department-user-title {
    margin-left: -51px !important;
    margin-top: 50px !important;
  }

  .appointment-header {
    margin-left: 0px !important;
  }

  .marketplace-header {
    margin-left: -2px !important;
  }

  .job-header {
    margin-left: -6px !important;
  }

  .faq-header {
    margin-right: -38px;
  }

  .newsform-label {
    margin-top: 61px;
  }

  .newsform-radio1 {
    margin-left: 152px !important;
    margin-top: -53px;
  }

  .radio-newsform {
    margin-left: -4px !important;
    margin-top: 26px !important;
  }

  .badge-button {
    margin-left: 7px !important;
    margin-top: 0px !important;
  }

  .badge-image {
    margin-left: 54px !important;
  }

  .badge-button {
    margin-left: 57px !important;
    margin-top: 11px !important;
  }
}

@media screen and (max-width: 780px) {
  .radio-label-newsform {
    margin-left: 16px;
    margin-top: -22px;
  }
}

@media screen and (min-width: 750px) and (max-width: 1280px) {
  .department-header {
    margin-left: -2px !important;
  }
}

@media screen and (max-width: 560px) {
  .department-header {
    margin-left: 54px !important;
  }

  .badge-button {
    margin-left: 85px !important;
  }
}

@media screen and (max-width: 420px) {
  .department-header {
    margin-left: -57px !important;
  }
}

@media screen and (min-width: 520px) and (max-width: 1290px) {
  .subject-header {
    width: auto !important;
  }

  .class-header {
    margin-left: -1px !important;
  }
}

@media only screen and (max-width: 310px) {
  .student-header {
    margin-left: -60px !important;
    font-size: 14px !important;
  }

  .search-box {
    margin-left: 8px !important;
  }

  .stud_button {
    margin-left: -13px !important;
    width: 112px !important;
  }

  .button-label {
    font-size: 10px !important;
    margin-left: 5px !important;
  }

  .stud_button1 {
    margin-left: -13px !important;
    width: 103px !important;
  }

  .plus-symbol {
    font-size: 8px !important;
  }

  .subject-label {
    margin-top: -11px !important;
    font-size: 10px !important;
    font-weight: 400 !important;
    font-family: Poppins !important;
  }

  .subject-button {
    width: 120px !important;
    margin-left: -19px !important;
  }

  .subject-plus-button {
    margin-top: 1px !important;
    font-size: 11px !important;
    margin-left: 13px;
  }

  .subject-header {
    margin-left: -67px !important;
  }

  .class-header {
    margin-left: -62px !important;
  }

  .class-button {
    width: 92px !important;
    margin-left: -11px !important;
    margin-top: 8px !important;
    padding: 10px !important;
  }

  .plus-button-small {
    margin-top: -6px !important;
    font-size: 13px !important;
    margin-left: -8px;
    margin-bottom: -1px;
  }

  .class-label {
    margin-top: -11px !important;
    font-size: 10px !important;
    font-weight: 400 !important;
    margin-left: 7px !important;
    font-family: Poppins !important;
  }

  .department-header {
    margin-left: -62px !important;
  }

  .department-button {
    width: 158px !important;
    margin-left: -14px !important;
    margin-top: 3px !important;
    padding: 10px !important;
  }

  .appointment-header {
    margin-left: -56px;
  }

  .appointment-button {
    width: 170px;
    margin-left: -16px !important;
  }

  .appointment-plus-sign {
    margin-left: -13px;
  }

  .marketplace-header {
    margin-left: -61px;
  }

  .marketplace-button {
    margin-left: -13px !important;
    width: 96px;
  }

  .marketplace-plus-button {
    margin-left: -14px;
  }

  .job-header {
    margin-left: -58px !important;
  }

  .job-button {
    width: 114px;
    margin-left: -13px;
  }

  .job-plus-button {
    margin-left: -12px;
  }

  .faq-header {
    margin-left: -48px !important;
  }

  .faq-button {
    width: 115px;
    margin-left: -18px !important;
  }

  .faq-plus-button {
    margin-left: -14px;
  }

  .faq_tabs {
    width: 90% !important;
  }

  .tablist {
    height: 54vh !important;
  }

  .tab-headings {
    margin-left: 23px !important;
    margin-top: 35px !important;
  }

  .faq-searchbox {
    width: 188px !important;
    padding: 3px !important;
    background-color: #f5f5f5 !important;
    height: 35px !important;
  }

  .faq-inputBox {
    background-color: #f5f5f5 !important;
    height: 30px !important;
    width: 91% !important;
    margin-left: 2px !important;
  }

  .faq-searchicon {
    margin-left: -24px;
  }

  .grouptable-header {
    margin-left: -57px !important;
  }

  .group_table_button {
    width: 127px;
  }

  .group_table_plus_icon {
    margin-left: -14px;
  }

  .news-button {
    margin-left: -13px !important;
    width: 140px !important;
  }

  .newsform-radio {
    margin-top: 13px !important;
    margin-left: -21px !important;
  }

  .newsform-radio1 {
    margin-left: -10px !important;
  }

  .badge-button {
    width: 143px;
  }

  .polls-header {
    margin-left: -53px !important;
  }

  .polls-button {
    width: 120px !important;
    margin-left: -14px !important;
  }

  .polls-plus-icon {
    margin-left: -10px !important;
  }

  .form-check-input {
    margin-left: 19px !important;
  }

  .polls-user-name {
    margin-left: -20px !important;
  }

  .polls-icon {
    margin-left: 5px;
  }

  .grp-button {
    margin-bottom: 5px;
    margin-left: 19px !important;
    width: 98px;
  }

  .grp-align {
    margin-left: 25px;
  }

  .group-input {
    width: 90% !important;
  }

  .grp-label {
    margin-top: 41px !important;
    width: 100% !important;
    margin-left: -229px !important;
  }
}

/* media query for responsiveness (pallavi) end */

.tab-headings {
  display: none;
}

/* calendar tabs componenet */
.e-cell-popup {
  display: none !important;
}

/* date12/04/2022 ATSI*/
.cAKknD {
  background-color: white !important;
  width: 100% !important;
  padding: 0px !important;
  overflow-y: hidden !important;
}

.jPpcqH {
  width: 100% !important;
  overflow-y: auto !important;
  min-height: 382px;
}

.dash_height_class {
  height: 125px !important;
}

.jPpcqH::-webkit-scrollbar {
  width: 3px;
}

.jPpcqH::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.jPpcqH::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background-color: grey;
}

.fncldf {
  width: 100% !important;
  overflow-y: auto !important;
  /* height: 250px !important */
}

.fncldf::-webkit-scrollbar {
  width: 3px;
}

.fncldf::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.fncldf::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background-color: grey;
}

.btn-secondary:focus,
.btn-secondary.focus {
  box-shadow: none !important;
  border: none !important;
}

.gINrfE {
  position: relative !important;
  width: 100% !important;
  border-radius: inherit !important;
  overflow: auto !important;
  min-height: 0px !important;
}

.cFafZF {
  background-color: transparent !important;
}

.jjsEJi {
  padding-right: 40px !important;
}

.dHsUur {
  height: 100% !important;
}

.iEKndm {
  margin-top: -4px;
}

.recipient_class .cAKknD {
  background-color: white !important;
  overflow: auto !important;
}

.lfJGeo {
  background-color: var(--color) !important;
  padding: 0 !important;
  min-height: 0 !important;
}

.recipient_class .lfJGeo {
  background-color: white !important;
}

.recipient_class header {
  display: none !important;
}

.jhobdk {
  margin-top: 0px;
  width: 100% !important;
  display: flex !important;
  /* padding:10px 10px 10px 15px!important; */
}

.gEuYxO {
  font-weight: bold;
  border-bottom-width: none !important;
  border-bottom-color: none !important;
  border-bottom-style: none !important;
  min-height: 36px !important;
}

.eQzNb {
  margin: 0 !important;
  width: 100% !important;
  border-top-width: none !important;
  border-top-color: none !important;
  border-top-style: none !important;
  border-radius: 10px !important;
}

.dKNajo:not(:last-of-type) {
  border-bottom-width: none !important;
  border-bottom-color: none !important;
  border-bottom-style: none !important;
  background-color: #f5f5f4 !important;
}

.dKNajo {
  background-color: #f5f5f4 !important;
}

.gJJMdd:not(:last-of-type) {
  border-bottom-width: none !important;
  border-bottom-color: none !important;
  border-bottom-style: none !important;
}

/* calendar icons */
::-webkit-search-cancel-button,
::-webkit-clear-button {
  -webkit-appearance: none;
  /* background-image: url('data:image/svg+xml;charset=utf8,%3Csvg fill="%23000" fill-opacity=".54" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z"/%3E%3Cpath d="M0 0h24v24H0z" fill="none"/%3E%3C/svg%3E'); */
  background-image: url(../Images/calendar_plus.png);
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
  height: 1.5rem;
  margin-right: 0;
  width: 1.5rem;
}

::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  /* background-image: url('data:image/svg+xml;charset=utf8,%3Csvg fill="%23000" fill-opacity=".54" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z"/%3E%3Cpath d="M0 0h24v24H0z" fill="none"/%3E%3C/svg%3E'); */
  background-image: url(../Images/calendar_plus.png);
  width: 15px;
  height: 15px;
  cursor: pointer;
  /* border-radius: 50%; */
  margin-left: 0.5rem;
}

::-webkit-calendar-picker-indicator:hover {
  /* -webkit-box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.04); */
  /* box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.04); */
}

#exTab2 h3 {
  color: black;
  background-color: white !important;
}

.tab-content {
  /* border: 1px solid #edebeb; */
  box-sizing: border-box;
  border-top: none;
  background-color: white !important;
  padding-bottom: 10px !important;
}

h3 {
  margin: 0;
}

#mainNav li a:active {
  background-color: red !important;
}

.nav-tabs-ticket > li.active > a,
.nav-tabs-ticket > li.active > a:focus,
.nav-tabs-ticket > li.active > a:hover {
  background-color: #2d5dd0 !important;
  font-weight: 500 !important;
  color: white !important;
  border-radius: 5px 5px 0px 0px !important;
  font-size: 11px;
}

.nav-tabs-ticket li {
  background-color: #c4c4c4;
  color: white;
  font-weight: 500 !important;
  font-size: 11px;
}

.nav-tabs-ticket li a {
  padding: 5px 15px;
  color: white;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover {
  color: #2d5dd0 !important;
  font-weight: 600 !important;
  background-color: white !important;
  border: none;
  border-radius: 5px 5px 0px 0px !important;
  border-bottom: 3px solid #339dd8;
}

.nav-tabs > li > a {
  /* background-color: #c4c4c4 !important; */
  color: black !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  margin-bottom: 0 !important;
  border-radius: 5px 5px 0px 0px !important;
  padding: 6px 30px !important;
  /* background: #fff !important; */
  /* border: 1px solid #e5e5e5; */
}

.nav_tabs > li.active > a,
.nav_tabs > li.active > a:focus,
.nav_tabs > li.active {
  color: white !important;
  font-weight: 600 !important;
  background-color: #2d5dd0 !important;
  border-radius: 5px 5px 0px 0px !important;
  border-bottom-color: transparent !important;
}

.nav_tabs > li > a {
  background-color: #6e7781 !important;
  color: white !important;
  font-size: 10px !important;
  font-weight: 600 !important;
  border-radius: 5px 5px 0px 0px !important;
  padding: 10px 40px !important;
}

.nav_tabs {
  margin-top: 10px !important;
  border-bottom: 0.5px solid #edebeb !important;
}

#exTab3 h3 {
  color: black;
  background-color: white !important;
}

.recipient_class .dKNajo:not(:last-of-type) {
  border-bottom-style: solid;
  border-bottom-width: 1px !important;
  border-bottom-color: red !important;
}

.mt-3 {
  margin-top: 8px !important;
}

/* calendar design */
.CalendarDay__highlighted_calendar:not(.CalendarDay__selected) {
  background-color: #f7f8f6 !important;
}

.CalendarMonth_table .td {
  width: 30px !important;
  height: 30px !important;
}

.CalendarDay__outside {
  color: rgb(196, 193, 193) !important;
  font-weight: 400 !important;
  align-items: center !important;
  text-align: center !important;
  font-size: 12px !important;
}

.DayPickerNavigation_button__default {
  border: none !important;
}

.DayPickerNavigation_svg__horizontal {
  display: none;
}

.CalendarMonth_caption strong {
  display: none !important;
}

.CalendarMonth_table {
  margin-top: -42px !important;
  margin-left: 0px !important;
  margin-right: -30px !important;
  border-collapse: separate !important;
  border-spacing: 20px 2px !important;
}

.sc-ZyCDH bfHjlM {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
}

.sc-jOhDuK bEUjfd {
  top: 6px !important;
  left: 9px !important;
}

.DayPicker_transitionContainer {
  height: 240px !important;
}

.DayPicker_weekHeader {
  position: absolute !important;
  top: -5px !important;
  padding-left: 7px !important;
  width: 100% !important;
}

.DayPicker_weekHeaders__horizontal {
  margin-left: 28px !important;
  width: auto !important;
  /* / border: 1px solid darkgrey !important; / */
}

.DayPicker_weekHeader_li {
  width: 45px !important;

  text-align: center;
  align-items: center !important;
  justify-content: center !important;
  margin: 0 !important;
}

.DayPicker_weekHeader_ul {
  padding: 0 !important;
  align-items: center !important;
  justify-content: center !important;
  display: flex;
  gap: 5px;
}

.DayPicker_weekHeader_li small {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  text-transform: uppercase;
  color: #6e7781;
}

.DayPicker__withBorder {
  width: 100% !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  box-shadow: none !important;
  /* height: 260px;  */
  padding-left: 50px;
  border-radius: 3px;
}

.DayPicker__withBorder div {
  width: auto !important;
}

.CalendarDay__default {
  width: 30px !important;
  height: 30px !important;
  border: none !important;
}

.CalendarDay__weekend {
  color: red !important;
}

.sc-gKclnd div {
  /* / border: 1px solid black !important; / */
  width: 100% !important;
}

.CalendarMonthGrid {
  height: 100px !important;
}

.DayPickerNavigation_button__horizontalDefault {
  /* display: none !important; */
}

.sc-jIAOiI XvFYe {
  padding-left: 50px !important;
}

.eCUcCv:hover {
  color: white !important;
}

/* .CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover
  {
	  background-color: red !important;
	  border:1px solid red !important;

  } */
.day.selected {
  background: red;
  border-radius: 3px;
  color: white;
  font-weight: bold;
}

.datewise_events::-webkit-scrollbar {
  width: 10px;
}

.datewise_events::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.datewise_events::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background-color: grey;
}

/* .DayPickerNavigation_leftButton__horizontalDefault
{
  left:22px !important;
} */
.DayPickerNavigation_button__horizontalDefault {
  position: absolute;
  top: 18px !important;
  line-height: 0.78 !important;
  border-radius: 3px !important;
  padding: 6px 9px !important;
  display: none;
}

.DayPickerNavigation_button__default {
  border: 1px solid #e4e7e7 !important;
  background-color: #fff !important;
  color: #757575 !important;
  display: none;
}

.DayPickerNavigation_svg__horizontal {
  height: 19px !important;
  width: 19px !important;
  fill: #82888a !important;
  display: none !important;
}

/* text slider */
.event-header {
  text-align: center;
  padding: 0 !important;
}

.event-header h1 {
  font-size: 14px !important;
  font-weight: 500 !important;
  /* text-transform: uppercase; */
  color: white !important;
}

.event-item {
  background-color: #fff;
  /* border: 1px solid #ccc; */
  padding: 1em;
  border-radius: 2px;
  padding-left: 0px !important;
  /* box-shadow: 0px 2px 2px 0 rgba(0,0,0,0.14); */
  margin-bottom: 1em;
  margin-top: 10px !important;
  margin-left: -11px !important;
}

.event-desc {
  text-align: center;
}

.event-desc p {
  font-size: 14px !important;
}

.event-date {
  text-align: center;
  border-right: none;
  /* margin-bottom: 1em; */
}

.event-date h1 {
  font-size: 12px !important;
  margin: 0 auto;
  font-weight: 700;
  text-align: center;
}

.event-date span {
  font-size: 12px !important;
  font-weight: 600;
  margin-left: 10px !important;
}

.carousel-control-next {
  top: 0 !important;
  right: 0 !important;
  width: 20px;
  height: 20px;
  background: url("./images/right_arrow.png");
}

.carousel-control-prev {
  top: 0 !important;
  left: 0 !important;
  background: url("./images/left_arrow.png");
  width: 20px;
  height: 20px;
  color: black !important;
}

.next_holidayList {
  top: 17px;
  right: 100px;
  width: 20px;
  height: 20px;
  background: url("./images/right_arrow.png");
}

.prev_holidayList {
  top: 17px;
  left: 100px;
  background: url("./images/left_arrow.png");
  width: 20px;
  height: 20px;
  color: black !important;
}

@media (min-width: 768px) {
  .event-date h1 {
    font-size: 12px !important;
  }

  .event-desc {
    text-align: left;
  }

  .event-header h1 {
    font-size: 5em;
    margin-top: -2px;
  }
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 12px;
  height: 12px;
}

.icon_holidayList {
  width: 12px;
  height: 12px;
}

.gcJheL {
  padding: 0 !important;
  font-weight: 600 !important;
}

.iEWLZy:not(:last-of-type) {
  border-bottom-style: none !important;
}

.fCOjbv:not(:last-of-type) {
  border-bottom-style: none !important;
}

/*  appearance for checked radiobutton */
input[type="radio"] {
  display: none;
}

input[type="radio"]:checked + label:before {
  background-color: #15a312;
  z-index: 2;
}

input[type="radio"] + label:before {
  content: "";
  /* create custom radiobutton appearance */
  display: inline-block;
  width: 20px;
  height: 20px;
  padding: 5px;
  margin-right: 3px;
  /* background-color only for content */
  background-clip: content-box;
  border: 1px solid #bbbbbb;
  background-color: transparent;
  border-radius: 50%;
}

input[type="checkbox"]:checked + label:before {
  background-color: #4779f0;
  z-index: 2;
  border-radius: 3px;
  border: 1px solid #4779f0;
}

input[type="checkbox"] + label:before {
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  padding: 4px;
  margin-right: 3px;
  background-clip: content-box;
  border: 1px solid #bbbbbb;
  background-color: transparent;
  border-radius: 3px;
}

input[type="radio"] + label:hover:before {
  border: 1px solid blue;
}

/* input date */
#publish_date {
  color: #aaa;
}

.date-container {
  position: relative;
  float: left;
}

.date-container .date-text {
  position: absolute;
  top: 6px;
  left: 12px;
  color: #aaa;
}

.date-container .date-icon,
.start-date-icon,
.end-date-icon,
.publish-date-icon,
.expire-date-icon,
.exam-date-icon,
.dob-date-icon,
.dob-edit-date-icon {
  position: absolute;
  top: 6px;
  right: 10px;
  /* pointer-events: none; */
  cursor: pointer;
  color: #aaa;
}

input[type="date"]:not(:valid)::-webkit-datetime-edit {
  color: #9e9e9e;
  font-size: 10px;
  font-weight: 500;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.all_inputs:disabled {
  background-color: #f0f0f0;
  color: #808080;
}
/* input[type="datetime-local"]::-webkit-calendar-picker-indicator {
	color: rgba(0, 0, 0, 0);
	opacity: 1;
	display: block;
	background: url("../Images/calendar_plus.png");
	width: 10px;
	height: 10px;
	border-width: thin;
       } */

/* community tabs */
.nav-tabs-community > li.active > a,
.nav-tabs-community > li.active > a:focus,
.nav-tabs-community > li.active > a:hover {
  color: white !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  background-color: #2d5dd0 !important;
  border: none !important;
  /* border: 2px solid red; */
}

.nav-tabs-community > li > a {
  background-color: rgba(196, 196, 196, 0.5) !important;
  color: black !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  border-radius: 0px !important;
  margin-right: 3px;
  border: 2px solid red;
}

.ezHxBa:not(:last-of-type) {
  border-bottom-style: none !important;
}

.ezHxBa {
  background-color: #f5f5f5 !important;
}

.hHEVfD:not(:last-of-type) {
  border-bottom-style: none !important;
}

.hHEVfD {
  background-color: white !important;
}

.adminLogin {
  background-image: url("../Images/login_image.png") no-repeat center center
    fixed;

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.radio-custom:checked ~ label {
  color: black !important;
  font-weight: bold !important;
}

.login_plceholder::placeholder {
  color: black !important;
  margin: 5px 0px 0px 5px !important;
  padding-left: 2px !important;
  font-size: 9px !important;

  font-family: Poppins;
}

.wrapper {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1) !important;
}

.university-dropdown option {
  color: black !important;
  font-size: 14px;
}

.epcPgk:not(:last-of-type) {
  border-bottom-style: none !important;
}

.epcPgk {
  background: #f5f5f5 !important;
}

.gjSesN:not(:last-of-type) {
  border-bottom-style: none !important;
}

.YdOKC {
  border-bottom-style: none !important;
}

.sidebar-mini .main-sidebar .nav-link,
.sidebar-mini-md .main-sidebar .nav-link,
.sidebar-mini-xs .main-sidebar .nav-link {
  width: 100% !important;
}

.iECcc {
  display: none !important;
}

.gKDESC:not(:last-of-type) {
  border-bottom: none !important;
}

.jMfglv:not(:last-of-type) {
  border-bottom: none !important;
}

.kkPGaV {
  border-top: none !important;
}

.iXgrnO {
  border-bottom: none !important;
}

.sidebar-dark-primary .nav-sidebar .nav-item .nav-link.active {
  background-color: red !important;
  color: green !important;
}

.nav-pills > li.active > a {
  background-color: transparent !important;
}

.gpWdeU {
  padding: 0 30px !important;
}

.ejBOVB {
  padding: 0 30px !important;
}

.kJfnHy,
.jsIBcP {
  min-height: 30px !important;
  background-color: #f5f5f5 !important;
  font-size: 11px !important;
}

.LAHwp {
  font-size: 11px !important;
}

.delete_container {
  width: 100%;
  position: absolute;
  top: 0;
  display: none;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
}

.delete_container_heading {
  font-weight: 600;
  color: black;
  font-size: 13px;
}

.delete_container_p {
  margin-top: 10px;
  font-size: 10px;
  font-weight: 500;
  width: 75%;
}

.delete_container_cancel_button {
  border-radius: 5px;
  background-color: transparent;
  font-size: 10px;
  padding: 8px 12px;
  font-weight: 500;
  color: rgb(0, 0, 0);
}

.delete_container_delete_button {
  border-radius: 5px;
  margin-right: 7px;
  background-color: rgb(210, 31, 60);
  font-size: 11px;
  font-weight: 500;
  padding: 2px 16px;
}

.delete_btn {
  display: flex;
  align-items: center;
  color: #fff;
  border-radius: 3px;
  margin-right: 7px;
  background-color: rgb(210, 31, 60);
  font-size: 11px;
  font-weight: 500;
  height: 23px;
  border: none;
  padding: 0px 10px;
}

.delete_cancel_btn {
  font-size: 10px;
  font-weight: 500;
  color: black;
  height: 24px;
  border: none;
  background: none;
}

/* / Loading Spinner start / */
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3;
  border-top: 10px solid #696969;
  border-radius: 50%;
  margin-left: 100px;
  animation: spinner 1.5s linear infinite;
}

.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 350px;
}

/* / Loading Spinner End  */

/* timetable schedule */

/* FONT IMPORTS */

.icon {
  font-family: "Material Icons", serif;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
}

/* VARIABLES */

/* :root {
	--main-color: #1a8fff;
	--text-color: #777;
	--text-color-light: #ccc;
	--border-color: #eee;
	--bg-color: #f9f9f9;
	--neutral-color: #fff;
  } */

/* GENERAL */
/*
  * {
	box-sizing: border-box;
  }
   */
/* body {
	font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
	font-size: 1em;
	font-weight: 300;
	line-height: 1.5;
	color: var(--text-color);
	background: var(--bg-color);
	position: relative;
  } */

/* header {
	display: block;
	width: 100%;
	padding: 1.75em 0;
	border-bottom: 1px solid var(--border-color);
	background: var(--neutral-color);
  } */

header #logo {
  font-size: 175%;
  text-align: center;
  color: var(--main-color);
  line-height: 1;
}

header #logo .icon {
  padding-right: 0.25em;
}

main {
  display: block;
  margin: 0 auto;
  margin-top: 5em;
  max-width: 50em;
}

/* GRID */

.row {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.row-middle {
  align-items: center;
}
.row2 {
  margin: 0;
  padding: 0;
  /* display: flex; */
  /* flex-direction: row;
  flex-wrap: wrap; */
  width: 100%;
}


.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.col-start {
  justify-content: flex-start;
  text-align: left;
}

.col-center {
  justify-content: center;
  text-align: center;
}

.col-end {
  justify-content: flex-end;
  text-align: right;
}

/* Calendar */

/* .calendar {
	display: block;
	position: relative;
	width: 100%;
	background: white !important;
	border: 1px solid var(--border-color);
  } */

.calendar .header {
  font-weight: 700;
  background-color: #1f3977;
  color: white;
  font-size: 12px;
  width: fit-content;
  padding: 10px;
  border-radius: 7px;

  /* position: absolute;
	top:-25px;
	right: 0; */
}

.calendar .header .icon {
  cursor: pointer;
  transition: 0.15s ease-out;
  font-size: 12px;
}

.calendar .header .icon:hover {
  transition: 0.25s ease-out;
  color: var(--main-color);
}

.calendar .header .icon:first-of-type {
  /* margin-left: 1em; */
}

.calendar .header .icon:last-of-type {
  /* margin-right: 1em; */
}

.calendar .days {
  text-transform: uppercase;
  font-weight: 400;
  color: var(--text-color-light);
  font-size: 70%;
  padding: 0.75em 0;
  border-bottom: 1px solid var(--border-color);
}

.calendar .body .cell {
  position: relative;
  height: auto;
  border-right: 1px solid var(--border-color);
  overflow: hidden;
  cursor: pointer;
  background: var(--neutral-color);
  transition: 0.25s ease-out;
  font-size: 1.5em;
  padding: 0;
  /* background-color: red; */
}

.calendar .body .cell:hover {
  background: var(--bg-color);
  transition: 0.5s ease-out;
}

.calendar .body .selected {
  border-left: 10px solid transparent;
  border-image: linear-gradient(45deg, #1a8fff 0%, #53cbf1 40%);
  border-image-slice: 1;
}

.calendar .body .today {
  /* border-left: 10px solid transparent;
	border-image: linear-gradient(45deg, #ff1a79 0%, #eb82b3 40%);
	border-image-slice: 1; */
}

.calendar .body .row {
  border-bottom: 1px solid var(--border-color);
}

/* .calendar .body .row:last-child {
	border-bottom: none;
  } */

.calendar .body .cell:last-child {
  border-right: none;
}

.calendar .body .cell .number {
  font-size: 11px;

  /* color: white; */
  font-weight: 500;
}

.calendar .body .disabled {
  color: var(--text-color-light);
  pointer-events: none;
}

.calendar .body .cell .bg {
  font-weight: 700;
  line-height: 1;
  color: var(--main-color);
  opacity: 0;
  font-size: 8em;
  position: absolute;
  top: -0.2em;
  right: -0.05em;
  transition: 0.25s ease-out;
  letter-spacing: -0.07em;
}

.calendar .body .cell:hover .bg,
.calendar .body .selected .bg {
  opacity: 0.05;
  transition: 0.5s ease-in;
}

.calendar .body .cell.today .bg {
  color: #ff1a79;
  opacity: 0.05;
}

.calendar .body .col {
  flex-grow: 0;
  flex-basis: calc(100% / 7);
  width: calc(100% / 7);
}

.empty-state {
  width: 100%;
  /* margin: 40px auto; */
  background: rgb(0, 0, 0, 0.2);
  box-shadow: 1px 2px 10px #e1e3ec;
  border-radius: 4px;
  z-index: 10;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
}

.empty-state__content {
  padding: 10px;
  display: flex;
  width: 350px;
  align-items: center;
  flex-direction: column;
  background-color: white;
  margin: 100px auto;
  border-radius: 10px;
}

.empty-state__content .empty-state__icon {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  border-radius: 200px;
  justify-content: center;
  background-color: #f7fafc;
  box-shadow: 0px 2px 1px #e1e3ec;
}

.empty-state__content .empty-state__icon img {
  width: 170px;
}

.empty-state__content .empty-state__message {
  color: #38a169;
  font-size: 12px;
  font-weight: 600;
  margin-top: 0.85rem;
}

.empty-state__content .empty-state__help {
  color: #a2a5b9;
  font-size: 10px;
  text-align: center;
}

.credit {
  color: #a2a5b9;
  font-size: 0.75rem;
  text-align: center;
}

.credit a {
  color: #444;
}

/* image upload with preview */
.col-ting {
  width: 25em;
  margin: 0 auto;
  margin-top: 3em;
  margin-bottom: 3em;
}

.file-upload .image-box {
  margin: 0 auto;
  margin-top: 1em;
  height: 15em;
  width: 20em;
  background: #d24d57;
  cursor: pointer;
  overflow: hidden;
}

.file-upload .image-box img {
  height: 100%;
  display: none;
}

.file-upload .image-box p {
  position: relative;
  top: 45%;
  color: #fff;
}

.sc-gXmSlM svg {
  height: 15px !important;
  width: 15px !important;
}

.gbnzhD {
  margin: 0px !important;
  font-size: 12px !important;
}

.ixJwiC {
  font-size: 12px !important;
}

.pgLGf svg {
  height: 18px !important;
  width: 18px !important;
}

.fMqkzt {
  margin: 0 5px !important;
  font-size: 12px !important;
}

.cEInQU {
  width: 20px !important;
  height: 20px !important;
  padding: 0 !important;
}

button:disabled,
button[disabled] {
  color: grey !important;
  cursor: no-drop;
}

/* Sidebar dropdown css start */

/* define a fixed width for the entire menu */
.navigation {
  width: 250px;
}

/* reset our lists to remove bullet points and padding */
.mainmenu,
.submenu {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* make ALL links (main and submenu) have padding and background color */
.mainmenu a {
  display: block;
  /* border: 0.5px solid #6E7781; */
  /* background-color: #293043; */
  text-decoration: none;
  /* padding: 10px; */
  color: rgba(255, 255, 255, 0.5);
  /* font-size: 12px; */
  text-align: justify;
  padding: 1px;
  font-size: 10px;
  font-weight: 500;
  /* font-family:; */
  font-style: normal;
  /* border-radius: 1px solid blue ; */
}

.mainmenu li ul li {
  margin-top: 2px;
  border: 0.1px solid #7e7e7e;
  padding: 0 5px;
  border-radius: 3px;
}

.mainmenu li ul li a i {
  margin-right: 12px;
}

/* add hover behaviour */
.mainmenu li ul li:hover {
  /* color: white; */
  /* font-size: 15px; */
  /* font-weight: 600; */
  /* border: 0.5px solid #E1D9D1; */
  border: 0.5px solid #4aa081;
  /* 0.5px solid rgba(110, 119, 129, 0.4) */
}

.mainmenu li ul li.active {
  background-color: #4aa081;
}

.mainmenu li ul li.active a {
  color: white;
  font-weight: 600;
}

/* .mainmenu li ul li.active i{

	} */
/* when hovering over a .mainmenu item,
	display the submenu inside it.
	we're changing the submenu's max-height from 0 to 200px;
  */

.mainmenu li:hover .submenu {
  display: block;
  max-height: 250px;
}

/*
	we now overwrite the background-color for .submenu links only.
	CSS reads down the page, so code at the bottom will overwrite the code at the top.
  */

.submenu a {
  color: #be2625;
  border-radius: 1px blue;
}

/* hover behaviour for links inside .submenu */
.submenu a:hover {
  color: #dc8c7c;
}

/* this is the initial state of all submenus.
	we set it to max-height: 0, and hide the overflowed content.
  */
.submenu {
  overflow: hidden;
  max-height: 0;
  -webkit-transition: all 0.5s ease-out;
}

/* Sidebar Sidemenu css end */

.fc .fc-button-primary:disabled {
  display: none !important;
}

.fc .fc-prev-button {
  background-color: #1f3977 !important;
  border-radius: 7px 0px 0px 7px !important;
  /* / background: url("./images/Forward\ Button.png") no-repeat center !important; / */
  border: none !important;

  height: 25px !important;
}

.fc .fc-next-button {
  background-color: #1f3977 !important;
  border-radius: 0px 7px 7px 0px !important;
  border: none !important;
  height: 25px !important;
}

.fc .fc-new-button {
  font-size: 10px !important;
  text-align: center !important;
  background-color: #1f3977 !important;
  padding: revert !important;
  height: 25px !important;
}

.fc .fc-edit-button {
  background: url("./images/Edit.png") no-repeat center !important;
  width: 18px;
  height: 18px;
  margin-right: 15px !important;
  margin-right: 30px;
  border: none !important;
  background-color: white;
  -webkit-background-size: contain !important;
  -moz-background-size: contain !important;
  -o-background-size: contain !important;
  background-size: contain !important;
}

.fc .fc-print-button {
  background: url("./images/Print.png") no-repeat center !important;
  width: 18px;
  height: 18px;
  border: none !important;
  margin-right: 15px !important;
  background-color: white;
  -webkit-background-size: contain !important;
  -moz-background-size: contain !important;
  -o-background-size: contain !important;
  background-size: contain !important;
}

.fc .fc-excel-button {
  background: url("./images/Excel.png") no-repeat center !important;
  width: 18px;
  height: 18px;
  border: none !important;
  margin-right: 15px !important;
  background-color: white;
  -webkit-background-size: contain !important;
  -moz-background-size: contain !important;
  -o-background-size: contain !important;
  background-size: contain !important;
}

.fc .fc-timetable-button {
  border: none !important;
  font-size: 13px !important;
  background-color: white !important;
  font-weight: 600 !important;
  color: #1f3977 !important;
  font-family: Poppins !important;
}

.fc .fc-button {
  font-size: 10px !important;
  text-align: center !important;
  border: none !important;
  padding: 5px !important;
}

.fc .fc-button .fc-icon {
  vertical-align: baseline !important;
  font-size: 10px !important;
  position: none !important;
  top: 0 !important;
  margin: 0 !important;
}

.fc .fc-timegrid-axis-cushion,
.fc .fc-timegrid-slot-label-cushion {
  display: none !important;
}

.fc-col-header-cell-cushion {
  font-size: 12px !important;
  font-weight: 500 !important;
  margin: 0px 10px !important;
  font-family: Poppins !important;
}

.fc-day-mon .fc-scrollgrid-sync-inner {
  background-color: #6948c5 !important;
  color: white !important;
}

.fc-day-tue .fc-scrollgrid-sync-inner {
  background-color: #c0a200 !important;
  color: white !important;
}

.fc-day-wed .fc-scrollgrid-sync-inner {
  background-color: #d9000d !important;
  color: white !important;
}

.fc-day-thu .fc-scrollgrid-sync-inner {
  background-color: #2d5dd0 !important;
  color: white !important;
}

.fc-day-fri .fc-scrollgrid-sync-inner {
  background-color: #15a312 !important;
  color: white !important;
}

.fc-day-sat .fc-scrollgrid-sync-inner {
  background-color: #de861e !important;
  color: white !important;
}

.fc-day-sun .fc-scrollgrid-sync-inner {
  background-color: #c54884 !important;
  color: white !important;
}

.fc-day-mon {
  margin: 0 5px;
}

.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  min-height: 0 !important;
  margin-top: 0 !important;
}

.fc .fc-timegrid-divider {
  padding: 0 !important;
}

.fc .fc-timegrid-slot-minor {
  border: none !important;
}

.fc-timegrid-divider .fc-cell-shaded {
  /* / border: none !important; / */
}

/* .fc-daygrid-day-frame, .fc-daygrid-day-events, .fc-daygrid-event-harness
{
	height:0 !important;
} */
.fc .fc-scrollgrid-liquid {
  border: none !important;
}

.fc-theme-standard td,
.fc-theme-standard th {
  /* / border: none !important; / */
}

.fc .fc-scroller {
  overflow: hidden !important;
  /* / display: none !important; / */
}

.fc .fc-scrollgrid-section table {
  /* / height:0 !important; / */
  width: 100% !important;
}

.fc-scrollgrid-sync-table tbody tr {
  display: none !important;
}

.fc-scrollgrid-section .fc-scrollgrid-section-body td {
  display: none !important;
}

.fc-timegrid-body {
  width: 100% !important;
}

.fc-theme-standard td,
.fc-theme-standard th {
  border: none !important;
}

.fc-timegrid-event .fc-event-main {
  padding: 0 !important;
}

.calendar .body .selected {
  border-left: 0 !important;
}

#editBox:hover {
  border: 1px solid #63c5da;
}

.cAKknD {
  background-color: white !important;
  width: 100% !important;
  padding: 0px !important;
  overflow-y: hidden !important;
}

.jPpcqH {
  width: 100% !important;
  overflow-y: auto !important;
}

.jPpcqH::-webkit-scrollbar {
  width: 3px;
}

.jPpcqH::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.jPpcqH::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background-color: grey;
}

.btn-secondary:focus,
.btn-secondary.focus {
  box-shadow: none !important;
  border: none !important;
}

.right_part {
  padding: 15px;
  width: 18%;
  margin-top: 50px;
  height: 600px;
  overflow-y: hidden;
}

.right_part::-webkit-scrollbar {
  width: 7px;
}

.right_part::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.right_part::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background-color: grey;
}

.create_form::-webkit-scrollbar {
  width: 7px;
}

.create_form::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.create_form::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background-color: grey;
}

/* .small-box>.inner {
	padding: 20px;
       } */

.bMgaAx div:first-child {
  width: 110px;
}

.flagged_inner {
  padding: 15px !important;
}

/* *************************** For Pagination ************************************************* */

.navigationButtons {
  width: 80%;
  height: 40px;
  list-style: none;
  display: flex;
  justify-content: center;
  margin: auto;
  padding-top: 40px;
}

.navigationButtons a {
  padding: 10px;
  margin: 8px;
  border-radius: 5px;
  border: 1px solid #90ee90;
  color: red;
  cursor: pointer;
}

.navigationButtons a:hover {
  color: white;
  background-color: #90ee90;
}

.navigationActive a {
  color: white;
  background-color: #90ee90;
}

.navigationDisabled a {
  color: white;
  background-color: grey;
}

#more {
  display: none;
}

#exam_name[disabled],
#course_name[disabled],
#class_name[disabled],
#subject_name[disabled],
#publish_date[disabled],
#expiry_date[disabled] {
  background: #e4e9f3;
}

.faq_Content_scrollbar::-webkit-scrollbar {
  width: 5px;
}

.faq_Content_scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.faq_Content_scrollbar::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background-color: grey;
}

.btn {
  border: none;
  outline: none;
  padding: 10px 16px;
  background-color: #f1f1f1;
  cursor: pointer;
  font-size: 18px;
}

.download_template:hover {
  text-decoration: underline;
}

.campus_event::-webkit-scrollbar {
  width: 7px;
}

.campus_event::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.campus_event::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background-color: grey;
}

.teacher_profile::-webkit-scrollbar {
  width: 7px;
}

.teacher_profile::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.teacher_profile::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background-color: grey;
}

text {
  font-size: 10px;
  font-weight: 500;
}

.donutchart-legend-item-label {
  font-size: 16px !important;
}

.donutchart {
  padding: 5px;
}

.FAQ__question {
  font-weight: 400;
  font-size: 10px;
  color: #1f3977;
  border: none;
  padding: 7px;
  background-color: #e4e9f3;
  text-align: left;
  display: flex;
  width: 100%;
}

.FAQ__answer {
  color: black;
  font-size: 10px;
  padding: 10px 122px 10px 10px;
  background: #f5f5f5;
  margin-left: 25px;
  font-weight: 500;
}

dd {
  background: rgb(249 249 249 / 40%);
}

.FAQ__question:hover {
  cursor: pointer;
}

.FAQ__answer--hidden {
  display: none;
}

.BGDyz {
  margin: 0 2px 0 2px !important;
  font-size: 11px !important;
}

.dYnndG {
  font-size: 11px !important;
  padding-left: 8px !important;
  padding-right: 24px !important;
}

.cYoylj svg {
  height: 18px;
  width: 18px;
}

.kPgAoF {
  margin: 0 2px !important;
  font-size: 11px !important;
}

.kkPGaV {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.esgPtq {
  height: 20px !important;
  width: 20px;
  padding: 0 !important;
}

.img-thumb {
  max-height: 75px;
  border: 2px solid none;
  border-radius: 3px;
  padding: 1px;
  cursor: pointer;
}

.img-thumb-wrapper {
  display: inline-block;
  margin: 10px 10px 0 0;
}

.remove {
  display: block;
  background: #444;
  border: 1px solid none;
  color: white;
  text-align: center;
  cursor: pointer;
}

.remove:hover {
  background: white;
  color: black;
}

#password::placeholder {
  color: black !important;
  font-size: 9px !important;
  font-weight: 400;
}

#mobile_new::placeholder {
  color: black !important;
  font-size: 9px !important;
}

#email::placeholder {
  color: black !important;
  font-size: 9px !important;
  font-weight: 400;
}

#blood_type::placeholder {
  color: black !important;
  font-size: 9px !important;
}

#designation_neww::placeholder {
  color: black !important;
  font-size: 9px !important;
  font-weight: 400;
}

#email_neww::placeholder {
  color: black !important;
  font-size: 9px !important;
  font-weight: 400;
}

#mobile_neww::placeholder {
  color: black !important;
  font-size: 9px !important;
  font-weight: 400;
}

#password_neww::placeholder {
  color: black !important;
  font-size: 9px !important;
  font-weight: 400;
}

#last_name::placeholder {
  color: black !important;
  font-size: 9px !important;
  font-weight: 400;
}

#first_name::placeholder {
  color: black !important;
  font-size: 9px !important;
  font-weight: 400;
}

#groupName::placeholder {
  color: black !important;
  font-size: 9px !important;
}

#descriptionName::placeholder {
  color: black !important;
  font-size: 9px !important;
}

#faq_question::placeholder {
  color: black !important;
  font-size: 9px !important;
}

#faq_answer::placeholder {
  color: black !important;
  font-size: 9px !important;
}

input[type="date"]:invalid::-webkit-datetime-edit {
  color: black !important;
  font-size: 9px !important;
}

.css-yk16xz-control {
  border: 1px solid #c4c4c4 !important;
}

.css-1wa3eu0-placeholder {
  color: black !important;
  font-size: 9px !important;
}

.next-referral {
  margin: 0 15px !important;
  width: 38% !important;
}

/* .eqoZa, .hTTxEQ
{
align-items: center  !important;
justify-content: center !important;
} */
/* job tabs */
@import url("https://fonts.googleapis.com/css?family=DM+Sans:400,500|Jost:400,500,600&display=swap");

/* ==== TABS NAV ==== */
.job-tab-link:hover,
li.current {
  /* background: #c4c4c4 !important; */
  background: #2d5dd0 !important;
  color: #fff !important;
}

.job-tab-container {
  border-radius: none !important;
  background-color: white !important;
}

ul.job-tabs {
  list-style: none;
  font-size: 2.75vmin;
  line-height: 1.6;
  display: flex;
}

ul.job-tabs li {
  width: 10% !important;
  color: black;
  text-align: center;
  padding: 3px 5px !important;
  border: 1px solid #c4c4c4 !important;
  border-radius: 5px !important;
  cursor: pointer;
}

ul.job-tabs li:first-child {
  border-radius: 5px !important;
}

ul.job-tabs li:last-child {
  border-radius: 5px !important;
}

ul.job-tabs,
.job-tab-link {
  transition: all 0.5s ease-in-out;
}

.job-tab-link:hover {
  color: #fff;
}

.job-tab-content {
  display: none;
  padding: 1vmin 3vmin 5vmin;
  transition-property: transform, opacity;
  transition-duration: 0.4s;
  transition-timing-function: ease-out;
}

.job-tab-content.current {
  display: inherit;
  background: ivory;
  border-radius: 5px !important;
  animation: fade 0.3s ease-in-out both;
}

.job_desc_limit {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  min-height: 65px;
}

.job_desc_seemore_btn {
  border: none;
  background: none;
  color: blue;
  justify-content: end;
  display: flex;
  width: 100%;
}

.show-full-content {
  display: block;
}

@keyframes fade {
  0% {
    opacity: 0;
    transform: translateY(2rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.job-tab-content > * {
  /* margin: 1rem 0; */
}

.job-tab-content img {
  max-width: 100%;
  border-radius: 0.5rem;
}

main {
  width: 100% !important;
  margin: 0 !important;
  border: none !important;
  border-radius: none !important;
  max-width: 100% !important;
  height: 500px !important;
  overflow-y: auto;
}

main::-webkit-scrollbar {
  width: 5px;
}

main::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

main::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background-color: grey;
}

.recharts-layer text {
  font-size: 14px !important;
}

/* timeline */
/* start global style */
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css");

/*.......... start timeline style ..........*/

.timeline > h3 {
  text-align: center;
  text-transform: capitalize;
  font-size: 2rem;
  font-weight: bold;
  padding: 1rem;
}

.timeline > .column {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 1rem;
}

.timeline > .column::before {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  transform: translatex(-50%);
  border: 1px solid rgba(49, 49, 49, 0.5);
  height: calc(100% / 5 * 4);
  z-index: -1;
}

.timeline > .column > .row {
  display: flex;
}

/* row text */
.timeline > .column > .row > .text {
  flex: 1 1 calc(50% - 50px);
  position: relative;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
}

.timeline > .column > .row h3 {
  color: #fff;
  background-color: black;
  padding: 1rem;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.timeline > .column > .row p {
  padding: 1rem;
  color: rgba(51, 51, 51, 0.5);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

/* row icon */
.timeline > .column > .row > .icon {
  flex: 1 1 100px;
}

.timeline > .column > .row > .icon > div {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: auto;
  background: #fff;
  box-shadow: 0 1rem 3rem rgb(0 0 0 / 18%);
  position: relative;
}

.timeline > .column > .row > div i {
  display: block;
  font-size: 1.5rem;
  color: rgb(0, 85, 255);
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* row time */
.timeline > .column > .row > .time {
  flex: 1 1 calc(50% - 50px);
  padding-top: 1rem;
}

.timeline > .column > .row:nth-child(even) {
  flex-direction: row-reverse;
}

.timeline > .column > .row:nth-child(even) > div:last-child {
  text-align: end;
}

.timeline > .column > .row:nth-child(even) h3::before {
  content: "";
  position: absolute;
  left: -20px;
  border: 10px solid rgba(0, 0, 0, 0);
  border-right-color: black;
}

.timeline > .column > .row:nth-child(odd) h3::after {
  content: "";
  position: absolute;
  right: -20px;
  border: 10px solid rgba(0, 0, 0, 0);
  border-left-color: black;
}

/* mobile version */
@media screen and (max-width: 768px) {
  .timeline > .column::before {
    left: 50px;
    height: calc(100% / 5 * 4);
    top: 50px;
  }

  .timeline > .column > .row {
    flex-flow: column wrap;
    height: 250px;
  }

  .timeline > .column > .row:nth-child(even) {
    flex-direction: column;
  }

  .timeline > .column > .row:nth-child(even) > .time:last-child {
    text-align: start;
  }

  .timeline > .column > .row > .icon {
    order: 1;
    flex: 1 1 100%;
    padding-top: 50px;
    width: 100px;
  }

  .timeline > .column > .row > .time {
    order: 2;
    flex: 1 1 50px;
    width: calc(100% - 100px);
  }

  .timeline > .column > .row > .text {
    order: 3;
    flex: 1 1 calc(100% - 50px);
    width: calc(100% - 100px);
  }

  .timeline > .column > .row:nth-child(odd) h3::before {
    content: "";
    position: absolute;
    left: -20px;
    border: 10px solid rgba(0, 0, 0, 0);
    border-right-color: black;
  }

  .timeline > .column > .row:nth-child(odd) h3::after {
    border: none;
  }
}

/*.......... end timeline style ..........*/
.preview_form {
  overflow-y: auto;
  height: 565px;
  /* padding-bottom: 35px; */
  /* margin-top: 10px; */
  padding: 0px 7px 5px 3px;
}

.preview_form::-webkit-scrollbar {
  width: 3px;
}

.preview_form::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 7px;
}

.edit_border_class::-webkit-scrollbar-thumb {
  border-radius: 7px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background-color: grey;
}

.edit_border_class::-webkit-scrollbar {
  width: 3px;
}

.edit_border_class::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 7px;
}

.edit_border_class::-webkit-scrollbar-thumb {
  border-radius: 7px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background-color: grey;
}

#gallerywrapper {
  width: 100%;
  height: 215px;
  margin: 0 auto;
  position: relative;
  font-family: verdana, arial, sans-serif;
}

#gallerywrapper #gallery {
  position: absolute;
  left: 0;
  top: 0;
  height: 215px;
  width: 100%;
  overflow: hidden;
  text-align: center;
}

#gallerywrapper #gallery div {
  width: 100%;
  height: 900px;
  /* padding-top:10px;  */
  position: relative;
}

#gallerywrapper #gallery div img {
  clear: both;
  display: block;
  margin: 0 auto;
  border: 0;
}

#gallerywrapper #gallery div h3 {
  padding: 10px 0 0 0;
  margin: 0;
  font-size: 18px;
}

#gallerywrapper #gallery div p {
  padding: 5px 0;
  margin: 0;
  font-size: 12px;
  line-height: 18px;
}

#gallery .previous {
  display: inline;
  float: left;

  text-decoration: none;
}

#gallery .next {
  display: inline;
  float: right;

  text-decoration: none;
}

.relogin {
  background: rgba(0, 0, 0, 0.8);
  bottom: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  -webkit-transition: opacity 400ms ease-in;
  -moz-transition: opacity 400ms ease-in;
  transition: opacity 400ms ease-in;
  z-index: -1;
}

.relogin:target {
  display: block;
  opacity: 1;
  pointer-events: auto;
  z-index: 99999;
}

.relogin .modalContainer {
  background-color: #f2f2f2;
  height: auto;
  padding-bottom: 10px;
  position: relative;
  margin: 0 auto;
  border-radius: 10px;
  /* / padding: 3em; / */
  overflow-y: auto !important;
  -webkit-overflow-scrolling: touch;
}

.relogin .modalContainer::-webkit-scrollbar {
  width: 8px;
}

.relogin .modalContainer::-webkit-scrollbar-track {
  /* / -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);  / */
  border-radius: 10px;
}

.relogin .modalContainer::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* / -webkit-box-shadow: inset 0 0 6px rgba(248, 7, 7, 0.5);  / */
  background-color: rgb(196, 193, 193);
}

/* #form #email {
	width: 300px;

	outline: none;
	border: none;
	padding: 10px;
	border-radius: 6px;
	color: #fff;
	font-style: 18px;
  } */

#form .input-box {
  position: relative;
}

#text {
  display: block;
  color: #000;
  font-weight: 400;
  font-style: italic;
  padding: 5px;
}

#form.invalid .input-box::before {
  content: "";
  position: absolute;
  right: 12px;
  top: 7px;
  width: 20px;
  height: 20px;
  background: url(https://fadzrinmadu.github.io/hosted-assets/email-validation-check-using-javascript/invalid.png);
  -webkit-background-size: cover;
  background-size: cover;
}

#form.valid .input-box::before {
  content: "";
  position: absolute;
  right: 12px;
  top: 7px;
  width: 20px;
  height: 20px;
  background: url(https://fadzrinmadu.github.io/hosted-assets/email-validation-check-using-javascript/valid.png);
  -webkit-background-size: cover;
  background-size: cover;
}

.news_bar {
  transform: scaleY(1.9);
  color: #4aa081;
  margin-left: 13px;
}

.faq_bar {
  transform: scaleY(1.8);
  color: #4aa081;
  margin-left: 13px;
}

.fUtggp {
  max-height: 860px !important;
}

.overflow_class {
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.modal-header .close {
  padding: 8px;
}

@media (max-width: 760px) {
  body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav)
    .content-wrapper,
  body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav)
    .main-footer,
  body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav)
    .main-header {
    margin-left: 0 !important;
  }
}

/* .fDquJO{
	overflow: auto !important;
         }
         .gelpCx{
	overflow: auto !important;
         }
         .gelpCx::-webkit-scrollbar {
	width: 5px;
         }

         .gelpCx::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	border-radius: 10px;
         }

         .gelpCx::-webkit-scrollbar-thumb {
	         border-radius: 10px;
	         -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
	         background-color: grey;
         }
         .fDquJO::-webkit-scrollbar {
	width: 5px;
         }

         .fDquJO::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	border-radius: 10px;
         }

         .fDquJO::-webkit-scrollbar-thumb {
	         border-radius: 10px;
	         -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
	         background-color: grey;
         } */

.card_inner_hr_css {
  margin: 10px 0px;
  color: #d9d9d9;
}

.card_inner_div {
  background: #fff;
  border-radius: 5px;
  padding: 15px;
  box-shadow: rgba(0, 0, 0, 0.125) 0px 0px 1px, rgba(0, 0, 0, 0.2) 0px 1px 3px;
  width: 60%;
  min-height: 540px;
}

.all_icon_imgs_div {
  display: flex;
  align-items: center;
  width: 40px;
  justify-content: center;
  border-radius: 3px;
}

.all_icon_imgs {
  height: 20px;
  width: 20px;
  font-family: Poppins;
}

.nine_font_class {
  font-size: 9px;
  font-weight: 500 !important;
  font-family: Poppins;
}

.ten_font_class {
  font-size: 10px;
  font-weight: 500 !important;
  font-family: Poppins;
}

.eleven_font_class {
  font-size: 11px;
  font-weight: 500;
  font-family: Poppins;
}

.twelve_font_class {
  font-size: 12px;
  font-weight: 600;
  font-family: Poppins;
}
.twelve_font_class2 {
  font-size: 14px;
  font-weight: 600;
  font-family: Poppins;
}

.twenty_font_class {
  font-size: 20px;
  font-weight: 600;
  font-family: Poppins;
}

.modal_body_p {
  font-size: 12px;
  font-weight: 400;
  font-family: Poppins;
}

.box_border_class {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 1px;
  border-radius: 3px;
}

.all_action_buttons {
  background: rgb(71, 121, 240);
  color: white;
  border-radius: 5px;
  font-size: 9px;
  font-weight: 600;
  border: none;
  height: 24px;
  width: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.action_buttons_end_css {
  display: flex;
  justify-content: end;
  position: relative;
  width: 100%;
  cursor: pointer;
}

.login_empty_div {
  width: 35%;
  background: #fff;
}

.login_img_div {
  width: 100%;
  padding: 120px 175px 93px 450px;
  background: rgb(255, 255, 255);
}

.login_main_div {
  height: 456px;
  width: 290px;
  position: absolute;
  top: 80px;
  left: 160px;
  /* border-top: 5px solid rgb(45, 93, 208); */
}

.vertical-line {
  height: 100%;
  margin-right: 10px;
}

/*
	##Device = Desktops
	##Screen = 1281px to higher resolution desktops
         */

@media (min-width: 1281px) {
  /* CSS */
}

/*
	##Device = Laptops, Desktops
	##Screen = B/w 1025px to 1280px
         */

@media (min-width: 1025px) and (max-width: 1280px) {
  /* CSS */
}

/*
	##Device = Tablets, Ipads (portrait)
	##Screen = B/w 768px to 1024px
         */

@media (min-width: 768px) and (max-width: 1024px) {
  .login_img_div {
    display: none;
  }

  .login_empty_div {
    display: none;
  }

  .login_main_div {
    border-top: 5px solid rgb(45, 93, 208);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

/*
	##Device = Tablets, Ipads (landscape)
	##Screen = B/w 768px to 1024px
         */

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .login_img_div {
    display: none;
  }

  .login_empty_div {
    display: none;
  }

  .login_main_div {
    border-top: 5px solid rgb(45, 93, 208);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

/*
	##Device = Low Resolution Tablets, Mobiles (Landscape)
	##Screen = B/w 481px to 767px
         */

@media (min-width: 481px) and (max-width: 767px) {
  /* CSS */
  .login_img_div {
    display: none;
  }

  .login_empty_div {
    display: none;
  }

  .login_main_div {
    border-top: 5px solid rgb(45, 93, 208);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

/*
	##Device = Most of the Smartphones Mobiles (Portrait)
	##Screen = B/w 320px to 479px
         */

@media (min-width: 320px) and (max-width: 480px) {
  .login_img_div {
    display: none;
  }

  .login_empty_div {
    display: none;
  }

  .login_main_div {
    border-top: 5px solid rgb(45, 93, 208);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

/**
 * Makes our code later a bit neater
 **/
@mixin grad($hex) {
  background: linear-gradient(
    to right,
    rgba($hex, 0) 0%,
    rgba($hex, 1) 30%,
    rgba($hex, 1) 50%,
    rgba($hex, 1) 70%,
    rgba($hex, 0) 100%
  );
}

/**
          * Space out the bars
          **/
*,
*:before,
*:after {
  box-sizing: border-box;
}

aside {
  font-size: 13px;
  line-height: 24px;
  font-family: monospace;
  color: #555;
  /* text-align: center; */
}

/* main {
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	background: #00BA13;
	padding: 24px 0;
	min-height: 250px;
         } */

/**
          * Styling the loader bar
          **/
.loader {
  width: 100%;
  height: 3px;
  overflow: hidden;
  background: #bef5c3;
  border-radius: 10px;

  &::after {
    content: " ";
    height: 100%;
    display: block;
    transform-origin: top left;
  }
}

/**
          * Full width bars, alternating colours
          **/
.loader--full {
  animation: solidcolours 3.75s steps(1) infinite 0.75s;

  &:after {
    width: 100vw;
    animation: solidcolours 3.75s steps(1) infinite, load 0.75s infinite linear;
  }
}

/**
          * Bars 1/3 width, either solid or with gradiated colour
          **/
.loader--solid {
  &:after {
    width: 2vw;
    animation: solidcolours 3s steps(1) infinite, loadthird 3s infinite linear;
  }
}

/**
          * Javascript overwrite to stop animations
          **/
.loader.loader--paused {
  animation: 0;

  &:after {
    animation: 0;
  }
}

/**
          * The animations.
          **/
@keyframes load {
  0% {
    transform: translateX(-100vw);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes loadthird {
  0% {
    transform: translateX(-33.3vw);
  }

  100% {
    transform: translateX(100vw);
  }
}

/* there are 5 frames. we've given an animation duration of 5s so each frame gets 1s of time, matching the length of our load animation */
@keyframes solidcolours {
  0% {
    background-color: #00ba13;
  }

  20% {
    background-color: #00ba13;
  }

  40% {
    background-color: #00ba13;
  }

  60% {
    background-color: #00ba13;
  }

  80% {
    background-color: #00ba13;
  }
}

.dnZiGA:not(:last-of-type) {
  border: none !important;
}

.fCtwXt:not(:last-of-type) {
  border: none !important;
}

.summernote_class {
  color: red;
}

.go2072408551 {
  min-width: 25%;
  font-size: 11px;
  font-weight: 500;
  padding: 4px 10px;
  /* background: #555 !important; */
  /* color: #fff !important; */
}

.go2358747891 {
  font-size: 20px !important;
}

.go3958317564 {
  justify-content: left !important;
}

.css-p0rm37 {
  font-size: 11px !important;
  top: -6px !important;
}

.css-1x5jdmq {
  padding: 5px !important;
}

.css-1x5jdmq {
  padding: 10px !important;
  font-size: 11px !important;
  font-family: "Poppins" !important;
  font-weight: 400 !important;
}

.css-14x1nlk {
  font-size: 11px !important;
  font-weight: 600 !important;
  top: -10px !important;
  margin-bottom: 0px !important;
  font-family: "Poppins" !important;
}

.css-2bxn45 {
  padding: 17px 12px 7px !important;
  font-size: 11px !important;
  font-weight: 500 !important;
  font-family: "Poppins" !important;
}

.css-1rgmeur {
  font-size: 14px !important;
  font-weight: 600 !important;
  top: -5px !important;
  font-family: "Poppins" !important;
}

.css-u9osun.Mui-focused {
  font-weight: 500 !important;
  font-family: "Poppins" !important;
}

.student_preview_td {
  padding: 8px;
  line-height: 14px;
}

.student_preview_name_label {
  color: grey;
  font-weight: 400;
  font-size: 10px;
}

.student_preview_name {
  margin-top: 5px;
  font-weight: 600;
  font-size: 13px;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 5px 10px !important;
  height: 24px !important;
  font-weight: 400 !important;
  font-size: 11px !important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  font-size: 11px !important;
  top: -7px !important;
}

.css-e4w4as-MuiFormLabel-root-MuiInputLabel-root {
  font-size: 11px !important;
  top: -6px !important;
}

.css-o943dk-MuiFormLabel-root-MuiInputLabel-root {
  font-size: 14px !important;
  top: -3px !important;
}

.css-10botns-MuiInputBase-input-MuiFilledInput-input {
  padding-top: 21px !important;
  padding-bottom: 3px !important;
  font-size: 11px !important;
  font-weight: 500 !important;
}

.MuiIconButton-root {
  background: rgba(0, 0, 0, 0.06) !important;
  border-radius: 0px !important;
  border-bottom: 1px solid !important;
  padding: 11px !important;
}

.MuiSvgIcon-root {
  height: 17px !important;
  width: 17px !important;
}

/* .evxAbm{display: none}
      .TUEFO{display: none !important} */

.border_class header {
  display: none !important;
}

.border_class2 header {
  display: none !important;
}

.rdt_TableBody {
  min-height: 62vh;
  overflow: auto;
}

/* delete With Password modal css */
.delet_with_pass_main_contener {
  padding: 10px;
  background: #6c7a99;
}
.rdt_Table {
  overflow: hidden;
}

.delet_with_pass_header {
  border-bottom: none;
  padding: 3px 10px;
}

.delet_with_pass_body_main_div {
  background: white;
  padding: 10px 10px 0px;
  font-size: 10px;
  font-weight: 500;
}

.delet_with_pass_input {
  width: 100%;
  border-radius: 5px;
  background: white;
  height: 30px;
  font-size: 13px;
  padding: 8px 12px;
  border: 1px solid rgb(45, 93, 208);
}

.delet_with_pass_footer {
  background: white;
  padding: 2px 10px;
  border-top: none !important;
}

.delet_with_pass_delete_button {
  border-radius: 3px;
  background: rgba(235, 36, 36, 0.95);
  font-size: 10px;
  font-weight: 500;
  padding: 0px 20px;
  height: 24px;
  border: none;
  color: white;
}

#exampleModalLabel {
  font-size: 12px !important;
  font-weight: 500 !important;
}

.welcome_msg_main_div {
  background: rgb(108, 122, 153);
  padding: 7px 5px 10px 20px;
  border-radius: 3px;
  margin-bottom: 10px;
}

.welcome_msg_main_p {
  font-size: 12px;
  font-weight: 600;
  color: white;
}

.welcome_msg_inner_p {
  font-size: 9px;
  font-weight: 500;
  color: white;
  margin-top: 3px;
  width: 85%;
}

.no_data_main_div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  background: white;
  font-size: 12px;
  font-weight: 500;
  font-family: "Poppins";
  gap: 10px;
}

.selected_std_div {
  display: flex;
  font-size: 8px;
  background: #1f3977;
  color: #fff;
  width: fit-content;
  gap: 2px;
  padding: 3px 7px 3px 0px;
  border-radius: 5px;
  align-items: center;
  font-weight: 500;
  font-family: "Poppins";
  margin-right: 3px;
}

.selected_std_main_div {
  padding: 10px;
  display: ruby-text;
  margin-top: 10px;
  max-height: 90px;
  overflow: auto;
}

.selected_std_close_img {
  height: 8px;
  width: 8px;
}

.selected_std_modal {
  position: fixed;
  inset: 0px;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: 20;
  right: 0px;
  margin: 0;
  padding: 0;
}

.selected_std_modal_inner_div {
  padding: 15px;
  background: rgb(245, 245, 245);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 6px 6px;
  position: absolute;
  bottom: 0px;
  top: 0px;
  right: 0px;
  width: 450px;
  height: 100%;
  overflow: auto;
}
.specific_recipients_label {
  display: flex;
  color: black;
  font-size: 9px;
  margin-top: 4px;
  font-weight: 500 !important;
  align-items: center;
  justify-content: center;
}

.eOtTIX {
  min-height: 35px !important;
}

.gZWIKX {
  min-height: 35px !important;
}

.ghUtUN {
  overflow-y: auto !important;
}
.news_doc {
  font-size: 11px;
  font-weight: 500;
  margin-top: 2px;
  max-height: 55px;
  overflow: auto;
  border: 1px solid #edebeb;
  padding: 5px;
  border-radius: 3px;
}

.preview_form_imgs_event {
  width: 216px;
  height: 100px;
  object-fit: contain;
  border-radius: 3px;
  /* border-radius: 3px;
	transition: transform 0.2s ease; */
}

.preview_form_imgs {
  width: 60px;
  height: 40px;
  object-fit: contain;
  border-radius: 3px;
  transition: transform 0.2s ease;
}

.preview_form_imgs_hover {
  width: 60px;
  height: 40px;
  object-fit: contain;
  border-radius: 3px;
  transition: transform 0.2s ease;
}
.preview_form_imgs_hover:hover {
  /* height: 150px;
	width: 200px; */
  transform: scale(2.7);
  z-index: 2;
  border-radius: 3px;
  transition: transform 1s ease;
}

.flagged_content_img {
  height: 30px;
  width: 60px;
  margin: 2px;
  border-radius: 3px;
  object-fit: contain;
  transition: transform 0.2s ease;
}
.flagged_content_img:hover {
  transform: scale(5.7);
  z-index: 2;
  border-radius: 5px;
  transition: transform 1s ease;
  position: absolute;
  top: 7px;
}

.preview_form_event_imgs {
  height: 170px;
  /* width: 100%; */
  width: 190px;
  object-fit: contain;
  /* border-radius: 3px; */
  /* width: 90px;
	height: 70px;
	object-fit: contain;*/
  /* transition: transform 0.2s ease; */
}

.img_hover_class {
  height: 140px;
  width: 200px;
  /* transition: transform 0.2s ease; */
}

.preview_form_event_imgs:hover {
  border: 2px solid #1e5fd7;
  transition: border 0.3s ease;
}

/* .preview_form_event_imgs:hover{ */
/* border-radius: 1px solid var(--darkBlue); */
/* height: 150px;
	width: 200px; */
/* transform: scale(2.8);
	z-index: 2;
	border-radius: 3px;
	transition: transform 1s ease; */

/* position: absolute;
	top: 50%;;
	left: 50%; ;
	transform: translate(-50%, -50%); */
/* } */

.event_column_img {
  height: 30px;
  width: 40px;
  padding: 5px;
  border-radius: 3px;
  transition: transform 0.2s ease;
}
/* .event_column_img:hover{
	z-index: 2;
	transform: scale(2.8);
	border-radius: 3px;
	transition: transform 1s ease;
} */

._job_logo {
  height: 77px;
  width: 100%;
  object-fit: contain;
  border-radius: 3px;
  transition: transform 0.4s ease;
}
._job_logo:hover {
  z-index: 2;
  transform: scale(2.8);
  border-radius: 3px;
  transition: transform 1s ease;
}
._job_logo2{
  height: 170px;
  width: 100%;
  object-fit: contain;
  border-radius: 3px;
  transition: transform 0.4s ease;
}
._job_logo3{
  height: 110px;
  width: 110px;
  object-fit: contain;
  border-radius: 3px;
  /* transition: transform 0.4s ease; */
}

.ghUtUN::-webkit-scrollbar,
.selected_std_main_div::-webkit-scrollbar,
.selected_std_modal_inner_div::-webkit-scrollbar,
.NVNsk::-webkit-scrollbar,
.news_doc::-webkit-scrollbar,
.rdt_TableBody::-webkit-scrollbar {
  width: 3px;
}

.ghUtUN::-webkit-scrollbar-track,
.selected_std_main_div::-webkit-scrollbar,
.selected_std_modal_inner_div::-webkit-scrollbar,
.NVNsk::-webkit-scrollbar,
.news_doc::-webkit-scrollbar,
.rdt_TableBody::-webkit-scrollbar {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.ghUtUN::-webkit-scrollbar-thumb,
.selected_std_main_div::-webkit-scrollbar,
.selected_std_modal_inner_div::-webkit-scrollbar,
.NVNsk::-webkit-scrollbar,
.news_doc::-webkit-scrollbar,
.rdt_TableBody::-webkit-scrollbar {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background-color: grey;
}

.note-editor.note-airframe .note-status-output,
.note-editor.note-frame .note-status-output {
  display: none !important;
}

.feed_publish_btn_enabled {
  background: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  padding: 6px;
  border-radius: 3px;
  width: 100%;
  font-size: 10px;
  font-weight: 500;
  border: none;
}
.feed_publish_btn_disabled {
  background: #e9e9e9;
  color: #f9f9f9;
  padding: 6px;
  border-radius: 3px;
  width: 100%;
  font-size: 10px;
  font-weight: 500;
  border: none;
}
.select_options_class:disabled {
  background-color: #f0f0f0;
  color: #808080;
}
.select_border_class::after {
  background-color: red;
  border-radius: 20px;
}
.sc-lbOyJj {
  display: flex;
  justify-content: center;
}
.react-calendar {
  width: 85%;
}

/* ----------------------------------------------- */

.main-container {
  max-width: 600px;
  margin: auto;
  border: 1px solid #4779f0; /* Set border for main container */
  padding: 10px;
  border-radius: 0px;
}

.event-image {
  width: 240px; /* Set fixed width */
  height: 120px; /* Set fixed height */
  object-fit: fill;
}

.event-title {
  font-size: 14px; /* Set font size for event name */
}

.event-info,
.ticket-info,
.publish-close-info {
  font-size: 10px; /* Set font size for other details */
}
