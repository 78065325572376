body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.file-ip-1{
  /* width: 100%; */
  /* border: 1px solid #c4c4c4; */
  /* border: 1px solid #ced4da; */
  /* padding: 10px;
  height: 178px;
  display: flex;
  align-items: center; */
  
}
#file-ip-1-preview{
  height: 77px;
   
    width: 100%;
    object-fit: contain;
  
}
#file-ip-2-preview{
  height: 77px;
   
    width: 100%;
    object-fit: contain;
  
}
#comp_logo{
  height: 170px;
   
    width: 100%;
    object-fit: contain;
}
.marketImage{
  width: 230px !important;
  height: 120px !important;
}
#marketImage{
  height: 150px;
   
    width: 100%;
    object-fit: contain;
}

    /* akshays code */
    .note-toolbar{
      text-align: left !important;
    background: white !important;
    }
    .note-btn-group .btn {
      background-color: #999999 !important;
      margin-top: 0px;
    }
    .summernote{
      
    position: rel;
    display: flex;
    /* align-items: flex-end; */
    overflow: hidden;
    /* padding: 1rem; */
    height: auto;
    width: 100%;
    text-align: center;
    /* color: whitesmoke; */
    background-color:#ffffff6c;
    /* box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1), 0 4px 4px rgba(0, 0, 0, 0.1), 0 8px 8px rgba(0, 0, 0, 0.1), 0 16px 16px rgba(0, 0, 0, 0.1); */

    }
    .note-editor.card:after{
      content: "";
    /* display: block; */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 200%;
    /* pointer-events: none; */
    /* background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.009) 11.7%, rgba(0, 0, 0, 0.034) 22.1%, rgba(0, 0, 0, 0.072) 31.2%, rgba(0, 0, 0, 0.123) 39.4%, rgba(0, 0, 0, 0.182) 46.6%, rgba(0, 0, 0, 0.249) 53.1%, rgba(0, 0, 0, 0.32) 58.9%, rgba(0, 0, 0, 0.394) 64.3%, rgba(0, 0, 0, 0.468) 69.3%, rgba(0, 0, 0, 0.54) 74.1%, rgba(0, 0, 0, 0.607) 78.8%, rgba(0, 0, 0, 0.668) 83.6%, rgba(0, 0, 0, 0.721) 88.7%, rgba(0, 0, 0, 0.762) 94.1%, rgba(0, 0, 0, 0.79) 100%); */
    /* transform: translateY(-50%); */
    /* transition: transform calc(var(--d) * 2) var(--e); */
}
.note-editor .note-airframe .card{
    position: relative;
    display: flex;
    align-items: flex-end;
    overflow: hidden;
    /* padding: 1rem; */
    height: auto;
    width: 100%;
    text-align: center;
    color: #000 !important;
    background-color: #fff !important;
    
    /* color: whitesmoke; */
    /* background-color: whitesmoke; */
    /* box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1), 0 4px 4px rgba(0, 0, 0, 0.1), 0 8px 8px rgba(0, 0, 0, 0.1), 0 16px 16px rgba(0, 0, 0, 0.1); */
}
.note-editor.note-frame{
  color: #ffffff!important;
  background-color: white!important;
}
    
 
  
    .note-editing-area.card{
      position: relative;
    display: flex;
    align-items: flex-end;
    overflow: hidden;
    /* padding: 1rem; */
    height: auto;
    width: 100%;
    text-align: center;
    /* color: whitesmoke; */
    background-color: whitesmoke;
    /* box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1), 0 4px 4px rgba(0, 0, 0, 0.1), 0 8px 8px rgba(0, 0, 0, 0.1), 0 16px 16px rgba(0, 0, 0, 0.1); */
}


.note-editor.note-frame {
    color: black!important;
    /* background-color: white!important; */
}


.card:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 200%;
    pointer-events: none;
    /* background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.009) 11.7%, rgba(0, 0, 0, 0.034) 22.1%, rgba(0, 0, 0, 0.072) 31.2%, rgba(0, 0, 0, 0.123) 39.4%, rgba(0, 0, 0, 0.182) 46.6%, rgba(0, 0, 0, 0.249) 53.1%, rgba(0, 0, 0, 0.32) 58.9%, rgba(0, 0, 0, 0.394) 64.3%, rgba(0, 0, 0, 0.468) 69.3%, rgba(0, 0, 0, 0.54) 74.1%, rgba(0, 0, 0, 0.607) 78.8%, rgba(0, 0, 0, 0.668) 83.6%, rgba(0, 0, 0, 0.721) 88.7%, rgba(0, 0, 0, 0.762) 94.1%, rgba(0, 0, 0, 0.79) 100%); */
    transform: translateY(-50%);
    transition: transform calc(var(--d) * 2) var(--e);
}
.note-btn-group.note-insert{
  display: none;
}
.dropdown-toggle::after{
  display: none;
}
    