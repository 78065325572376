

.note-btn-group .btn{
    background-color: #fff !important;
    margin-top: 0px !important;
}
.note-btn-group .note-btn{
  border-color: rgba(0,0,0,.2) !important;
  padding: 0.2rem 0.65rem !important;
  font-size: 9px !important;

}
.btn{
  display: inline-block !important;
    font-weight: 400 !important;
    color: #212529 !important;
    text-align: center !important;
    vertical-align: middle !important;
    -webkit-user-select: none !important;
    user-select: none !important;
    background-color: transparent !important;
    border: 1px solid transparent !important;
    padding: 0.375rem 0.75rem !important;
    font-size: 1rem !important;
    line-height: 1.5 !important;
    border-radius: 0.25rem !important;
    letter-spacing: 0.025rem !important;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-group, .btn-group-vertical{
    position: relative !important;
    display: inline-flex;
    vertical-align: middle !important;
}
.note-editor .note-toolbar>.note-btn-group, .note-popover .popover-content>.note-btn-group{
  
    margin-top: 5px !important;
    margin-left: 0 !important;
    margin-right: 5px !important;
}
.note-btn-group btn-group note-insert{
  display: none !important;
}
.note-style{
  display: none !important;
}
.panel{
  margin-bottom: 0px !important;
}
.btn-group, .btn-group-vertical{
    position: relative !important;
    display: inline-flex;
    vertical-align: middle !important;
}
.note-editor .note-toolbar, .note-popover .popover-content{
    margin: 0 !important;
    padding: 0 0 5px 5px !important;
}
.panel-default>.panel-heading {
    color: #333 !important;
    background-color: #f5f5f5 !important;
    border-color: #ddd !important;

}
.note-toolbar{
    text-align: left !important;
    background: white !important;
    position: relative !important;
    border-top-left-radius: 3px !important;
    border-top-right-radius: 3px !important;
}
.note-editor .note-dropzone{
    position: absolute !important;
    display: none !important;
    z-index: 100 !important;
    color: #fa87a2 !important;
    background-color: #fff !important;
    opacity: .95 !important;
}
.note-editor.note-frame{
    color: black !important;
    background-color: white!important;
}
.note-editor {
    position: relative !important;
}
.note-dropdown-menu dropdown-menu dropdown-style{
    position: absolute;
    will-change: transform;
    top: 0px;
    left: 0px;
    transform: translate3d(0px) !important;
}
.dropdown-menu{
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
}
.note-editor.note-airframe .note-editing-area .note-editable,
 .note-editor.note-frame .note-editing-area .note-editable{
  padding: 5px;
 }

.note-editable{
  height: 70px;
  font-size: 11px;
  width: 100%;
}

.note-editable::-webkit-scrollbar {
	width: 2px;
}

.note-editable::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	border-radius: 10px;

}

.note-editable::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
	background-color: grey;
}






.note-toolbar {
    /* text-align: left !important;
    background: rgb(103, 50, 226) !important; */
  }

  .btn-group .btn {
    /* background-color: #9eed0c !important;
    margin-top: 0px; */
  }
  /* .btn-group .btn:hover{
    box-shadow: none !important;
   
    border-radius: 5px !important;
    width: auto !important;
    height: auto !important;
  } */

  /* .btn-group > .btn:hover, .btn-group-vertical > .btn:hover{
    z-index: 99999;
  } */
  .note-editor.note-frame, .note-editor.note-airframe{
    /* margin: 0px !important; */
  }


  .note-editor.card:after {
    content: "";
    /* display: block; */
    /* position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 200%; */
    /* pointer-events: none; */
    /* background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.009) 11.7%, rgba(0, 0, 0, 0.034) 22.1%, rgba(0, 0, 0, 0.072) 31.2%, rgba(0, 0, 0, 0.123) 39.4%, rgba(0, 0, 0, 0.182) 46.6%, rgba(0, 0, 0, 0.249) 53.1%, rgba(0, 0, 0, 0.32) 58.9%, rgba(0, 0, 0, 0.394) 64.3%, rgba(0, 0, 0, 0.468) 69.3%, rgba(0, 0, 0, 0.54) 74.1%, rgba(0, 0, 0, 0.607) 78.8%, rgba(0, 0, 0, 0.668) 83.6%, rgba(0, 0, 0, 0.721) 88.7%, rgba(0, 0, 0, 0.762) 94.1%, rgba(0, 0, 0, 0.79) 100%); */
    /* transform: translateY(-50%); */
    /* transition: transform calc(var(--d) * 2) var(--e); */
  }

  .note-editor .note-airframe .card {
    /* position: relative;
    display: flex;
    align-items: flex-end;
    overflow: hidden;
   
    height: auto;
    width: 100%;
    text-align: center;
    color: #000 !important;
    background-color: #fff !important; */

    /* color: whitesmoke; */
    /* background-color: whitesmoke; */
    /* box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1), 0 4px 4px rgba(0, 0, 0, 0.1), 0 8px 8px rgba(0, 0, 0, 0.1), 0 16px 16px rgba(0, 0, 0, 0.1); */
  }

  .note-editor.note-frame {
    /* color: #ffffff !important;
    background-color: white !important; */
  }



  .note-editing-area.card {
    /* position: relative;
    display: flex;
    align-items: flex-end;
    overflow: hidden;
    
    height: auto;
    width: 100%;
    text-align: center;
    
    background-color: whitesmoke; */
    
  }


  .note-editor.note-frame {
    color: black !important;
    border-radius: 3px;
   
  }